import { ChainProtocol, Token } from '@archax/shared-types'

/**
 * Extracts the contract address/id to be used from the token deployment result.
 * To be used to check the balance of the token.
 */
export const extractTokenHolderAddress = (token: Token) => {
  const {
    standard: {
      chain: { protocol },
    },
  } = token

  switch (protocol) {
    case ChainProtocol.Ethereum:
    case ChainProtocol.Polygon:
      return token.deploymentResult.contract.address
    case ChainProtocol.Hedera:
      return token.deploymentResult.contractId
    default:
      throw new Error(`${protocol} protocol not supported`)
  }
}
