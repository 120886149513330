"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TABLE_HEADER_NAMES = void 0;
exports.TABLE_HEADER_NAMES = {
    common: {
        address: 'Address',
        address_name: 'Address name',
        approver: 'Approver',
        balance: 'Balance',
        pro_rata: 'Pro rata',
        created_at: 'Created at',
        date_time: 'Date time',
        details: 'Details',
        email: 'Email',
        explorer_links: 'Explorer links',
        name: 'Name',
        operation_status: 'Status',
        operation_type: 'Action type',
        protocol: 'Protocol',
        requester: 'Requester',
        standard: 'Standard',
        symbol: 'Symbol',
        token_name: 'Token name',
        token_address: 'Token address',
        token_price: 'Token price',
        trader_address: 'Trader address',
        trader_name: 'Trader name',
        holder_address: 'Holder address',
        holder_name: 'Holder name',
        trader_address_name: 'Trader address name',
        trader_status: 'Status',
        value: 'Value',
        type: 'Type',
        tokenId: 'Token Id',
    },
    token_history: {
        amount: 'Amount',
    },
    token_whitelist: {
        is_whitelisted: 'Status',
    },
    token_blacklist: {
        is_blacklisted: 'Status',
    },
    trader_addresses: {
        account_name: 'Account name',
    },
    token_pool: {
        token_name: 'Token',
    },
    distribution: {
        snapshot_date: 'Snapshot date',
        distribution_date: 'Distribution date',
        distribution_asset: 'Distribution asset',
        distribution_rate: 'Price per share',
        token_name: 'Source asset',
        approved_at: 'Approved at',
        status: 'Status',
    },
};
