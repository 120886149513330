import { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Stack, Typography } from '@mui/material'

const StyledStack = styled(Stack)({
  display: 'flex',
})
const StyledContainer = styled(Box)(({ theme }) => ({
  border: `dashed 2px ${theme.palette.grey[300]}`,
  borderRadius: '1rem',
  margin: '0.5rem 0',
  padding: '1.5rem 0',
}))
const StyledBoxAction = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '1rem',
}))
const StyledBoxDescription = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}))
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type Props = {
  title: string
  description: string
  accept: string
  onChange: (file: File) => void
}
export default function FileUpload({ title, description, accept, onChange }: Props): ReactElement {
  return (
    <StyledStack>
      <StyledContainer>
        <StyledBoxDescription>
          <Typography variant="body2">{description}</Typography>
        </StyledBoxDescription>
        <StyledBoxAction>
          <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
            {title}
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => {
                if ((event.target?.files || []).length) {
                  onChange((event.target?.files || [])[0])
                }
              }}
              accept={accept}
            />
          </Button>
        </StyledBoxAction>
      </StyledContainer>
    </StyledStack>
  )
}
