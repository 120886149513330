import {
  OperationProcessStatus,
  OperationStatus,
  OperationType,
  READABLE_OPERATION_TYPE,
  TABLE_HEADER_NAMES,
  Token,
  TokenHistoryOperation,
  TokenType,
} from '@archax/shared-types'
import { Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ColDef, GridOptions, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetTokenHistoryParams, getTokenHistory, getTokenHistoryCSVUrl } from '../../../api/tokens'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { ExplorerLinks } from '../../../components/ui/ExplorerLinks'
import { OperationStatus as OperationStatusComponent } from '../../../components/ui/OperationStatus'
import { dateFilterOptions, numberFilterOptions, substringFilterParams } from '../../../util/common-grid-options'
import { formatDate } from '../../../util/formatters'
import tokenBalanceFormatter from '../../../util/token-balance-formatter'
import { canRegisterTokens } from '../../../util/token-options'

interface TokenHistoryProps {
  token: Token
}

const TOKEN_HISTORY_OPERATION_TYPES = [
  OperationType.CreateToken,
  OperationType.Burn,
  OperationType.Mint,
  OperationType.Pause,
  OperationType.Unpause,
  OperationType.GrantKYC,
  OperationType.RevokeKYC,
  OperationType.Wipe,
  OperationType.Send,
]

const POOL_TOKEN_HISTORY_OPERATION_TYPES = TOKEN_HISTORY_OPERATION_TYPES.concat(
  OperationType.RegisterToken,
  OperationType.UnregisterToken,
)

function TokenHistory({ token }: TokenHistoryProps) {
  const [showFailed, setShowFailed] = useState(false)
  const { t } = useTranslation()

  const columnDefs: ColDef<TokenHistoryOperation>[] = useMemo(
    () => [
      {
        field: 'explorer',
        headerName: '',
        cellRenderer: (params: ICellRendererParams<TokenHistoryOperation>) => {
          return <ExplorerLinks operation={params.data!} />
        },
        minWidth: 48,
        flex: 1,
        sortable: false,
        filter: false,
        cellStyle: { padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' },
      },
      {
        field: 'status',
        cellRenderer: (params: ICellRendererParams<TokenHistoryOperation>) => {
          return <OperationStatusComponent operation={params.data!} />
        },
        headerName: TABLE_HEADER_NAMES.common.operation_status,
        minWidth: 110,
        flex: 1,
        sortable: false,
        filter: false,
        floatingFilter: false,
        cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 },
      },
      {
        field: 'approved_at',
        headerName: TABLE_HEADER_NAMES.common.date_time,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          const date =
            params.data?.status === OperationStatus.Approved ? params.data!.approvedAt : params.data!.updatedAt
          return formatDate(date?.toString())
        },
        minWidth: 140,
        flex: 2,
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: dateFilterOptions,
      },
      {
        field: 'action_type',
        headerName: TABLE_HEADER_NAMES.common.operation_type,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return READABLE_OPERATION_TYPE[params.data!.type as OperationType]
        },
        minWidth: 160,
        flex: 2,
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values:
            token.tokenType === TokenType.Regular ? TOKEN_HISTORY_OPERATION_TYPES : POOL_TOKEN_HISTORY_OPERATION_TYPES,
          valueFormatter: (params: ValueFormatterParams<OperationType>) =>
            READABLE_OPERATION_TYPE[params.value as OperationType],
        },
      },
      {
        field: 'requestor_name',
        headerName: TABLE_HEADER_NAMES.common.requester,
        minWidth: 150,
        flex: 2,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return params.data!.requestor?.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'approver_name',
        headerName: TABLE_HEADER_NAMES.common.approver,
        minWidth: 150,
        flex: 2,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return params.data!.approver?.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'registered_token_name',
        headerName: TABLE_HEADER_NAMES.common.token_name,
        minWidth: 150,
        flex: 2,
        valueGetter: (params: ValueGetterParams<any>) => {
          return params.data!.registeredToken?.name || ''
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
        hide: !canRegisterTokens(token),
      },
      {
        field: 'registered_token_address',
        headerName: TABLE_HEADER_NAMES.common.token_address,
        minWidth: 200,
        flex: 2,
        valueGetter: (params: ValueGetterParams<any>) => {
          return params.data!.registeredToken?.deploymentResult?.contract?.address || ''
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
        hide: !canRegisterTokens(token),
      },
      {
        field: 'trader_name',
        headerName: t('headers.common.holderName'),
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return params.data!.trader?.name
        },
        minWidth: 150,
        flex: 2,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'trader_address_name',
        headerName: t('headers.common.holderAddressName'),
        valueGetter: (params: ValueGetterParams<any>) => {
          return params.data!.trader?.addresses?.name || ''
        },
        minWidth: 250,
        flex: 2,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'trader_address',
        headerName: t('headers.common.holderAddress'),
        valueGetter: (params: ValueGetterParams<any>) => {
          if (params.data!.trader?.addresses) {
            return params.data!.trader.addresses?.address
          }
          if (params.data!.data.to) {
            return params.data!.data.to
          }
          if (params.data!.data.address) {
            return params.data!.data.address
          }
          return ``
        },
        minWidth: 200,
        flex: 4,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'pool_token_name',
        headerName: TABLE_HEADER_NAMES.common.token_name,
        minWidth: 150,
        flex: 2,
        valueGetter: (params: ValueGetterParams<any>) => {
          return params.data!.poolToken?.name || ''
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'pool_token_address',
        headerName: TABLE_HEADER_NAMES.common.token_address,
        minWidth: 200,
        flex: 2,
        valueGetter: (params: ValueGetterParams<any>) => {
          return params.data!.poolToken?.deploymentResult?.contract?.address || ''
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'amount',
        headerName: TABLE_HEADER_NAMES.token_history.amount,
        valueGetter: (params: ValueGetterParams<any>) => {
          return tokenBalanceFormatter(params.data!.data?.amount, token.decimals)
        },
        minWidth: 120,
        flex: 2,
        sortable: true,
        filter: 'agNumberColumnFilter',
        filterParams: numberFilterOptions,
      },
    ],
    [token, t],
  )

  const mutateFilters = useCallback(
    (filters: any) => {
      if (!showFailed) {
        filters.operation_status = [OperationProcessStatus.Success]
      }

      return filters
    },
    [showFailed],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      onGridReady({ api }) {
        api.sizeColumnsToFit()
      },
    }),
    [columnDefs],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          History
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Switch
            checked={showFailed}
            onChange={(event) => setShowFailed(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography sx={{ marginRight: 6 }} align="left" variant="body1">
            Show failed actions
          </Typography>
        </Box>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => {
            return getTokenHistory({ ...(gridParams as GetTokenHistoryParams), id: token.id })
          }}
          csvExportUrlGetter={(gridParams) => {
            return getTokenHistoryCSVUrl({ ...(gridParams as GetTokenHistoryParams), id: token.id })
          }}
          mutateFilters={mutateFilters}
        />
      </Box>
    </div>
  )
}

export default TokenHistory
