import { Avatar, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { MouseEvent } from 'react'

interface AddButtonProps {
  'data-testid'?: string
  name: string
  ariaLabel: string
  disabled?: boolean
  onClick: (_: MouseEvent) => void
}

const AddButton: React.FunctionComponent<AddButtonProps> = ({
  'data-testid': dataTestId,
  name,
  ariaLabel,
  disabled,
  onClick,
}) => {
  return (
    <IconButton
      data-testid={dataTestId || 'add-button'}
      name={name}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      sx={{ '&:hover': { backgroundColor: 'info.light' } }}
    >
      <Avatar sx={{ backgroundColor: disabled ? 'grey[900]' : 'primary.main', width: 24, height: 24 }}>
        <AddIcon sx={{ color: 'info.light' }} />
      </Avatar>
    </IconButton>
  )
}

export default AddButton
