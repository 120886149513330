import {
  ChainProtocol,
  NFT_STANDARDS,
  OperationType,
  READABLE_STANDARD,
  Token,
  TokenStandardStatus,
  TokenStandardType,
  TokenStatus,
  TokenType,
} from '@archax/shared-types'
import {
  Archive,
  Download,
  ManageAccounts as ManageAccountsIcon,
  Pause,
  PlayArrow,
  Unarchive,
  Upgrade,
} from '@mui/icons-material'
import {
  Button,
  Card,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import { Box } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { download } from '../../../api/axios'
import { createOperation } from '../../../api/operations'
import Dialog from '../../../components/Dialog/Dialog'
import { useGlobalContext } from '../../../context'
import onApiError from '../../../util/on-api-error'
import tokenBalanceFormatter from '../../../util/token-balance-formatter'
import { isEVMProtocol } from '../../../util/token-options'
import { hasAdminRole, hasUserRole } from '../../../util/user-roles'
import { BurnNFTTokenForm } from './BurnNFTTokenForm'
import { BurnTokenForm } from './BurnTokenForm'
import { ChangeOwnerForm } from './ChangeOwnerForm'
import { MintTokenForm } from './MintTokenForm'
import { readableTokenProtocol } from '../../../util/token-utils'

const StyledTitleDetails = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '0.5rem',
  gap: '0.2rem',
  fontSize: '0.9em',
  label: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  'label, a': {
    alignSelf: 'flex-start',
  },
  a: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.2rem',
    fontSize: '0.9em',
  },
}))
const StyledButton = styled(Button)(() => ({
  backgroundColor: '#FFFFFF',
  color: '#000000',
  '&:hover': {
    color: '#FFFFFF',
  },
}))

interface TokenProps {
  token: Token
}

interface TotalSupplyProps {
  totalSupply: string | undefined
}

function TotalSupply({ totalSupply }: TotalSupplyProps): React.ReactElement {
  if (!totalSupply) {
    return <></>
  }

  if (totalSupply === '-1') {
    return (
      <Tooltip title="Error getting the total supply. You can try again later.">
        <Typography data-testid="token-details__total-supply">{'Error'}</Typography>
      </Tooltip>
    )
  }

  return <Typography data-testid="token-details__total-supply">{totalSupply}</Typography>
}

function TokenDetails({ token }: TokenProps) {
  const {
    state: { user },
  } = useGlobalContext()
  const { mutate: pauseMutation } = useMutation(() => createOperation(OperationType.Pause, {}, token.id), {
    onSuccess: () => {
      toast.success('Pause token request sent for approval')
    },
    onError: onApiError,
  })

  const { mutate: unpauseMutation } = useMutation(() => createOperation(OperationType.Unpause, {}, token.id), {
    onSuccess: () => {
      toast.success('Unpause token request sent for approval')
    },
    onError: onApiError,
  })

  const { mutate: archiveMutation } = useMutation(() => createOperation(OperationType.ArchiveToken, {}, token.id), {
    onSuccess: () => {
      toast.success('Archive token request sent for approval')
    },
    onError: onApiError,
  })

  const { mutate: unarchiveMutation } = useMutation(() => createOperation(OperationType.UnarchiveToken, {}, token.id), {
    onSuccess: () => {
      toast.success('Unarchive token request sent for approval')
    },
    onError: onApiError,
  })

  const { mutate: upgradeMutation } = useMutation(() => createOperation(OperationType.Upgrade, {}, token.id), {
    onSuccess: () => {
      toast.success('Upgrade token request sent for approval')
    },
    onError: onApiError,
  })

  const { mutate: associateMutation } = useMutation(() => createOperation(OperationType.AssociateToken, {}, token.id), {
    onSuccess: () => {
      toast.success('Associate token request sent for approval')
    },
    onError: onApiError,
  })

  const [showChangeOwnerDialog, setShowChangeOwnerDialog] = useState(false)
  const [showMintTokenDialog, setShowMintTokenDialog] = useState(false)
  const [showBurnTokenDialog, setShowBurnTokenDialog] = useState(false)
  const [showPauseTokenDialog, setShowPauseTokenDialog] = useState(false)
  const [showUnpauseTokenDialog, setShowUnpauseTokenDialog] = useState(false)
  const [showArchiveTokenDialog, setShowArchiveTokenDialog] = useState(false)
  const [showUnarchiveTokenDialog, setShowUnarchiveTokenDialog] = useState(false)
  const [showUpgradeTokenDialog, setShowUpgradeTokenDialog] = useState(false)
  const [showAssociateTokenDialog, setShowAssociateTokenDialog] = useState(false)

  if (!token) {
    return <></>
  }

  const isPaused = token.onChainData?.paused
  const isArchived = token.status === TokenStatus.Archived
  const isXrpLedgerChain = token.standard.chain.protocol === ChainProtocol.XRP_Ledger

  const handlePause = async () => {
    pauseMutation()
    setShowPauseTokenDialog(false)
  }

  const handleUnpause = async () => {
    unpauseMutation()
    setShowUnpauseTokenDialog(false)
  }

  const handleArchive = async () => {
    archiveMutation()
    setShowArchiveTokenDialog(false)
  }

  const handleUnarchive = async () => {
    unarchiveMutation()
    setShowUnarchiveTokenDialog(false)
  }

  const handleUpgrade = async () => {
    upgradeMutation()
    setShowUpgradeTokenDialog(false)
  }

  const handleAssociate = async () => {
    associateMutation()
    setShowUpgradeTokenDialog(false)
  }

  const isNft = NFT_STANDARDS.includes(token.standard.name)
  const canMint = isArchived ? false : !isNft || (isNft && token.onChainData?.totalSupply! === '0')
  const canBurn = isArchived ? false : !isNft || (isNft && BigInt(token.onChainData?.balance! || '0') > BigInt(0))
  const totalSupply = typeof token.onChainData?.totalSupply! === 'string' ? token.onChainData!.totalSupply! : '-1'
  const isExternalToken = token.tokenType === TokenType.External
  const upgradeAvailable = token.standard.status === TokenStandardStatus.Disabled
  const showAssociateTokenAction =
    hasUserRole(user) &&
    isExternalToken &&
    token.standard.chain.protocol === ChainProtocol.Hedera &&
    !token.onChainData?.isAssociated!
  const showChangeOwnerAction = hasAdminRole(user) && !isExternalToken

  return (
    <>
      <Card sx={{ p: 7 }}>
        <Typography data-testid="token-details__title" align="left" variant="h3">
          {token.name}
        </Typography>
        <StyledTitleDetails>
          {token.explorerLinks?.owner?.url && (
            <label>
              <span>Owner:</span>
              <Link href={token.explorerLinks?.owner?.url} rel="noreferrer" target="_blank">
                {token.explorerLinks?.owner?.value}
              </Link>
            </label>
          )}
          <label>
            <span>Address:</span>
            <Link href={token.explorerLinks?.address?.url} rel="noreferrer" target="_blank">
              {token.explorerLinks?.address?.value}
            </Link>
          </label>
          {token.standard?.chain?.protocol === ChainProtocol.Algorand && (
            <label>
              <Link href={`/algorand/${token.id}/optin`} rel="noreferrer" target="_blank">
                Link to Opt In
              </Link>
            </label>
          )}
        </StyledTitleDetails>

        <Grid marginTop={3} container>
          <Grid item xs={8}>
            <TableContainer sx={{ maxWidth: "100%", '& th': { border: 0 }, '& td': { border: 0 } }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Total Supply</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell>Protocol</TableCell>
                    <TableCell>Standard</TableCell>
                    {!isNft && <TableCell>Decimals</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: '#F8FBFD' }}>
                  <TableRow>
                    <TableCell>
                      <TotalSupply
                        data-testid="token-details__total-supply"
                        totalSupply={totalSupply && tokenBalanceFormatter(totalSupply, token.decimals)}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography data-testid="token-details__symbol">{token.symbol}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography data-testid="token-details__protocol">
                        {readableTokenProtocol(token.standard.chain.protocol, token.privacyEnabled)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography data-testid="token-details__token-type">
                        {READABLE_STANDARD[token.standard.name as TokenStandardType]}
                      </Typography>
                    </TableCell>
                    {!isNft && (
                      <TableCell>
                        <Typography>{token.decimals}</Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end" flexDirection={'column'}>
            <Box display="flex" justifyContent="flex-end" flexDirection={'row'}>
              {!isArchived && (
                <Box m={1}>
                  <Button
                    sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                    variant="contained"
                    onClick={() => setShowArchiveTokenDialog(true)}
                    disabled={token.hasExternalOwner}
                  >
                    <Archive />
                    Archive
                  </Button>
                </Box>
              )}
              {isArchived && (
                <Box m={1}>
                  <Button
                    sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                    variant="contained"
                    onClick={() => setShowUnarchiveTokenDialog(true)}
                    disabled={token.hasExternalOwner}
                  >
                    <Unarchive />
                    Unarchive
                  </Button>
                </Box>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" flexDirection={'row'}>
              {hasUserRole(user) && !isExternalToken && (
                <>
                  <Box m={1}>
                    <Button
                      data-testid="token-details__mint-button"
                      disabled={!canMint || token.hasExternalOwner}
                      variant="contained"
                      onClick={() => setShowMintTokenDialog(true)}
                    >
                      Mint
                    </Button>
                  </Box>
                  <Box m={1}>
                    <Button
                      data-testid="token-details__burn-button"
                      disabled={!canBurn || token.hasExternalOwner}
                      variant="contained"
                      onClick={() => setShowBurnTokenDialog(true)}
                    >
                      Burn
                    </Button>
                  </Box>
                  <Box m={1}>
                    {!isPaused && (
                      <Button
                        data-testid="token-details__pause-button"
                        sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                        variant="contained"
                        onClick={() => setShowPauseTokenDialog(true)}
                        disabled={token.hasExternalOwner}
                      >
                        <Pause />
                        Pause
                      </Button>
                    )}
                    {isPaused && (
                      <Button
                        data-testid="token-details__unpause-button"
                        sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                        variant="contained"
                        onClick={() => setShowUnpauseTokenDialog(true)}
                        disabled={token.hasExternalOwner}
                      >
                        <PlayArrow />
                        Unpause
                      </Button>
                    )}
                  </Box>
                  {upgradeAvailable && (
                    <Box m={1}>
                      <Button
                        sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                        variant="contained"
                        onClick={() => setShowUpgradeTokenDialog(true)}
                        disabled={token.hasExternalOwner}
                      >
                        <Upgrade />
                        Upgrade
                      </Button>
                    </Box>
                  )}
                </>
              )}
              {showAssociateTokenAction && (
                <>
                  <Box m={1}>
                    <Button
                      data-testid="token-details__associate-button"
                      variant="contained"
                      onClick={() => setShowAssociateTokenDialog(true)}
                    >
                      Associate
                    </Button>
                  </Box>
                </>
              )}
              {showChangeOwnerAction && (
                <>
                  <Box m={1}>
                    <StyledButton
                      variant="contained"
                      onClick={() => setShowChangeOwnerDialog(true)}
                      disabled={token.hasExternalOwner}
                    >
                      <ManageAccountsIcon />
                      Change Owner
                    </StyledButton>
                  </Box>
                  {isEVMProtocol(token.standard.chain.protocol) && (
                    <Box m={1}>
                      <StyledButton variant="contained" onClick={() => download(`tokens/${token.id}/assets`)}>
                        <Download />
                        Contract
                      </StyledButton>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
      {!token.hasExternalOwner && (
        <>
          <Dialog title="Change Owner" onClose={() => setShowChangeOwnerDialog(false)} open={showChangeOwnerDialog}>
            <ChangeOwnerForm
              token={token}
              onCancel={() => setShowChangeOwnerDialog(false)}
              onSuccess={() => setShowChangeOwnerDialog(false)}
            />
          </Dialog>
          <Dialog
            title={isNft ? 'Mint token' : 'Mint tokens'}
            onClose={() => setShowMintTokenDialog(false)}
            open={showMintTokenDialog}
          >
            <MintTokenForm onSuccess={() => setShowMintTokenDialog(false)} token={token} isNft={isNft} />
          </Dialog>
          <Dialog title="Burn tokens" onClose={() => setShowBurnTokenDialog(false)} open={showBurnTokenDialog}>
            {isNft && (
              <BurnNFTTokenForm
                onSuccess={() => setShowBurnTokenDialog(false)}
                tokenId={token.id}
                assets={token.onChainData?.assets!}
              />
            )}
            {!isNft && <BurnTokenForm onSuccess={() => setShowBurnTokenDialog(false)} token={token} />}
          </Dialog>
          <Dialog
            title="Are you sure that you want to pause the token?"
            onConfirm={handlePause}
            confirmLabel="Pause"
            onClose={() => setShowPauseTokenDialog(false)}
            open={showPauseTokenDialog}
            showCancel
          >
            <Typography variant="body2">You can unpause it later.</Typography>
          </Dialog>
          <Dialog
            title="Are you sure that you want to unpause the token?"
            onConfirm={handleUnpause}
            confirmLabel="Unpause"
            onClose={() => setShowUnpauseTokenDialog(false)}
            open={showUnpauseTokenDialog}
            showCancel
          >
            <Typography variant="body2">You can pause it later.</Typography>
          </Dialog>
          <Dialog
            title="Are you sure that you want to archive the token?"
            onConfirm={handleArchive}
            confirmLabel="Archive"
            onClose={() => setShowArchiveTokenDialog(false)}
            open={showArchiveTokenDialog}
            showCancel
          >
            <Typography variant="body2">You can unarchive it later.</Typography>
          </Dialog>
          <Dialog
            title="Are you sure that you want to unarchive the token?"
            onConfirm={handleUnarchive}
            confirmLabel="Unarchive"
            onClose={() => setShowUnarchiveTokenDialog(false)}
            open={showUnarchiveTokenDialog}
            showCancel
          >
            <Typography variant="body2">You can re-archive it later.</Typography>
          </Dialog>
          <Dialog
            title="Are you sure that you want to upgrade the token?"
            onConfirm={handleUpgrade}
            confirmLabel="Upgrade"
            onClose={() => setShowUpgradeTokenDialog(false)}
            open={showUpgradeTokenDialog}
            showCancel
          >
            <Typography variant="body2"></Typography>
          </Dialog>
          <Dialog
            title="Are you sure that you want to associate the token?"
            onConfirm={handleAssociate}
            confirmLabel="Associate"
            onClose={() => setShowAssociateTokenDialog(false)}
            open={showAssociateTokenDialog}
            showCancel
          >
            <Typography variant="body2"></Typography>
          </Dialog>
        </>
      )}
    </>
  )
}

export default TokenDetails
