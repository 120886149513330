import { TRADER_NAME_MAX_LENGTH, TRADER_NAME_MIN_LENGTH, TraderCreationFormData } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup, debounce } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { getAllTradersByName } from '../../../api/traders'
import { createTrader } from '../../../api/traders'
import TextField from '../../../components/ui/TextField/TextField'
import onApiError from '../../../util/on-api-error'

interface CreateTraderProps {
  onSuccess: () => void
}

function CreateTraderForm({ onSuccess }: CreateTraderProps) {
  const { t } = useTranslation()
  const [isValidatingUniqueName, setIsValidatingUniqueName] = useState(false)

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string()
        .min(TRADER_NAME_MIN_LENGTH, `Minimum length is ${TRADER_NAME_MIN_LENGTH}`)
        .max(TRADER_NAME_MAX_LENGTH)
        .required(),
    })
    .required()

  const initialValues = { name: '' }

  const { mutate } = useMutation((formData: TraderCreationFormData) => createTrader(formData.name), {
    onSuccess: () => {
      toast.success(t('holder.toast.created'))
      onSuccess()
    },
    onError: onApiError,
  })

  const onSubmit = (data: TraderCreationFormData) => {
    mutate(data)
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
    watch,
    setError,
  } = useForm<TraderCreationFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const traderName = watch('name')
  const checkHolderNameUnique = useMemo(
    () =>
      debounce((value: string) => {
        getAllTradersByName(value.trim()).then(({ data: response }) => {
          setIsValidatingUniqueName(false)
          if (response.data.some((it: { name: string }) => it.name === value)) {
            setError('name', { type: 'custom', message: t('holder.errors.uniqueHolderName') })
          }
        })
      }, 1000),
    [],
  )

  useEffect(() => {
    if (traderName?.trim()) {
      setIsValidatingUniqueName(true)
      checkHolderNameUnique(traderName)
    }
  }, [traderName])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="name"
        control={control}
        label="Name"
        inputProps={{ minLength: TRADER_NAME_MIN_LENGTH, maxLength: TRADER_NAME_MAX_LENGTH }}
      />

      <ButtonGroup fullWidth>
        <Button
          disabled={!isDirty || !isValid || isSubmitting || isValidatingUniqueName}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Create
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default CreateTraderForm
