import { NFT_STANDARDS, Token } from '@archax/shared-types'
import { Box } from '@mui/material'
import TokenWhitelistPoolTokens from './TokenWhitelistPoolTokens'
import TokenWhitelistTraders from './TokenWhitelistTraders'

interface TokenWhitelistProps {
  token: Token
}

function TokenWhitelist({ token }: TokenWhitelistProps) {
  const isNft = NFT_STANDARDS.includes(token.standard.name)
  const canMint = !isNft || (isNft && token.onChainData!.totalSupply === '0')
  const canSend = BigInt(token.onChainData?.balance || 0) > BigInt(0) && !token.hasExternalOwner

  return (
    <div>
      <TokenWhitelistTraders token={token} {...{ isNft, canMint, canSend }} />
      <Box marginTop={3} />
      <TokenWhitelistPoolTokens token={token} {...{ isNft, canMint, canSend }} />
    </div>
  )
}

export default TokenWhitelist
