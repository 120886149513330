import { OperationType, RegisterTokenOperationData, RegistrableTokenDataRow } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, DialogActions, Grid } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import { getRegistrableTokens } from '../../../../api/tokens'
import { Select } from '../../../../components/ui/Select'
import { SelectOption } from '../../../../components/ui/Select/Select'
import onApiError from '../../../../util/on-api-error'

interface RegisterTokenProps {
  onSuccess: () => void
  tokenId: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string().required('Token is required'),
})

function RegisterToken({ onSuccess, tokenId }: RegisterTokenProps) {
  const [registrableTokens, setRegistrableTokens] = useState<RegistrableTokenDataRow[]>([])
  const [selectTokenOptions, setSelectTokenOptions] = useState<SelectOption[]>([])

  const initialValues = { id: '' }

  const { mutate } = useMutation(
    (formData: RegisterTokenOperationData) => createOperation(OperationType.RegisterToken, formData, tokenId),
    {
      onSuccess: (data) => {
        toast.success('Register token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  useQuery(['get-registrable-tokens'], () => getRegistrableTokens({ id: tokenId }), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const tokens = data?.data?.data
      setRegistrableTokens(tokens)
      const options = tokens?.map((token: RegistrableTokenDataRow) => {
        return {
          label: `${token.name} (${token.symbol})${token.tokenType === 'EXTERNAL' ? ' - External' : ''}`,
          value: token.id,
        }
      })
      setSelectTokenOptions(options)
    },
  })

  const onSubmit = (data: { id: string }) => {
    let operationData = { tokenId: data.id }
    mutate(operationData)
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors },
    getValues,
  } = useForm<{ id: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid minWidth={540}>
        <Select name="id" label="Select token" options={selectTokenOptions} control={control} />
        <DialogActions sx={{ marginBottom: 2, display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={onSuccess}
            sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit}
            disabled={isSubmitButtonDisabled}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            Register token
          </Button>
        </DialogActions>
      </Grid>
    </form>
  )
}

export default RegisterToken
