import React, { useCallback, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { useGlobalContext } from '../../context'

interface SessionTimeoutProps {
  children: JSX.Element
}

const SessionTimeout: React.FC<SessionTimeoutProps> = ({ children }): React.ReactElement => {
  const timeoutRef = useRef<null | number>(null)
  const {
    actions: {
      auth: { logout },
    },
    state: { accessTokenExpiresAtInMs },
  } = useGlobalContext()

  const handleTimeout = useCallback(() => {
    toast.warning('Your session has expired. Please login again.', { autoClose: false })
    logout()
  }, [logout])

  useEffect(() => {
    if (!accessTokenExpiresAtInMs) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      return
    }

    const sessionRemainingTime = accessTokenExpiresAtInMs - Date.now()
    timeoutRef.current = window.setTimeout(handleTimeout, sessionRemainingTime)
  }, [accessTokenExpiresAtInMs, handleTimeout])

  return children
}

export default SessionTimeout
