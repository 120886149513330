import { MintTokenFormData, OperationType, Token } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import TextField from '../../../../components/ui/TextField/TextField'
import onApiError from '../../../../util/on-api-error'
import { tokenAmountValidator } from '../../../../util/yup-validators'

interface MintTokenProps {
  token: Token
  isNft: boolean
  onSuccess: () => void
}

function MintTokenForm({ token: { id, decimals }, onSuccess, isNft }: MintTokenProps) {
  const validationSchema = Yup.object()
    .shape({
      amount: tokenAmountValidator({ decimals, positive: true }),
    })
    .required()

  const initialValues = { amount: isNft ? '1' : '0' }
  const { mutate } = useMutation((formData: MintTokenFormData) => createOperation(OperationType.Mint, formData, id), {
    onSuccess: (data) => {
      toast.success('Mint token request sent for approval')
      onSuccess()
    },
    onError: onApiError,
  })

  const onSubmit = (data: MintTokenFormData) => {
    mutate(data)
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<MintTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isNft && (!isDirty || !isValid || isSubmitting || isSubmitSuccessful)

  return (
    <form data-testid="mint-token-form" onSubmit={handleSubmit(onSubmit)}>
      {!isNft && (
        <TextField
          data-testid="mint-token-form__amount-input"
          name="amount"
          type="number"
          control={control}
          label="Number to mint"
        />
      )}

      <ButtonGroup fullWidth>
        <Button
          data-testid="mint-token-form__submit-button"
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Mint
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default MintTokenForm
