import {
  OperationType,
  Token,
  TokenWhitelistTradersData,
  TraderAddress,
  WipeNFTTokenFormData,
} from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import onApiError from '../../../../util/on-api-error'

interface WipeNFTTokenProps {
  tokenId: string
  onSuccess: () => void
  account?: TraderAddress
  trader?: TokenWhitelistTradersData
  poolToken?: Token
}

const validationSchema = Yup.object()
  .shape({
    nftTokenId: Yup.number()
      .positive()
      .integer('NFT id must be a positive number.')
      .min(0, 'NFT id must be a positive number.')
      .typeError('NFT id must be a positive number.'),
  })
  .required()

function WipeNFTTokenForm({ tokenId, onSuccess, account, trader, poolToken }: WipeNFTTokenProps) {
  // The user shouldn't be here on this form if the holder doesn't has NFTs
  // But we still try with id 0 just in case...
  const nftTokenId = trader?.onChainData?.assets![0].nftTokenId || 0
  const initialValues = { nftTokenId }
  const { mutate } = useMutation(
    (formData: WipeNFTTokenFormData) => createOperation(OperationType.Wipe, formData, tokenId, account?.trader.id),
    {
      onSuccess: () => {
        toast.success('Wipe token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = (data: WipeNFTTokenFormData) => {
    const operationData = account
      ? ({ nftTokenId: data.nftTokenId, traderAddressId: account.id } as WipeNFTTokenFormData)
      : ({ nftTokenId: data.nftTokenId, poolTokenId: poolToken!.id } as WipeNFTTokenFormData)

    mutate(operationData)
  }

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<WipeNFTTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Later on if we move to collections we need add a NFT id selector here
          The ids for the selector should come from: account.assets */}

      <ButtonGroup fullWidth>
        <Button
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Wipe
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default WipeNFTTokenForm
