"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.versionsWithPrivacy = exports.ChainCurrencySymbol = void 0;
const chain_1 = require("./chain");
exports.ChainCurrencySymbol = {
    [chain_1.ChainProtocol.Algorand]: 'ALGO',
    [chain_1.ChainProtocol.Ethereum]: 'ETH',
    [chain_1.ChainProtocol.Hedera]: 'HBAR',
    [chain_1.ChainProtocol.Polygon]: 'MATIC',
    [chain_1.ChainProtocol.XRP_Ledger]: 'XRP',
};
exports.versionsWithPrivacy = ['SilentDataRollupERC20', 'SilentDataRollupERC721', 'SilentDataRollupERC20PoolToken'];
