import { Trader, TraderStatus as TraderStatusEnum } from '@archax/shared-types'
import { Button, Typography } from '@mui/material'

interface TraderStatusProps {
  trader: Trader
}

function TraderStatus({ trader, ...props }: TraderStatusProps) {
  const { deletedAt } = trader

  const statusText = !deletedAt ? TraderStatusEnum.Active : TraderStatusEnum.Archived
  const statusBackground = !deletedAt ? 'success.light' : 'error.light'
  const statusColor = !deletedAt ? 'success.dark' : 'error.dark'

  return (
    <Button
      variant="contained"
      sx={{
        marginRight: 1,
        marginLeft: 1,
        minWidth: 58,
        height: 28,
        backgroundColor: statusBackground,
        color: statusColor,
        borderRadius: '8px',
        ':hover': {
          backgroundColor: statusBackground,
          color: statusColor,
          cursor: 'default',
          boxShadow: 'none',
        },
      }}
    >
      <Typography variant="inputLabel">{statusText}</Typography>
    </Button>
  )
}

export default TraderStatus
