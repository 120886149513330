import { LoginResponse } from '@archax/shared-types'
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import authContext from '../feature/auth/auth.context'
import actionsContext from './actions-definition'

const _initialState_ = { ...authContext.state }

function getActions(setState: Dispatch<SetStateAction<any>>) {
  return {
    auth: {
      setCredentials: (payload: LoginResponse): void => authContext.actions.setCredentials(setState, payload),
      logout: (): Promise<void> => authContext.actions.logout(setState),
      getUser: (): Promise<void> => authContext.actions.getUser(setState),
    },
  }
}

function useInitialGlobalState() {
  const [state, setState] = useState(_initialState_)
  const actions = useMemo(() => getActions(setState), [setState])
  return { state, actions }
}

const GlobalContext = createContext({
  state: _initialState_,
  actions: actionsContext,
})

const useGlobalContext = () => useContext(GlobalContext)

function GlobalContextProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const { state, actions } = useInitialGlobalState()

  useEffect(() => {
    actions.auth.getUser()
  }, [actions.auth])

  return <GlobalContext.Provider value={{ state, actions }}>{children}</GlobalContext.Provider>
}

export { useGlobalContext, useInitialGlobalState, GlobalContext, GlobalContextProvider }
