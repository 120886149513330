import React from 'react'
import { Controller } from 'react-hook-form'
import MuiTextField, { TextFieldProps } from '@mui/material/TextField'

export type FormTextFieldProps = TextFieldProps & {
  name: string
  control: any
}

export const FormTextField: React.FunctionComponent<FormTextFieldProps> = ({ name, control, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => (
        <MuiTextField
          sx={{ mb: 4 }}
          variant="standard"
          fullWidth
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
          {...other}
        />
      )}
    />
  )
}

export default FormTextField
