import { Operation, TokenFormData } from '@archax/shared-types'
import { Card, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editOperationWithFiles } from '../../../api/operations'
import { FormMode } from '../../../api/types'
import onApiError from '../../../util/on-api-error'
import TokenForm from './TokenForm/TokenForm'

function EditCreateTokenOperation({ operation }: { operation: Operation }) {
  const navigate = useNavigate()
  const { mutateAsync } = useMutation((formData: TokenFormData) => editOperationWithFiles(operation.id, formData), {
    onSuccess: (data) => {
      toast.success('Token creation request edited with success.')
      navigate(`/tokens/requests/edit`)
    },
    onError: onApiError,
  })

  const onSubmit = async (data: TokenFormData) => {
    const oldDocuments: Record<string, boolean> = {}
    if (data.document && data.tempDocuments) {
      data.tempDocuments.map((docName) => {
        const index = data.document.findIndex((doc: any) => doc === docName)
        if (index !== -1) {
          oldDocuments[docName] = true
          data.document.splice(index, 1)
        } else {
          oldDocuments[docName] = false
        }
      })
      delete data.tempDocuments
    }
    await mutateAsync({ ...data, oldDocuments })
  }

  const defaultValues = operation.data || {}

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7 }}>
        <Typography align="center" variant="h2">
          Edit token creation request
        </Typography>
        <TokenForm mode={FormMode.Edit} defaultValues={defaultValues as TokenFormData} onSubmit={onSubmit} />
      </Card>
    </Container>
  )
}

export default EditCreateTokenOperation
