import algosdk from 'algosdk'
import { useCallback, useState } from 'react'
import Client from '@walletconnect/sign-client'
import { getSdkError } from '@walletconnect/utils'
import { Web3Modal } from '@web3modal/standalone'

import { WalletConnection } from './types'
import { base64ToUint8Array, formatJsonRpcRequest, getSignTxnRequestParams } from './utils'

const ALGORAND_METHOD = 'algo_signTxn'

const projectId = process.env.REACT_APP_ALGORAND_OPTIN_PROJECT_ID || ''
const chainId = process.env.REACT_APP_ALGORAND_OPTIN_CHAIN_ID || ''
const requiredNamespaces = {
  algorand: {
    methods: [ALGORAND_METHOD],
    chains: [chainId],
    events: [],
  },
}

export type WCWalletConnection = WalletConnection & {
  chainId: string
}
export type WalletConnectClient = {
  walletConnection?: WalletConnection
  connect: () => Promise<void>
  disconnect: () => Promise<void>
  signTransaction: (transaction: algosdk.Transaction) => Promise<Uint8Array[] | undefined>
}
export function useConnection(): WalletConnectClient {
  const [client, setClient] = useState<Client>()
  const [walletConnection, setWalletConnection] = useState<WCWalletConnection>()

  const connect = useCallback(async () => {
    let _client = client
    if (!_client) {
      _client = await Client.init({
        relayUrl: 'wss://relay.walletconnect.com',
        projectId,
      })
      setClient(_client)
    }
    const web3Modal = new Web3Modal({
      projectId,
      themeMode: 'light',
      walletConnectVersion: 2,
    })
    try {
      const { uri, approval } = await _client.connect({
        pairingTopic: JSON.parse(localStorage.getItem('wc-connection') || '{}').pairingTopic,
        requiredNamespaces,
      })

      if (uri) {
        // Create a flat array of all requested chains across namespaces.
        const standaloneChains = Object.values(requiredNamespaces)
          .map((namespace) => namespace.chains)
          .flat() as string[]
        web3Modal.openModal({ uri, standaloneChains: [...standaloneChains] })
      }
      const session = await approval()
      localStorage.setItem('wc-connection', JSON.stringify(session))
      const accountKey = (session.namespaces?.algorand?.accounts || [])[0]
      const address = accountKey.replace(`${chainId}:`, '')
      const newWalletConnection = {
        address,
        connection: session,
        chainId,
      }
      setWalletConnection(newWalletConnection)
    } catch (err) {
      localStorage.removeItem('wc-connection')
      console.error('Failed to get account information:', err)
      throw err
    } finally {
      web3Modal.closeModal()
    }
  }, [client])

  const disconnect = useCallback(async () => {
    try {
      if (!client || !walletConnection) {
        throw new Error('Wallet connection is not connected')
      }
      await client.disconnect({
        topic: walletConnection.connection.topic,
        reason: getSdkError('USER_DISCONNECTED'),
      })
    } catch (error) {
      console.error('Failed to disconnect wallet', error)
    } finally {
      localStorage.removeItem('wc-connection')
      setWalletConnection(undefined)
    }
  }, [client, walletConnection])

  const signTransaction = useCallback(
    async (transaction: algosdk.Transaction) => {
      if (!client || !walletConnection) {
        throw new Error('Wallet connection is not connected')
      }

      const signTxnParams = getSignTxnRequestParams([{ txn: transaction }])
      const request = formatJsonRpcRequest('algo_signTxn', [signTxnParams])
      const response = await client.request<(string | number[])[]>({
        topic: walletConnection.connection.topic,
        chainId: walletConnection.chainId,
        request,
      })

      const signedTxns =
        typeof response[0] === 'string'
          ? (response as string[]).map(base64ToUint8Array)
          : (response as number[][]).map((item) => Uint8Array.from(item))

      return signedTxns
    },
    [client, walletConnection],
  )

  return {
    walletConnection,
    connect,
    disconnect,
    signTransaction,
  }
}
