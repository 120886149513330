import { OperationType, TokenFormData } from '@archax/shared-types'
import { Card, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { createOperation, createOperationWithFiles } from '../../api/operations'
import onApiError from '../../util/on-api-error'
import TokenForm from './components/TokenForm/TokenForm'
import { FormMode } from '../../api/types'

function CreateToken() {
  const { mutate } = useMutation(
    (formData: TokenFormData) => {
      if (formData.document && formData.document.length > 0) {
        return createOperationWithFiles(OperationType.CreateToken, formData)
      }
      return createOperation(OperationType.CreateToken, formData)
    },
    {
      onSuccess: () => {
        toast.success('Token creation request sent for approval')
      },
      onError: onApiError,
    },
  )

  const onSubmit = async (data: TokenFormData) => {
    mutate(data)
  }

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7 }}>
        <Typography align="center" variant="h2">
          Request token creation
        </Typography>
        <TokenForm mode={FormMode.Create} onSubmit={onSubmit} />
      </Card>
    </Container>
  )
}
export default CreateToken
