import { Trader } from '@archax/shared-types'
import { Button, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { BaseSyntheticEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { archiveTrader, reactivateTrader } from '../../../../api/traders'
import Dialog from '../../../../components/Dialog/Dialog'
import onApiError from '../../../../util/on-api-error'
import { useTranslation } from 'react-i18next'

interface TraderStatusProps {
  trader: Trader
  size?: 'small' | 'medium' | 'large'
  refetch: () => void
}

function TraderStatusChangeButton({ trader, size = 'small', refetch, ...props }: TraderStatusProps) {
  const [showArchiveTraderDialog, setShowArchiveTraderDialog] = useState(false)
  const [showReactivateTraderDialog, setShowReactivateTraderDialog] = useState(false)
  const { deletedAt } = trader
  const { t } = useTranslation()

  const { mutate: archiveTraderMutation } = useMutation((id: string) => archiveTrader(id), {
    onSuccess: () => {
      refetch()
      toast.success(t('holder.toast.archive'))
    },
    onError: onApiError,
  })

  const { mutate: reactivateTraderMutation } = useMutation((id: string) => reactivateTrader(id), {
    onSuccess: () => {
      refetch()
      toast.success(t('holder.toast.reactivate'))
    },
    onError: onApiError,
  })

  function handleArchiveTrader(trader: Trader) {
    archiveTraderMutation(trader.id)
    setShowArchiveTraderDialog(false)
  }

  const openArchiveDialog = () => {
    setShowArchiveTraderDialog(true)
  }

  const reactivateAction = (trader: Trader) => {
    reactivateTraderMutation(trader.id)
    setShowReactivateTraderDialog(false)
  }

  const openReactivateDialog = () => {
    setShowReactivateTraderDialog(true)
  }

  const statusText = !deletedAt ? 'Archive' : 'Reactivate'
  const statusColor = !deletedAt ? 'primary' : 'alternative'
  const action = !deletedAt ? openArchiveDialog : openReactivateDialog

  return (
    <>
      <Button
        variant="contained"
        color={statusColor}
        size={size}
        sx={{
          border: deletedAt && '1px solid #13103E',
        }}
        onClick={(event: BaseSyntheticEvent) => {
          action()
          event.stopPropagation()
        }}
      >
        <Typography variant="button">{statusText}</Typography>
      </Button>

      <Dialog
        title={t('holder.dialog.archive')}
        onConfirm={() => handleArchiveTrader(trader!)}
        confirmLabel="Archive"
        onClose={() => setShowArchiveTraderDialog(false)}
        open={showArchiveTraderDialog}
        showCancel
      >
        {' '}
      </Dialog>

      <Dialog
        title={t('holder.dialog.reactivate')}
        onConfirm={() => reactivateAction(trader!)}
        confirmLabel="Reactivate"
        onClose={() => setShowReactivateTraderDialog(false)}
        open={showReactivateTraderDialog}
        showCancel
      >
        {' '}
      </Dialog>
    </>
  )
}

export default TraderStatusChangeButton
