import algosdk from 'algosdk'
import { useCallback, useEffect, useRef, useState } from 'react'
import { PeraWalletConnect } from '@perawallet/connect'
import { WalletConnection } from './types'

export type WalletConnectClient = {
  walletConnection?: WalletConnection
  connect: () => Promise<void>
  disconnect: () => Promise<void>
  signTransaction: (transaction: algosdk.Transaction) => Promise<Uint8Array[] | undefined>
}
export function useConnection(): WalletConnectClient {
  const peraWalletConnectRef = useRef(new PeraWalletConnect())
  const [walletConnection, setWalletConnection] = useState<WalletConnection>()

  const connect = useCallback(async () => {
    try {
      const peraWalletConnect = peraWalletConnectRef?.current
      const addresses = await peraWalletConnect?.connect()

      const newWalletConnection = {
        address: addresses[0],
        connection: peraWalletConnect,
      }
      setWalletConnection(newWalletConnection)
    } catch (error) {
      console.log(error)
    }
  }, [peraWalletConnectRef])

  const disconnect = useCallback(async () => {
    try {
      console.log('DISCONNECT')
      await walletConnection?.connection.disconnect()
      setWalletConnection(undefined)
    } catch (error) {
      console.log(error)
    }
  }, [walletConnection])

  const signTransaction = useCallback(
    async (transaction: algosdk.Transaction) => {
      const results = await walletConnection?.connection.signTransaction([[transaction].map((txn) => ({ txn }))])
      return results
    },
    [walletConnection],
  )

  useEffect(() => {
    ;(async () => {
      const wallet = localStorage.getItem('walletconnect')
      if (wallet) {
        const peraWalletConnect = peraWalletConnectRef?.current
        const addresses = await peraWalletConnect?.reconnectSession()

        const newWalletConnection = {
          address: addresses[0],
          connection: peraWalletConnect,
        }
        setWalletConnection(newWalletConnection)
      }
    })()
  }, [])

  return {
    walletConnection,
    connect,
    disconnect,
    signTransaction,
  }
}
