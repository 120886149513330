import { AddPriceFormData, ChainProtocol, ConsolidatedCapTableEntry, DistributionAsset } from '@archax/shared-types'
import { AxiosResponse } from 'axios'
import qs from 'qs'
import { axios, axiosNoAuth } from './axios'
import { PaginationRequestParams } from './types'

export interface GetTokensBaseParams {
  name?: string
  symbol?: string
  protocol?: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export interface GetTokensParams extends GetTokensBaseParams, PaginationRequestParams { }

interface GetTokenCapTableParams {
  timestamp: number | null
  id: string
}

type GetConsolidatedCapTableParams = {
  tokenIds: string[]
  timestamp: number
}

type ConsolidatedCapTableResponse = {
  data: ConsolidatedCapTableEntry[]
}

export const getToken = (id: string) => {
  return axios.get(`/tokens/${id}`)
}

export const getTokenDataToOptIn = (id: string) => {
  return axiosNoAuth.get(`/token/${id}/optin`)
}

export interface GetTokenHistoryParams extends PaginationRequestParams {
  id: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export interface GetTokenWhitelistParams extends PaginationRequestParams {
  id: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
  with_treasury?: boolean
}

export const getTokenHistoryCSVUrl = (params: GetTokenHistoryParams) => {
  const { id, limit, offset, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return `/tokens/${id}/history?${querystring}`
}

export const getTokenHistory = (params: GetTokenHistoryParams) => {
  const { id, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/tokens/${id}/history?${querystring}`)
}

export const getTokenWhitelistTradersCSVUrl = (params: GetTokenWhitelistParams) => {
  const { id, limit, offset, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return `/tokens/${id}/whitelist/traders?${querystring}`
}

export const getTokenWhitelistPoolTokensCSVUrl = (params: GetTokenWhitelistParams) => {
  const { id, limit, offset, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return `/tokens/${id}/whitelist/pool-tokens?${querystring}`
}

export const getTokenWhitelistTraders = (params: GetTokenWhitelistParams) => {
  const { id, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/tokens/${id}/whitelist/traders?${querystring}`)
}

export const getTokenWhitelistPoolTokens = (params: GetTokenWhitelistParams) => {
  const { id, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/tokens/${id}/whitelist/pool-tokens?${querystring}`)
}

export const getTokenCapTableCSVUrl = (params: GetTokenCapTableParams) => {
  const { id, timestamp } = params
  return `/tokens/${id}/cap-table${timestamp ? `?timestamp=${timestamp}` : ''}`
}

export const getTokenCapTable = (params: GetTokenCapTableParams) => {
  const { id, timestamp } = params
  return axios.get(`/tokens/${id}/cap-table${timestamp ? `?timestamp=${timestamp}` : ''}`)
}

export const getConsolidatedCapTable = (
  params: GetConsolidatedCapTableParams,
): Promise<AxiosResponse<ConsolidatedCapTableResponse>> => {
  return axios.post(`/tokens/consolidated-cap-table`, params)
}

export const getConsolidatedCapTableUrl = (): string => {
  return `/tokens/consolidated-cap-table`
}

export const getTokensUrl = (params: GetTokensBaseParams) => {
  const { sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })
  return `/tokens?${querystring}`
}

export const getTokens = (params: GetTokensParams) => {
  const url = getTokensUrl(params)
  return axios.get(url)
}

interface GetRegistrableTokensParams {
  id: string
}

export const getRegistrableTokens = (params: GetRegistrableTokensParams) => {
  const url = `tokens/${params.id}/registrable-tokens`
  return axios.get(url)
}

export const addPrice = (id: string, price: AddPriceFormData) => {
  return axios.post(`/tokens/${id}/prices`, price)
}

export interface GetRegisteredTokenParams extends PaginationRequestParams {
  id: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export const getRegisteredToken = async (params: GetRegisteredTokenParams) => {
  const { id, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/tokens/${id}/registered-tokens?${querystring}`)
}

export const getRegisteredTokenCSVUrl = (params: GetRegisteredTokenParams) => {
  const { id, limit, offset, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return `/tokens/${id}/registered-tokens?${querystring}`
}

export const getGrantablePoolTokens = (id: string) => {
  return axios.get(`/tokens/${id}/grantable-pool-tokens`)
}

export const getTokenDocuments = (id: string) => {
  return axios.get(`/tokens/${id}/documents`)
}

export interface GetTokenBlacklistParams extends PaginationRequestParams {
  id: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
  status?: string
  address?: string
}

export const getTokenBlacklist = (params: GetTokenBlacklistParams) => {
  const { id, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })
  return axios.get(`/tokens/${id}/blacklist?${querystring}`)
}

export const getTokenBlacklistCSVUrl = (params: GetTokenBlacklistParams) => {
  const { id, sort, limit, offset, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })
  return `/tokens/${id}/blacklist?${querystring}`
}

export interface GetTokenDistributionParams {
  distributionAsset: DistributionAsset
  distributionRate: string
  snapshotUnixTimestamp: number
}

export const getTokenDistribution = async (id: string, params: GetTokenDistributionParams): Promise<AxiosResponse> => {
  return axios.post(`/tokens/${id}/calculate-distribution`, params)
}

export const getDistributionSchedule = async (params: PaginationRequestParams): Promise<AxiosResponse> => {
  const { limit, offset } = params
  const queryObj = { limit, offset }
  const querystring = qs.stringify(queryObj, { indices: false })
  return axios.get(`/distributions?${querystring}`)
}

export const getExternalToken = (protocol: ChainProtocol, address: string, privacy: string) => {
  return axios.get(`/tokens/external/${protocol}/${address}/${privacy}`)
}

export interface GetDistributionPayoutsParams {
  id: string
}

export const getDistributionPayouts = (params: GetDistributionPayoutsParams): Promise<AxiosResponse> => {
  const { id } = params
  return axios.get(`/distributions/${id}`)
}
