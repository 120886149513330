import { numericFormatter } from 'react-number-format'

/**
 * Formats the native currency to a maximum of 4 decimal places rounded down.
 */
const nativeCurrencyFormatter = (value: string) => {
  const [integerPart, decimalPart] = value.split('.')
  const roundedDecimalPart = decimalPart ? decimalPart.substring(0, 4) : ''
  const numStr = decimalPart ? `${integerPart}.${roundedDecimalPart}` : integerPart
  return numericFormatter(numStr, {
    thousandSeparator: true,
    fixedDecimalScale: false,
    decimalScale: 6,
  })
}

export default nativeCurrencyFormatter
