import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  /**
   * Optional cache property to control whether the content of the tab should be kept rendered
   * once it has been displayed. This is useful for preserving the state or avoiding re-renders
   * of expensive components when switching tabs.
   */
  cache?: boolean

  // Unique identifier for the tab, used to determine if the tab is active.
  index: number

  // Current active tab index
  value: number
}

const TabPanel: React.FunctionComponent<TabPanelProps> = ({ children, value, index, cache = false, ...other }) => {
  const [visited, setVisited] = useState(false)
  const isActive = value === index
  const isActiveAndCached = isActive && cache

  useEffect(() => {
    if (isActiveAndCached) {
      setVisited(true)
    } else if (!cache) {
      setVisited(false)
    }
  }, [value, index, cache, isActiveAndCached])

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {(visited || isActive) && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </div>
  )
}

export default TabPanel
