import { Box, Container, CssBaseline } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

const Layout: React.FC = () => {
  return (
    <Box>
      <CssBaseline />
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </Box>
  )
}

export default Layout
