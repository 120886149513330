import {
  NFT_STANDARDS,
  OperationType,
  RegisteredTokenDataRow,
  TABLE_HEADER_NAMES,
  Token,
  UnregisterTokenOperationData,
} from '@archax/shared-types'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { AxiosResponse } from 'axios'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { createOperation } from '../../../api/operations'
import { GetRegisteredTokenParams, getRegisteredToken, getRegisteredTokenCSVUrl } from '../../../api/tokens'
import Dialog from '../../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { useGlobalContext } from '../../../context'
import { substringFilterParams } from '../../../util/common-grid-options'
import onApiError from '../../../util/on-api-error'
import tokenBalanceFormatter from '../../../util/token-balance-formatter'
import { hasUserRole } from '../../../util/user-roles'
import { RegisterToken } from './RegisterToken'
import { TransferNFTTokenForm } from './TransferNFTTokenForm'
import { TransferTokenForm } from './TransferTokenForm'

interface IToken extends Token {
  deploymentResult: any
}

interface RegisteredTokenProps {
  token: Token
}

function RegisteredTokens({ token }: RegisteredTokenProps) {
  const { id } = token as IToken
  const [showRegisterDialog, setShowRegisterDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<RegisteredTokenDataRow | null>(null)
  const [unregisterToken, setUnregisterToken] = useState<string | null>(null)
  const [showUnregisterDialogOpen, setShowUnregisterDialogOpen] = useState(false)
  const [showTransferDialog, setShowTransferDialog] = useState(false)
  const [selectedAddressRow, setSelectedAddressRow] = useState<string | null>(null)
  const [selectedAssetRow, setSelectedAssetRow] = useState<number | null>(null)

  const {
    state: { user },
  } = useGlobalContext()

  const { mutate: mutateUnregisterToken } = useMutation(
    (formData: UnregisterTokenOperationData) => createOperation(OperationType.UnregisterToken, formData, id),
    {
      onSuccess: (data) => {
        toast.success('Unregister token request sent for approval')
      },
      onError: onApiError,
    },
  )

  const handleUnregisterToken = (tokenId: string) => {
    mutateUnregisterToken({
      tokenId,
    })
    setShowUnregisterDialogOpen(false)
  }

  const columnDefs: ColDef<RegisteredTokenDataRow>[] = useMemo(
    () => [
      {
        field: 'token_name',
        headerName: TABLE_HEADER_NAMES.token_pool.token_name,
        flex: 1,
        minWidth: 250,
        valueGetter: (params: ValueGetterParams<RegisteredTokenDataRow>) => {
          return params.data!.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'onChainData.balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 130,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<RegisteredTokenDataRow>) => {
          if (params.data!.onChainData!.balance === undefined || params.data!.onChainData!.balance === null) {
            return (
              <Tooltip title="Could not retrieve the balance">
                <Typography>
                  <WarningIcon />
                </Typography>
              </Tooltip>
            )
          }

          return tokenBalanceFormatter(params.data!.onChainData!.balance, params.data!.decimals)
        },
      },
    ],
    [],
  )

  const userRoleColumnDefs: ColDef<RegisteredTokenDataRow>[] = useMemo(
    () => [
      {
        field: 'actions',
        flex: 1,
        minWidth: 400,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<RegisteredTokenDataRow>) => {
          const balance = params.data?.onChainData?.balance || 0
          const canTransfer = BigInt(balance) > BigInt(0)
          return (
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <Box marginRight={1}>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={!canTransfer}
                    onClick={() => {
                      setSelectedAddressRow(params.data!.tokenAddress)
                      setSelectedRow(params.data!)
                      setShowTransferDialog(true)
                      if (params.data!.onChainData.assets) {
                        setSelectedAssetRow(params.data!.onChainData!.assets[0].nftTokenId)
                      }
                    }}
                  >
                    Transfer
                  </Button>
                </Box>
                <Box marginRight={1}>
                  <Button
                    sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setUnregisterToken(params.data!.id)
                      setShowUnregisterDialogOpen(true)
                    }}
                  >
                    Unregister
                  </Button>
                </Box>
              </Box>
            </Grid>
          )
        },
      },
    ],
    [],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: hasUserRole(user) ? [...columnDefs, ...userRoleColumnDefs] : columnDefs,
    }),
    [columnDefs, userRoleColumnDefs, user],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          Tokens
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {hasUserRole(user) && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedRow(null)
                setShowRegisterDialog(true)
              }}
            >
              Register token
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => {
            return getRegisteredToken({ ...(gridParams as GetRegisteredTokenParams), id }) as unknown as Promise<
              AxiosResponse<any, any>
            >
          }}
          csvExportUrlGetter={(gridParams) => {
            return getRegisteredTokenCSVUrl({
              ...(gridParams as GetRegisteredTokenParams),
              id,
            })
          }}
        />
      </Box>
      <Dialog title={'Register token'} onClose={() => setShowRegisterDialog(false)} open={showRegisterDialog}>
        <RegisterToken onSuccess={() => setShowRegisterDialog(false)} tokenId={id} />
      </Dialog>
      <Dialog
        title="Are you sure that you want to unregister token?"
        onConfirm={() => handleUnregisterToken(unregisterToken!)}
        confirmLabel="Unregister token"
        onClose={() => setShowUnregisterDialogOpen(false)}
        open={showUnregisterDialogOpen}
        showCancel
      >
        <Typography variant="body2">You can register the token back later.</Typography>
      </Dialog>
      <Dialog title="Transfer token" open={showTransferDialog} onClose={() => setShowTransferDialog(false)} fullWidth>
        {NFT_STANDARDS.includes(selectedRow?.standard!) && (
          <TransferNFTTokenForm
            onSuccess={() => setShowTransferDialog(false)}
            tokenId={selectedRow?.id!}
            tokenAddress={selectedAddressRow!}
            poolTokenId={id}
            assetId={selectedAssetRow!}
          />
        )}
        {!NFT_STANDARDS.includes(selectedRow?.standard!) && (
          <TransferTokenForm
            onSuccess={() => setShowTransferDialog(false)}
            token={selectedRow as unknown as Token}
            tokenAddress={selectedAddressRow!}
            poolTokenId={id}
          />
        )}
      </Dialog>
    </div>
  )
}

export default RegisteredTokens
