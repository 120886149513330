import { Token } from '@archax/shared-types'
import { styled, Typography, List, ListItem, ListItemText } from '@mui/material'
import { ReactElement } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const Wrapper = styled('div')(() => ({
  width: '42rem',
  maxWidth: '42rem',
}))
const StyledList = styled(List)(() => ({
  li: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  svg: {
    color: '#000000',
  },
}))

type Props = {
  operationData: Record<string, unknown> | undefined
  token: Token
}
export default function ChangeOwnerDetailsModal({ operationData, token }: Props): ReactElement {
  const currentOwnerAddress = token.deploymentResult?.owner?.address || ''
  const newOwnerAddressOrAccountId = (operationData?.newOwnerAddress ||
    operationData?.newOwnerAccountId ||
    '') as string

  return (
    <Wrapper>
      <Typography variant="h5" mb={4}>
        Change Token owner
      </Typography>
      <StyledList>
        <ListItem>
          <ListItemText primary={'Current Owner address'} secondary={currentOwnerAddress} />
        </ListItem>
        <ListItem>
          <ArrowDownwardIcon fontSize="large" />
        </ListItem>
        <ListItem>
          <ListItemText primary={'New Owner address'} secondary={newOwnerAddressOrAccountId} />
        </ListItem>
      </StyledList>
    </Wrapper>
  )
}
