"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenStandardErrorMessage = exports.TokenErrorMessage = void 0;
var TokenErrorMessage;
(function (TokenErrorMessage) {
    TokenErrorMessage["NotFound"] = "Token not found";
    TokenErrorMessage["PoolTokenNotFound"] = "Pool token not found";
    TokenErrorMessage["TokenAssetsDownloadNotImplementedForProtocol"] = "Download token assets is not implemented for this token protocol";
    TokenErrorMessage["InvalidAddress"] = "Invalid address";
    TokenErrorMessage["NotPoolToken"] = "Token is not a pool token";
    TokenErrorMessage["NotRegularToken"] = "Token is not a regular token";
    TokenErrorMessage["ExternalTokenNotValid"] = "External token not known or not valid";
    TokenErrorMessage["ExternalTokenAlreadyExists"] = "External token already exists";
    TokenErrorMessage["UnexpectedBalanceError"] = "errors.token.unexpectedBalanceError";
})(TokenErrorMessage || (exports.TokenErrorMessage = TokenErrorMessage = {}));
var TokenStandardErrorMessage;
(function (TokenStandardErrorMessage) {
    TokenStandardErrorMessage["NotFound"] = "Token standard not found";
    TokenStandardErrorMessage["StandardAlreadyHasImplementation"] = "Standard already has an implementation";
    TokenStandardErrorMessage["StandardImplementationNotFound"] = "Standard implementation not found";
    TokenStandardErrorMessage["UpgradeNotAvailable"] = "Upgrade not available";
})(TokenStandardErrorMessage || (exports.TokenStandardErrorMessage = TokenStandardErrorMessage = {}));
