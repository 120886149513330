import { APIErrorResponse } from '@archax/shared-types'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

const getApiErrorMessage = (error: AxiosError<APIErrorResponse>): string => {
  const errorMessage = error.response?.data?.error?.message
  const errorDetails = error.response?.data?.error?.details
  if (errorMessage && errorDetails) {
    return `${errorMessage}:\n ${errorDetails}`
  }
  if (errorMessage) {
    return errorMessage
  }

  const status = error.response?.status
  if (!status || status >= 500) {
    return 'Internal server error'
  }

  return 'There was an error'
}

const onApiError = (error: AxiosError<APIErrorResponse>): void => {
  toast.error(getApiErrorMessage(error))
}

export default onApiError
