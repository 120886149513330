import { Button, Typography, styled } from '@mui/material'
import MuiTextField from '@mui/material/TextField'
import { ASAToken } from './utils'

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}))

type Step1Props = {
  token: ASAToken
  connectWallet: () => void
}
export default function Step1({ token, connectWallet }: Step1Props) {
  return (
    <Wrapper>
      <Typography align="center" variant="body2">
        Welcome to the Archax token opt in page
        <br />
        Please follow the steps below to be able to receive the token
      </Typography>
      <form>
        <MuiTextField
          sx={{ mb: 4 }}
          variant="standard"
          fullWidth
          label="ASA ID"
          value={token.deploymentResult.assetId}
        />
        <MuiTextField
          sx={{ mb: 4 }}
          variant="standard"
          fullWidth
          label="ASA Name"
          value={`${token.name} (${token.symbol})`}
        />
        <MuiTextField
          sx={{ mb: 4 }}
          variant="standard"
          fullWidth
          label="Permission manager application"
          value={token.deploymentResult.applicationId}
        />
      </form>
      <Button type="submit" variant="contained" size="large" color="primary" fullWidth onClick={connectWallet}>
        Connect Wallet
      </Button>
    </Wrapper>
  )
}
