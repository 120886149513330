import { ReactElement } from 'react'
import dayjs from 'dayjs'
import { FormLabel, FormControl, styled } from '@mui/material'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.grey[700],
}))

type Props = {
  label: string
  value?: Date
  name: string
  handleChange: (selectedDate: Date) => void
}
export default function DatePicker(props: Props): ReactElement {
  return (
    <FormControl fullWidth>
      <StyledFormLabel data-testid={`date-picker-${props.name}__label`} id={`date-picker-${props.name}__label`}>
        {props.label}
      </StyledFormLabel>
      <MuiDatePicker
        data-testid={`date-picker-${props.name}`}
        label=""
        value={props.value ? dayjs(props.value) : null}
        onChange={(selectedDate: { toDate: () => Date } | null) => {
          props.handleChange(selectedDate?.toDate() as Date)
        }}
      />
    </FormControl>
  )
}
