/**
 * Converts a decimal string value to a "BigInt" string value.
 * Eg. parseUnits('1.23', 6) => 123000000
 * @param value The decimal string value
 * @param decimals The number of decimals
 */
export const parseUnits = (value: string, decimals: number) => {
  if (typeof value != 'string') {
    throw new Error('Value must be a string')
  }

  if (Number(value) == 0) {
    return '0'
  }

  let [integerPart, fractionalPart = ''] = value.split('.')
  fractionalPart = fractionalPart.padEnd(decimals, '0')
  integerPart = BigInt(integerPart).toString()
  if (fractionalPart.length > decimals) {
    throw new Error('Fractional part exceeds the allowed decimals')
  }

  return integerPart + fractionalPart
}

/**
 * Converts a "BigInt" string value to a decimal string value without leading zeros
 * Eg. unparseUnits('123000000', 6) => 1.23
 * @param value The "BigInt" string value
 * @param decimals The number of decimals
 */
export const unparseUnits = (value: string, decimals: number) => {
  if (typeof value != 'string') {
    throw new Error('Value must be a string')
  }

  if (Number(value) == 0) {
    return '0'
  }

  if (value.length <= decimals) {
    return '0.' + value.padStart(decimals, '0')
  }

  const decimalPointIndex = value.length - decimals
  let integerPart = value.substring(0, decimalPointIndex)
  integerPart = BigInt(integerPart).toString()
  let fractionalPart = value.substring(decimalPointIndex)
  fractionalPart = fractionalPart.replace(/0+$/, '')
  return fractionalPart.length > 0 ? integerPart + '.' + fractionalPart : integerPart
}
