import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'

export interface DialogProps extends MuiDialogProps {
  title?: string
  children: React.ReactNode
  cancelLabel?: string
  showCancel?: boolean
  confirmLabel?: string
  onConfirm?: () => void
  onClose?: () => void
}

const Dialog: React.FunctionComponent<DialogProps> = (props) => {
  const {
    maxWidth = 'lg',
    title,
    children,
    cancelLabel = 'Cancel',
    showCancel = false,
    confirmLabel,
    onClose = () => {},
    onConfirm = () => {},
    ...other
  } = props

  return (
    <MuiDialog data-testid="dialog" onClose={onClose} maxWidth={maxWidth} {...other}>
      <DialogTitle component="div" sx={{ marginLeft: 3, marginRight: 3 }}>
        <Typography data-testid="dialog__title" variant="h4" align="center">
          {title}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent data-testid="dialog__content" sx={{ marginTop: 2, padding: 7 }}>
        {children}
      </DialogContent>
      <DialogActions sx={{ marginBottom: 2, display: 'flex', justifyContent: 'center' }}>
        {showCancel && (
          <Button
            data-testid="dialog__cancel-button"
            onClick={onClose}
            sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
            variant="contained"
            fullWidth
          >
            {cancelLabel}
          </Button>
        )}
        {confirmLabel && (
          <Button
            data-testid="dialog__confirm-button"
            onClick={onConfirm}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
