import React from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import themeOptions from './default'
import '@fontsource/montserrat'

interface ThemeProviderProps {
  children?: JSX.Element
}

const ThemeProvider: React.FunctionComponent<ThemeProviderProps> = ({ children }): React.ReactElement => {
  return <MuiThemeProvider theme={themeOptions}>{children}</MuiThemeProvider>
}

export default ThemeProvider
