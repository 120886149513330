import { IRowNode } from 'ag-grid-community'
import { GridApi } from 'ag-grid-community'

export const isFooterLevel = (params: { node?: { level: number } | null }) => params.node?.level === -1

export function calculateColumnSum<T>(gridApi: GridApi<T>, columnId: string): string {
  if (!gridApi) {
    return '0'
  }
  return `${gridApi
    .getRenderedNodes()
    .filter((it: { level: number }) => it.level >= 0)
    .reduce((t: number, it: IRowNode<T>) => t + Number(((it.data || {}) as Record<string, string>)[columnId]), 0)}`
}
