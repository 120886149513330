import { UserFormData } from '@archax/shared-types'
import { Card, Container, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createUser } from '../../api/users'
import UserForm from './components/UserForm'
import onApiError from '../../util/on-api-error'

function CreateUsers() {
  const navigate = useNavigate()
  const { mutate } = useMutation((formData: UserFormData) => createUser(formData), {
    onSuccess: (data) => {
      toast.success('User created successfully')
      navigate('/users')
    },
    onError: onApiError,
  })

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          Create user
        </Typography>
        <UserForm mode="create" onSubmit={mutate} />
      </Card>
    </Container>
  )
}

export default CreateUsers
