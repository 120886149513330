"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadTooLarge = exports.UnsupportedMediaType = exports.ConflictError = exports.NotFoundError = exports.BadRequestError = exports.ForbiddenError = exports.UnauthorizedError = exports.BaseError = exports.CommonErrorMessages = exports.ErrorCode = void 0;
const HttpStatusCode_1 = require("../HttpStatusCode");
var ErrorCode;
(function (ErrorCode) {
    ErrorCode["NOT_FOUND"] = "NOT_FOUND";
    ErrorCode["FORBIDDEN"] = "FORBIDDEN";
    ErrorCode["UNAUTHORIZED"] = "UNAUTHORIZED";
    ErrorCode["BAD_REQUEST"] = "BAD_REQUEST";
    ErrorCode["OPERATION_VERSION_MISMATCH"] = "OPERATION_VERSION_MISMATCH";
    ErrorCode["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    ErrorCode["INVALID_CSRF_TOKEN"] = "INVALID_CSRF_TOKEN";
    ErrorCode["CONFLICT"] = "CONFLICT";
    ErrorCode["UNSUPPORTED_MEDIA_TYPE"] = "UNSUPPORTED_MEDIA_TYPE";
    ErrorCode["PAYLOAD_TOO_LARGE"] = "PAYLOAD_TOO_LARGE";
})(ErrorCode || (exports.ErrorCode = ErrorCode = {}));
var CommonErrorMessages;
(function (CommonErrorMessages) {
    CommonErrorMessages["BadRequest"] = "Bad request. The request was unacceptable, often due to missing a required parameter.";
    CommonErrorMessages["Unauthorized"] = "Unauthorized. You must be authenticated to access this resource.";
    CommonErrorMessages["Forbidden"] = "Forbidden. You do not have permission to access this resource.";
    CommonErrorMessages["NotFound"] = "The requested resource doesn't exist.";
    CommonErrorMessages["Conflict"] = "Conflict";
    CommonErrorMessages["UnsupportedMediaType"] = "Unsupported Media Type";
    CommonErrorMessages["PayloadTooLarge"] = "Payload Too Large";
    CommonErrorMessages["InternalServerError"] = "Internal Server Error. Something went wrong on the platform end.";
    CommonErrorMessages["DocumentNotFound"] = "Document not found";
})(CommonErrorMessages || (exports.CommonErrorMessages = CommonErrorMessages = {}));
class BaseError extends Error {
    code;
    status;
    details;
    constructor(message, { code, details, status }) {
        super(message);
        Error.captureStackTrace(this, this.constructor);
        this.code = code;
        this.status = status;
        this.details = details;
    }
    get name() {
        return this.constructor.name;
    }
}
exports.BaseError = BaseError;
class UnauthorizedError extends BaseError {
    constructor(message = CommonErrorMessages.Unauthorized, errorOptions = {}) {
        const { code = ErrorCode.UNAUTHORIZED, details } = errorOptions;
        super(message, { status: HttpStatusCode_1.HttpStatusCode.Unauthorized, code, details });
    }
}
exports.UnauthorizedError = UnauthorizedError;
class ForbiddenError extends BaseError {
    constructor(message = CommonErrorMessages.Forbidden, errorOptions = {}) {
        const { code = ErrorCode.FORBIDDEN, details } = errorOptions;
        super(message, { status: HttpStatusCode_1.HttpStatusCode.Forbidden, code, details });
    }
}
exports.ForbiddenError = ForbiddenError;
class BadRequestError extends BaseError {
    constructor(message = CommonErrorMessages.BadRequest, errorOptions = {}) {
        const { code = ErrorCode.BAD_REQUEST, details } = errorOptions;
        super(message, { status: HttpStatusCode_1.HttpStatusCode.BadRequest, code, details });
    }
}
exports.BadRequestError = BadRequestError;
class NotFoundError extends BaseError {
    constructor(message = CommonErrorMessages.NotFound, errorOptions = {}) {
        const { code = ErrorCode.NOT_FOUND, details } = errorOptions;
        super(message, { status: HttpStatusCode_1.HttpStatusCode.NotFound, code, details });
    }
}
exports.NotFoundError = NotFoundError;
class ConflictError extends BaseError {
    constructor(message = CommonErrorMessages.Conflict, errorOptions = {}) {
        const { code = ErrorCode.CONFLICT, details } = errorOptions;
        super(message, { status: HttpStatusCode_1.HttpStatusCode.Conflict, code, details });
    }
}
exports.ConflictError = ConflictError;
class UnsupportedMediaType extends BaseError {
    constructor(message = CommonErrorMessages.UnsupportedMediaType, errorOptions = {}) {
        const { code = ErrorCode.UNSUPPORTED_MEDIA_TYPE, details } = errorOptions;
        super(message, { status: HttpStatusCode_1.HttpStatusCode.UnsupportedMediaType, code, details });
    }
}
exports.UnsupportedMediaType = UnsupportedMediaType;
class PayloadTooLarge extends BaseError {
    constructor(message = CommonErrorMessages.PayloadTooLarge, errorOptions = {}) {
        const { code = ErrorCode.PAYLOAD_TOO_LARGE, details } = errorOptions;
        super(message, { status: HttpStatusCode_1.HttpStatusCode.PayloadTooLarge, code, details });
    }
}
exports.PayloadTooLarge = PayloadTooLarge;
