import { useParams } from 'react-router-dom'
import EditOrganization from './EditOrganization'

function AdminEditOrganization() {
  const { id: organizationId } = useParams<{ id: string }>()

  return <>{organizationId && <EditOrganization organizationId={organizationId} />}</>
}

export default AdminEditOrganization
