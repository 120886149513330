import { LoginResponse } from '@archax/shared-types'
import axios, { AxiosResponse } from 'axios'
import { LoginFormData } from './types'

const API_URL = process.env.REACT_APP_API_URL

const authService = axios.create({
  baseURL: API_URL,
})

function onResponse(response: AxiosResponse) {
  if (response.data) {
    return response.data
  }
  return response
}

authService.interceptors.response.use(onResponse)

async function login(credentials: LoginFormData): Promise<LoginResponse> {
  const result = await authService.post<never, LoginResponse>('/login', credentials)
  return result
}

export { login }
