import { NFTInfo, OperationType, SendNFTTokenFormData, Token, TraderAddress } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import onApiError from '../../../../util/on-api-error'
import { HederaAssociateHelper } from '../HederaAssociateHelper'

interface SendNFTTokenProps {
  tokenId: string
  onSuccess: () => void
  assets: NFTInfo[]
  account?: TraderAddress
  poolToken?: Token
  token: Token
}

const validationSchema = Yup.object()
  .shape({
    nftTokenId: Yup.number()
      .positive()
      .integer('NFT id must be a positive number.')
      .min(0, 'NFT id must be a positive number.')
      .typeError('NFT id must be a positive number.'),
  })
  .required()

function SendNFTTokenForm({ tokenId, onSuccess, account, assets, poolToken, token }: SendNFTTokenProps) {
  const initialValues = { nftTokenId: assets?.[0].nftTokenId || 0 }
  const { mutate } = useMutation(
    (formData: SendNFTTokenFormData) => createOperation(OperationType.Send, formData, tokenId, account?.trader?.id),
    {
      onSuccess: () => {
        toast.success('Send token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = (data: typeof initialValues) => {
    const operationData = account ? { traderAddressId: account.id, ...data } : { poolTokenId: poolToken!.id, ...data }
    mutate(operationData)
  }

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<SendNFTTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Later on if we move to collections we need add a NFT id selector here
          The ids for the selector should come from: account.assets */}

      <ButtonGroup fullWidth>
        <Button
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Send
        </Button>
      </ButtonGroup>
      <HederaAssociateHelper token={token} />
    </form>
  )
}
export default SendNFTTokenForm
