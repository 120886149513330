import {
  ChainProtocol,
  CreateTokenOperationData,
  Operation,
  OperationType,
  READABLE_OPERATION_TYPE,
  READABLE_STANDARD,
  TokenStandardType,
} from '@archax/shared-types'
import { Button, Card, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridApi, GridOptions, ICellRendererParams, ITooltipParams, ValueGetterParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cancelOperation, getMyOperations } from '../../api/operations'
import Dialog from '../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import onApiError from '../../util/on-api-error'
import { getTokenIdOnChain } from '../../util/get-token-id-on-chain'
import { readableTokenProtocol } from '../../util/token-utils'

function EditOperations() {
  const navigate = useNavigate()
  const [selectedRow, setSelectedRow] = useState<Operation | null>(null)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [gridApi, setGridApi] = useState<null | GridApi>(null)

  const { mutate: cancelMutation } = useMutation((id: string) => cancelOperation(id), {
    onSuccess: () => {
      toast.success('Request cancelled.')
      gridApi?.refreshServerSide()
    },
    onError: onApiError,
  })

  const columnDefs: ColDef<Operation>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          const data =
            operation.type === OperationType.CreateToken
              ? (operation.data as CreateTokenOperationData)
              : operation.token
          return data ? `${data?.name} (${data?.symbol})` : ''
        },
        tooltipValueGetter: (params: ITooltipParams<Operation>) => {
          const operation = params.data!
          return operation.type === OperationType.CreateToken ? '' : getTokenIdOnChain(operation.token!)
        },
      },
      {
        field: 'action',
        headerName: 'Action',
        minWidth: 450,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return READABLE_OPERATION_TYPE[params.data!.type]
        },
      },
      {
        field: 'protocol',
        headerName: 'Protocol',
        minWidth: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          if (operation.type === OperationType.CreateToken) {
            return readableTokenProtocol(
              (operation.data as CreateTokenOperationData).protocol as ChainProtocol,
              (operation.data as CreateTokenOperationData).privacyEnabled,
            )
          }
          return readableTokenProtocol(
            operation.token?.standard.chain.protocol as ChainProtocol,
            operation.token?.privacyEnabled,
          )
        },
      },
      {
        field: 'standard',
        headerName: 'Standard',
        minWidth: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          return operation.type === OperationType.CreateToken
            ? READABLE_STANDARD[(operation.data as CreateTokenOperationData).standard as TokenStandardType]
            : READABLE_STANDARD[operation.token?.standard.name as TokenStandardType]
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<Operation>) => {
          return (
            <Box display="flex">
              <Box marginRight={1}>
                <Button
                  role="button"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate(`/tokens/requests/edit/${params.data!.id}`)
                  }}
                >
                  Edit
                </Button>
              </Box>
              <Box marginRight={1}>
                <Button
                  role="button"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSelectedRow(params.data!)
                    setShowCancelDialog(true)
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )
        },
      },
    ],
    [navigate],
  )

  const handleCancel = async (id: string) => {
    await cancelMutation(id)
    setShowCancelDialog(false)
  }

  const defaultColDef: ColDef = {
    filter: false,
  }

  const gridOptions: GridOptions = {
    columnDefs,
    defaultColDef,
  }

  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          Token creation requests
        </Typography>
        <Typography align="left">You can edit the token details before it is approved and created.</Typography>
        <Box>
          <ServerSideGrid gridOptions={gridOptions} queryFn={getMyOperations} setParentGridApi={setGridApi} />
        </Box>
      </Card>
      <Dialog
        title="Are you sure that you want to cancel this request?"
        onConfirm={() => handleCancel(selectedRow?.id!)}
        confirmLabel="Cancel request"
        cancelLabel="Close"
        onClose={() => setShowCancelDialog(false)}
        open={showCancelDialog}
        showCancel
      >
        <Typography variant="body2">You can not revert this action.</Typography>
      </Dialog>
    </Container>
  )
}

export default EditOperations
