import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AppRouter from './AppRouter'
import SessionTimeout from './components/SessionTimeout/SessionTimeout'
import ThemeProvider from './components/ThemeProvider/Theme'
import { GlobalContextProvider } from './context'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'
import './ag-grid-archax.css'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY!)

const queryClient = new QueryClient()

function App() {
  return (
    <GlobalContextProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <div className="App">
              <ToastContainer position="top-right" autoClose={5000} hideProgressBar={true} closeOnClick />
              <BrowserRouter>
                <SessionTimeout>
                  <AppRouter />
                </SessionTimeout>
              </BrowserRouter>
            </div>
          </ThemeProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </GlobalContextProvider>
  )
}

export default App
