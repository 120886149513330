import {
  ChainProtocol,
  Currency,
  READABLE_PROTOCOL,
  TABLE_HEADER_NAMES,
  TOKEN_BALANCE_ERROR_PLACEHOLDER_VALUE,
  TokenType,
  TraderHoldingsData,
} from '@archax/shared-types'
import { CircularProgress, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community'
import { useMemo } from 'react'
import { downloadCSV } from '../../api/axios'
import { getTraderHoldings, getTraderHoldingsCSVUrl } from '../../api/traders'
import { ClientSideGrid } from '../../components/ClientSideGrid'
import currencyFormatter from '../../util/currency-formatter'
import onApiError from '../../util/on-api-error'
import tokenBalanceFormatter from '../../util/token-balance-formatter'
import TraderHoldingsDetail from './TraderHoldingsDetail'
import BalanceErrorTooltip from './components/BalanceErrorTooltip'

interface TraderHoldingsProps {
  id: string
}

function TraderHoldings({ id }: TraderHoldingsProps) {
  const { isLoading, data, refetch } = useQuery(
    [`get-trader-${id}-holdings`],
    () =>
      getTraderHoldings({
        id,
      }),
    {
      retry: false,
      onError: onApiError,
    },
  )

  const { data: holdings } = data?.data || {}

  const columnDefs: ColDef<TraderHoldingsData>[] = useMemo(
    () => [
      {
        field: 'tokenName',
        headerName: TABLE_HEADER_NAMES.common.token_name,
        flex: 1,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'traderAddressName',
        valueGetter: (params: ValueGetterParams<TraderHoldingsData>) => {
          return `${params.data!.traderAddressName} (${params.data!.traderAddress})`
        },
        headerName: TABLE_HEADER_NAMES.common.address,
        flex: 1,
        minWidth: 200,
        sortable: false,
        tooltipValueGetter: (params) => {
          return `${params.data!.traderAddressName} (${params.data!.traderAddress})`
        },
      },
      {
        field: 'protocol',
        valueGetter: (params: ValueGetterParams<TraderHoldingsData>) => {
          return READABLE_PROTOCOL[params.data!.chain.protocol as ChainProtocol]
        },
        headerName: TABLE_HEADER_NAMES.common.protocol,
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 100,
        sortable: false,
        cellRenderer: (params: ValueGetterParams<TraderHoldingsData>) => {
          if (params.data!.balance !== TOKEN_BALANCE_ERROR_PLACEHOLDER_VALUE.toString()) {
            return tokenBalanceFormatter(params.data!.balance, params.data!.tokenDecimals)
          }
          return <BalanceErrorTooltip />
        },
      },
      {
        field: 'tokenPrice',
        headerName: TABLE_HEADER_NAMES.common.token_price,
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueGetter: (params: ValueGetterParams<TraderHoldingsData>) => {
          return currencyFormatter(params.data!.tokenPrice, { prefix: params.data!.tokenCurrency, decimals: 2 })
        },
      },
      {
        field: 'value',
        headerName: TABLE_HEADER_NAMES.common.value,
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueGetter: (params: ValueGetterParams<TraderHoldingsData>) => {
          if (!params.data!.value) return currencyFormatter('0', { decimals: 2 })

          if (typeof params.data!.value! === 'object') {
            return Object.entries(params.data!.value! as Record<Currency, string>)
              .map(([currency, value]) => {
                return currencyFormatter(value as string, { decimals: 2, prefix: currency as Currency })
              })
              .join(' + ')
          }
          return currencyFormatter(params.data!.value, { prefix: params.data!.tokenCurrency, decimals: 2 })
        },
      },
      {
        field: 'expandable',
        cellRenderer: 'agGroupCellRenderer',
        headerName: 'Underlying',
        width: 139,
      },
    ],
    [],
  )

  const handleCSVDownload = () => downloadCSV(getTraderHoldingsCSVUrl(id))

  const isRowMaster = useMemo(
    () => (dataItem: TraderHoldingsData) => {
      return dataItem.tokenType === TokenType.Pool
    },
    [],
  )

  const detailCellRenderer = useMemo(() => {
    return TraderHoldingsDetail
  }, [])

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: holdings,
      domLayout: 'autoHeight',
      masterDetail: true,
      isRowMaster: isRowMaster,
      detailCellRenderer,
      defaultColDef: { resizable: true },
    }),
    [columnDefs, holdings, isRowMaster, detailCellRenderer],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          Holdings
        </Typography>
      </Box>
      {isLoading && <CircularProgress />}
      {!isLoading && holdings && (
        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
          {holdings.length === 0 && <Typography>No holdings</Typography>}
          {holdings.length > 0 && (
            <ClientSideGrid gridOptions={gridOptions} handleRefresh={refetch} handleCSVDownload={handleCSVDownload} />
          )}
          <Divider />
        </Box>
      )}
    </div>
  )
}

export default TraderHoldings
