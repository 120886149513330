import { Token, TokenType } from '@archax/shared-types'
import { Box, Card, CircularProgress, Tabs, Typography, styled } from '@mui/material'
import Tab from '@mui/material/Tab'
import { Container } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { getToken } from '../../api/tokens'
import TabPanel from '../../components/ui/TabPanel/TabPanel'
import { isEVMProtocol } from '../../util/token-options'
import RegisteredTokens from './components/RegisteredTokens'
import { ShowTokenDocuments } from './components/ShowTokenDocuments'
import TokenBlacklist from './components/TokenBlacklist'
import TokenCapTable from './components/TokenCapTable'
import TokenDetails from './components/TokenDetails'
import TokenHistory from './components/TokenHistory'
import TokenWhitelist from './components/TokenWhitelist'
import TokenConfiguration from './components/TokenConfiguration'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  minHeight: '500px',
  h5: {
    color: theme.palette.grey[50],
  },
  svg: {
    color: theme.palette.grey[50],
  },
}))

function TokenManagement() {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams() as { id: string }
  const { isLoading, data } = useQuery([`get-token-${id}`], () => getToken(id as string), {
    retry: false,
  })
  const [isPoolToken, setIsPoolToken] = useState(false)
  const [isExternalToken, setIsExternalToken] = useState(false)

  useEffect(() => {
    if (data) {
      setIsPoolToken((data?.data as Token).tokenType === TokenType.Pool)
      setIsExternalToken((data?.data as Token).tokenType === TokenType.External)
    }
  }, [data])

  const token = data?.data as Token

  const showBlacklistTab = isEVMProtocol(token?.standard.chain.protocol)
    ? token.onChainData?.isBlacklistable
    : token?.blacklistEnabled && !isExternalToken

  const tabs = useMemo(
    () =>
      [
        { label: 'History', component: <TokenHistory token={token} />, visible: true, path: '/history' },
        {
          label: 'Whitelist',
          component: <TokenWhitelist token={data?.data as Token} />,
          visible: !isExternalToken,
          path: '/whitelist',
        },
        {
          label: 'Blacklist',
          component: <TokenBlacklist token={data?.data as Token} />,
          visible: showBlacklistTab,
          path: '/blacklist',
        },
        { label: 'Cap table', component: <TokenCapTable token={token} />, visible: true, path: '/cap-table' },
        {
          label: 'Tokens',
          component: <RegisteredTokens token={token} />,
          visible: isPoolToken && !isExternalToken,
          path: '/tokens',
        },
        {
          label: 'Documents',
          component: <ShowTokenDocuments token={token} />,
          visible: isEVMProtocol(token?.standard.chain.protocol) && !isExternalToken,
          path: '/documents',
        },
        {
          label: 'Configuration',
          component: <TokenConfiguration token={token} />,
          visible: isEVMProtocol(token?.standard.chain.protocol) && !isExternalToken,
          path: '/configuration',
        },
      ].filter((tab) => tab.visible),
    [token, isExternalToken, isPoolToken],
  )

  let currentTabIndex = tabs.findIndex((tab) => location.pathname.endsWith(tab.path))
  if (currentTabIndex === -1) {
    currentTabIndex = 0
  }

  const handleChangeTab = (event: React.SyntheticEvent, value: any) => {
    navigate(`/tokens/${id}${tabs[value].path}`)
  }

  if (isLoading) {
    return (
      <Wrapper>
        <CircularProgress />
        <Typography variant="h5">Loading token data...</Typography>
      </Wrapper>
    )
  }

  if (!data) {
    // TODO: Create a NotFound component
    return <Typography variant="h2">Not found</Typography>
  }

  return (
    <Container maxWidth="xl">
      <Box mb={3}>
        <TokenDetails token={data?.data as Token} />
      </Box>

      <Card sx={{ p: 7, mb: 5 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTabIndex} onChange={handleChangeTab}>
            {tabs.map((tab, index) => (
              <Tab label={tab.label} key={index} />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab, index) => (
          <TabPanel value={currentTabIndex} index={index} key={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Card>
    </Container>
  )
}

export default TokenManagement
