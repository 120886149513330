"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserErrorMessage = void 0;
var UserErrorMessage;
(function (UserErrorMessage) {
    UserErrorMessage["UserNotFound"] = "User not found";
    UserErrorMessage["UserAlreadyExists"] = "User already exists";
    UserErrorMessage["NoRolesFound"] = "No roles found";
    UserErrorMessage["PasswordsAreTheSame"] = "Password cannot be the same as previously used password";
    UserErrorMessage["PasswordPolicy"] = "The new password doesn't meet password policy requirements.";
    UserErrorMessage["InvalidPassword"] = "Invalid password";
    UserErrorMessage["NotAllowed"] = "Not allowed to reset this user password";
})(UserErrorMessage || (exports.UserErrorMessage = UserErrorMessage = {}));
