"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationErrorMessage = void 0;
var OrganizationErrorMessage;
(function (OrganizationErrorMessage) {
    OrganizationErrorMessage["CreationForbidden"] = "You cannot create a new organization";
    OrganizationErrorMessage["ModificationForbidden"] = "You can only modify own organization";
    OrganizationErrorMessage["LogoNotFound"] = "Organization logo not found";
    OrganizationErrorMessage["OrganizationNotFound"] = "Organization not found";
    OrganizationErrorMessage["OrganizationNameAlreadyExists"] = "Organization name already exists";
    OrganizationErrorMessage["OrganizationInternalIdAlreadyExists"] = "Organization internal id already exists";
    OrganizationErrorMessage["InternalOrganizationIdNotFound"] = "Internal organization id not found";
    OrganizationErrorMessage["CreateCustodialBlockchainAccountsFailed"] = "Create custodial blockchain accounts failed";
})(OrganizationErrorMessage || (exports.OrganizationErrorMessage = OrganizationErrorMessage = {}));
