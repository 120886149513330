import { Box, Button, CircularProgress, styled } from '@mui/material'

export const StyledForm = styled('form')(() => ({
  minWidth: '35rem',
}))

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  marginRight: '1rem',
  color: `#FFFFFF`,
}))

export const StyledBoxAction = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '1rem',
}))

export const StyledButtonAction = styled(Button)(() => ({
  backgroundColor: '#FFFFFF',
  color: '#000000',
  '&:hover': {
    color: '#FFFFFF',
  },
}))
