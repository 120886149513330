import { OperationType, Token, TokenWhitelistTradersData, TransferTokenFormData } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup, CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import { getTokenWhitelistTraders } from '../../../../api/tokens'
import { Select } from '../../../../components/ui/Select'
import TextField from '../../../../components/ui/TextField/TextField'
import onApiError from '../../../../util/on-api-error'
import { tokenAmountValidator } from '../../../../util/yup-validators'

interface TransferTokenProps {
  onSuccess: () => void
  tokenAddress: string
  token: Token
  poolTokenId: string
}

function TransferTokenForm({ onSuccess, tokenAddress, token: { id, decimals }, poolTokenId }: TransferTokenProps) {
  const validationSchema = Yup.object()
    .shape({
      amount: tokenAmountValidator({ decimals, positive: true }),
    })
    .required()

  const initialValues = { amount: '0', recipientAddress: '' }

  const [recipientAddress, setRecipientAddress] = useState<string>('')

  const { data: whitelistedAddresses, isLoading } = useQuery(
    [`get-whitelisted-addresses-${tokenAddress}`],
    () => getTokenWhitelistTraders({ id, limit: 100, offset: 0, with_treasury: true }),
    {
      retry: false,
      onSuccess: (data) => {
        if (data.data.data.length === 1) {
          setRecipientAddress(data.data.data[0].traderAddress.address)
        }
      },
    },
  )

  const { mutate } = useMutation(
    (formData: TransferTokenFormData) => createOperation(OperationType.TransferToken, formData, poolTokenId),
    {
      onSuccess: (data) => {
        toast.success('Transfer token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = (data: TransferTokenFormData) => {
    mutate({
      token: { tokenId: id },
      trader: {
        recipientAddress: recipientAddress!,
      },
      amount: data.amount,
    })
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<TransferTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Select
            name="recipientAddress"
            control={control}
            value={recipientAddress}
            label="Recipient address"
            options={whitelistedAddresses?.data.data.map((address: TokenWhitelistTradersData) => ({
              label: address.traderAddress.trader.name + ' - ' + address.traderAddress.address,
              value: address.traderAddress.address,
            }))}
            onChange={(e) => setRecipientAddress(e.target.value as string)}
          />

          <TextField name="amount" type="number" control={control} label="Number to send" />

          <ButtonGroup fullWidth>
            <Button
              disabled={isSubmitButtonDisabled}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
            >
              Send
            </Button>
          </ButtonGroup>
        </>
      )}
    </form>
  )
}
export default TransferTokenForm
