import { OperationType, TokenWhitelistTradersData, TransferTokenFormData } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup, CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import { getTokenWhitelistTraders } from '../../../../api/tokens'
import { Select } from '../../../../components/ui/Select'
import onApiError from '../../../../util/on-api-error'

interface TransferTokenProps {
  onSuccess: () => void
  tokenAddress: string
  tokenId: string
  poolTokenId: string
  assetId: number
}

const validationSchema = Yup.object()
  .shape({
    traderAddressId: Yup.string().required('Recipient address is required'),
  })
  .required()

const initialValues = { recipientAddress: '' }

function TransferNFTTokenForm({ onSuccess, tokenAddress, tokenId, poolTokenId, assetId }: TransferTokenProps) {
  const [recipientAddress, setRecipientAddress] = useState<string>('')

  const { data: whitelistedAddresses, isLoading } = useQuery(
    [`get-whitelisted-addresses-${tokenAddress}`],
    () => getTokenWhitelistTraders({ id: tokenId, limit: 100, offset: 0, with_treasury: true }),
    {
      retry: false,
      onSuccess: (data) => {
        if (data.data.data.length === 1) {
          setRecipientAddress(data.data.data[0].traderAddress.address)
        }
      },
    },
  )

  const { mutate } = useMutation(
    (formData: TransferTokenFormData) => createOperation(OperationType.TransferToken, formData, poolTokenId),
    {
      onSuccess: (data) => {
        toast.success('Transfer token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = () => {
    mutate({
      token: { tokenId },
      trader: {
        recipientAddress: recipientAddress!,
      },
      nftTokenId: assetId,
    })
  }

  const { control } = useForm<{ recipientAddress: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Select
            name="recipientAddress"
            control={control}
            value={recipientAddress}
            label="Recipient address"
            options={whitelistedAddresses?.data.data.map((address: TokenWhitelistTradersData) => ({
              label: address.traderAddress.trader.name + ' - ' + address.traderAddress.address,
              value: address.traderAddress.address,
            }))}
            onChange={(e) => setRecipientAddress(e.target.value as string)}
          />

          <ButtonGroup fullWidth>
            <Button
              disabled={recipientAddress === ''}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              onClick={onSubmit}
            >
              Send
            </Button>
          </ButtonGroup>
        </>
      )}
    </form>
  )
}
export default TransferNFTTokenForm
