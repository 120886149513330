import { waitForConfirmation } from 'algosdk'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'

import { Button, Card, Container, Stack, Typography, styled } from '@mui/material'
import { toast } from 'react-toastify'

import ArchaxLogo from '../../../../assets/logo.svg'
import { ASAToken, ASATokenAccount, generateOptInApplicationTx, generateOptInAssetTx, getAlgoClient } from './utils'
import { LoadingSpinner, WalletConnectDialog } from './styled-components'
import Step1 from './Step1'
import Step2 from './Step2'
import StepSuccess from './StepSuccess'
import StepFailed from './StepFailed'
import { useConnection } from './hooks'
import { getTokenDataToOptIn } from '../../../../api/tokens'

const StyledButton = styled(Button)(() => ({
  display: 'flex',
  gap: '5px',

  maxWidth: '200px',
  maxHeight: '50px',
  background: 'none',
  border: 'solid 1px white',
  color: 'white',
}))

export default function AlgorandOptInFlow() {
  const { id } = useParams()
  const algodClient = useMemo(() => getAlgoClient(), [])

  const { walletConnection, peraWalletConnect, wcConnect, disconnect, signTransaction } = useConnection()

  const [openWalletModal, setOpenWalletModal] = useState(false)
  const [token, setToken] = useState<ASAToken>()
  const [tokenAccount, setTokenAccount] = useState<ASATokenAccount>()
  const [currentStep, setCurrentStep] = useState<ReactElement>()

  const loadAccountInformation = useCallback(async (token: ASAToken, walletAddress: string) => {
    const account = await algodClient.accountInformation(walletAddress).do()
    const newTokenAccount = {
      assetOptedIn: account.assets.some(
        (it: { 'asset-id': number }) => Number(it['asset-id']) === Number(token.deploymentResult.assetId),
      ),
      applicationOptedIn: account['apps-local-state'].some(
        (it: { id: number }) => Number(it.id) === Number(token.deploymentResult.applicationId),
      ),
    }
    setTokenAccount(newTokenAccount)
  }, [])

  const optInAsset = useCallback(
    async (assetId: number) => {
      if (token && walletConnection) {
        try {
          const transaction = await generateOptInAssetTx(algodClient, walletConnection?.address, assetId)
          const signedTransaction = await signTransaction(transaction)
          await algodClient.sendRawTransaction(signedTransaction!).do()
          await waitForConfirmation(algodClient, transaction.txID(), 10)
          loadAccountInformation(token, walletConnection.address)
        } catch (err) {
          toast.error('An error occurred while trying to sign the transaction')
          console.error('Failed to sign tx:', err)
        }
      }
    },
    [walletConnection, token, tokenAccount],
  )
  const optInApplication = useCallback(
    async (applicationId: number) => {
      if (token && walletConnection) {
        try {
          const transaction = await generateOptInApplicationTx(algodClient, walletConnection?.address, applicationId)
          const signedTransaction = await signTransaction(transaction)

          await algodClient.sendRawTransaction(signedTransaction!).do()
          await waitForConfirmation(algodClient, transaction.txID(), 10)

          loadAccountInformation(token, walletConnection.address)
        } catch (err) {
          console.error('Failed to sign tx:', err)
        }
      }
    },
    [token, tokenAccount],
  )

  useEffect(() => {
    ;(async () => {
      if (id) {
        try {
          const { data: token } = await getTokenDataToOptIn(id)
          setToken(token)
        } catch (err) {
          setCurrentStep(
            <StepFailed
              content={
                <>
                  Huh, It seem we couldn't find the ASA in our system, please contact your sales agent and confirm that
                  the correct link has been shared with you.
                </>
              }
            />,
          )
          console.error('Failed to get token and application information:', err)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (token) {
      if (tokenAccount) {
        if (tokenAccount?.assetOptedIn && tokenAccount?.applicationOptedIn) {
          setCurrentStep(<StepSuccess />)
        } else {
          setCurrentStep(
            <Step2
              token={token}
              tokenAccount={tokenAccount}
              optInAsset={optInAsset}
              optInApplication={optInApplication}
            />,
          )
        }
      } else {
        setCurrentStep(<Step1 token={token} connectWallet={() => setOpenWalletModal(true)} />)
      }
    }
  }, [token, tokenAccount])

  useEffect(() => {
    if (token) {
      if (walletConnection) {
        loadAccountInformation(token, walletConnection.address)
      } else {
        setCurrentStep(<Step1 token={token} connectWallet={() => setOpenWalletModal(true)} />)
      }
    }
  }, [token, walletConnection])

  return (
    <Container maxWidth="xl">
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <img
          width="64px"
          height="64px"
          alt="Archax logo"
          src={ArchaxLogo}
          style={{
            backgroundColor: 'transparent',
            padding: '7px',
            borderRadius: '6px',
            objectFit: 'cover',
          }}
        />
        {walletConnection ? (
          <StyledButton
            data-testid="dialog__cancel-button"
            onClick={disconnect}
            sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
            variant="contained"
            fullWidth
          >
            Disconnect wallet
            <LogoutIcon />
          </StyledButton>
        ) : (
          <StyledButton
            data-testid="dialog__cancel-button"
            onClick={() => setOpenWalletModal(true)}
            sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
            variant="contained"
            fullWidth
          >
            Connect wallet
            <LoginIcon />
          </StyledButton>
        )}
      </Stack>
      <Container maxWidth="md">
        <Card sx={{ p: 7, marginTop: 20, minHeight: '550px' }}>
          <Stack gap={'25px'}>
            <Typography align="center" variant="h2">
              Token Opt In
            </Typography>
            {currentStep || <LoadingSpinner />}
          </Stack>
        </Card>
      </Container>
      <WalletConnectDialog
        open={openWalletModal}
        onClose={() => setOpenWalletModal(false)}
        peraWallet={() => {
          try {
            setOpenWalletModal(false)
            peraWalletConnect()
          } catch (error) {
            toast.error('An error occurred while trying to connect the wallet')
          }
        }}
        walletConnect={() => {
          try {
            setOpenWalletModal(false)
            wcConnect()
          } catch (error) {
            toast.error('An error occurred while trying to connect the wallet')
          }
        }}
      />
    </Container>
  )
}
