import * as Yup from 'yup'
import { parseUnits } from './units'

interface TokenAmountValidatorArgs {
  decimals: number
  min?: string
  max?: string
  positive?: boolean
}

export const tokenAmountValidator = ({ decimals, min, max, positive }: TokenAmountValidatorArgs) =>
  Yup.string()
    .transform((value) => {
      try {
        return parseUnits(value, decimals)
      } catch (error) {
        // If the value cannot be parsed, return the original value
        // and the maxDecimalPlaces validation should throw an error
        return value
      }
    })
    .required('Amount is required')
    .test(
      'bigIntString',
      `${positive ? 'Amount must be a positive number' : 'Amount must be greater than or equal to 0'}`,
      (value) => {
        return /^\d*\.?\d*$/.test(value)
      },
    )
    .test('maxDecimalPlaces', `Amount must have a maximum of ${decimals} decimal places`, (value) => {
      const decimalPart = (value.toString().split('.')[1] || '').length
      return decimalPart <= decimals
    })
    .test('positive', `Amount must be greater than 0`, (value) => {
      if (positive) {
        return BigInt(parseUnits(value, decimals)) > BigInt(0)
      }

      return true
    })
    .test('min', `Amount must be greater than or equal to ${min}`, (value) => {
      if (min) {
        return BigInt(parseUnits(value, decimals)) >= BigInt(parseUnits(min, decimals))
      }

      return true
    })
    .test('max', `Amount must be less than or equal to ${max}`, (value) => {
      if (max) {
        return BigInt(parseUnits(value, decimals)) <= BigInt(parseUnits(max, decimals))
      }
      return true
    })
