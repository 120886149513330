import { DistributionOperationData, Token } from '@archax/shared-types'
import { Button, ButtonProps } from '@mui/material'
import { useState } from 'react'
import Dialog from '../../../components/Dialog/Dialog'
import { DistributionForm } from './DistributionForm'
import { DistributionDefaults } from './DistributionForm/DistributionForm'

const DistributionModal = ({
  token,
  defaults,
  label,
  btnOptions,
  distributionId,
}: {
  token: Token
  defaults?: DistributionOperationData
  label?: string
  btnOptions?: ButtonProps
  distributionId?: string
}) => {
  const [showDistributionDialog, setShowDistributionDialog] = useState(false)

  return (
    <>
      <Button
        onClick={() => {
          setShowDistributionDialog(true)
        }}
        {...btnOptions}
      >
        {label ?? 'Distribute'}
      </Button>
      <Dialog title={''} onClose={() => setShowDistributionDialog(false)} open={showDistributionDialog} fullWidth>
        <DistributionForm
          token={token}
          defaults={defaults}
          onClose={() => setShowDistributionDialog(false)}
          distributionId={distributionId}
        />
      </Dialog>
    </>
  )
}

export default DistributionModal
