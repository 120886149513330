import RefreshIcon from '@mui/icons-material/Refresh'
import { Box } from '@mui/material'
import { GridOptions } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import DownloadIcon from '../DownloadIcon/DownloadIcon'

export interface ClientSideGridProps {
  gridOptions: Partial<GridOptions>

  /**
   * Optional handler for refreshing the grid data.
   * This function is called when the user clicks the refresh icon.
   */
  handleRefresh?: () => void

  /**
   * Optional handler for initiating a CSV download of the grid's data.
   * This function is called when the user clicks the download icon.
   */
  handleCSVDownload?: () => Promise<void>
}

const ClientSideGrid: React.FC<ClientSideGridProps> = ({ gridOptions, handleRefresh, handleCSVDownload }) => {
  return (
    <Box role="table-grid-wrapper" width={'100%'} className="ag-theme-material">
      <Box display={'flex'} justifyContent={'flex-end'} alignContent={'center'}>
        {handleRefresh && (
          <RefreshIcon
            sx={{ cursor: 'pointer', m: 1, color: 'secondary.main' }}
            onClick={handleRefresh}
            aria-label="Refresh"
          ></RefreshIcon>
        )}
        {handleCSVDownload && <DownloadIcon onClick={handleCSVDownload} aria-label="Download CSV"></DownloadIcon>}
      </Box>
      <AgGridReact {...gridOptions}></AgGridReact>
    </Box>
  )
}

export default ClientSideGrid
