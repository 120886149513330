import { OperationType, Token, TraderAddress, WipeTokenFormData } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import TextField from '../../../../components/ui/TextField/TextField'
import onApiError from '../../../../util/on-api-error'
import { tokenAmountValidator } from '../../../../util/yup-validators'

interface WipeTokenProps {
  token: Token
  onSuccess: () => void
  account?: TraderAddress
  poolToken?: Token
}

function WipeTokenForm({ token: { id, decimals }, onSuccess, account, poolToken }: WipeTokenProps) {
  const validationSchema = Yup.object()
    .shape({
      amount: tokenAmountValidator({ decimals, positive: true }),
    })
    .required()

  const initialValues = { amount: '0' }

  const { mutate } = useMutation(
    (formData: WipeTokenFormData) => createOperation(OperationType.Wipe, formData, id, account && account.trader.id),
    {
      onSuccess: () => {
        toast.success('Wipe token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = (data: { amount: string }) => {
    const operationData = account ? { traderAddressId: account.id, ...data } : { poolTokenId: poolToken!.id, ...data }
    mutate(operationData)
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<WipeTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField name="amount" type="number" control={control} label="Number to wipe" />

      <ButtonGroup fullWidth>
        <Button
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Wipe
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default WipeTokenForm
