import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

export interface LoadingSpinnerProps {
  open?: boolean
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ open = true }): React.ReactElement => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingSpinner
