import MuiCheckbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { Controller } from 'react-hook-form'

export interface CheckboxOption {
  label?: String
  value: string | number
}

interface CheckboxProps {
  name: string
  control: any
  options: CheckboxOption[]
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({ name, control, options }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: groupValues, onChange }, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <Typography>Roles</Typography>
          {options.map(({ value, label }, index: number) => (
            <FormControlLabel
              key={index}
              label={label}
              control={
                <MuiCheckbox
                  value={value}
                  checked={groupValues.includes(value)}
                  onChange={(event, checked) => {
                    if (groupValues.includes(value)) {
                      onChange(groupValues.filter((item: string) => item !== value))
                    } else {
                      onChange([...groupValues, value])
                    }
                  }}
                />
              }
            />
          ))}
        </FormControl>
      )}
    />
  )
}

export default Checkbox
