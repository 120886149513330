import MuiDownloadIcon from '@mui/icons-material/Download'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { useState } from 'react'
import { toast } from 'react-toastify'

export interface DownloadIconProps {
  onClick: () => Promise<void>
}

const DownloadIcon: React.FunctionComponent<DownloadIconProps> = ({ onClick }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const handleDownload = async () => {
    setIsDownloading(true)
    try {
      await onClick()
    } catch (error) {
      toast.error('Download failed')
    }

    setIsDownloading(false)
  }
  return isDownloading ? (
    <DownloadingIcon
      sx={{
        m: 1,
        color: 'secondary.main',
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          from: { transform: 'rotate(0deg)' },
          to: { transform: 'rotate(360deg)' },
        },
      }}
    />
  ) : (
    <MuiDownloadIcon
      sx={{ cursor: 'pointer', m: 1, color: 'secondary.main' }}
      onClick={handleDownload}
    ></MuiDownloadIcon>
  )
}

export default DownloadIcon
