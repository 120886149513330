import { BurnNFTTokenFormData, NFTInfo, OperationType } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import onApiError from '../../../../util/on-api-error'

interface BurnNFTTokenProps {
  tokenId: string
  onSuccess: () => void
  assets: NFTInfo[]
}

const validationSchema = Yup.object()
  .shape({
    nftTokenId: Yup.number()
      .positive()
      .integer('NFT id must be a positive number.')
      .min(0, 'NFT id must be a positive number.')
      .typeError('NFT id must be a positive number.'),
  })
  .required()

function BurnNFTTokenForm({ tokenId, onSuccess, assets }: BurnNFTTokenProps) {
  const initialValues = { nftTokenId: assets?.[0].nftTokenId || 0 }
  const { mutate } = useMutation(
    (formData: BurnNFTTokenFormData) => createOperation(OperationType.Burn, formData, tokenId),
    {
      onSuccess: (data) => {
        toast.success('Burn token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = (data: BurnNFTTokenFormData) => {
    mutate(data)
  }

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<BurnNFTTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form data-testid="burn-nft-token-form" onSubmit={handleSubmit(onSubmit)}>
      {/* Later on if we move to collections we need add a NFT id selector here
          The ids for the selector should come from: assets */}

      <ButtonGroup fullWidth>
        <Button
          data-testid="burn-nft-token-form__submit-button"
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Burn
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default BurnNFTTokenForm
