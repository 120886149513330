import { ChainProtocol, Token } from '@archax/shared-types'
import { isEVMProtocol } from './token-options'

export const getTokenIdOnChain = (token: Token): string => {
  if (!token) {
    return ''
  }

  const {
    standard: {
      chain: { protocol },
    },
  } = token

  if (isEVMProtocol(token.standard.chain.protocol)) {
    return token.deploymentResult.contract.address
  }

  if (protocol === ChainProtocol.Algorand) {
    return token.deploymentResult.assetId
  }

  if (protocol === ChainProtocol.Hedera) {
    return token.deploymentResult.tokenId
  }

  return ''
}
