import { ReactElement } from 'react'
import { Typography, styled } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2rem',
}))

type StepFailedProps = {
  content: ReactElement
}
export default function StepFailed({ content }: StepFailedProps) {
  return (
    <Wrapper>
      <ErrorIcon sx={{ fontSize: 100 }} color="error" />
      <Typography align="center" variant="body2" maxWidth={'500px'}>
        {content}
      </Typography>
    </Wrapper>
  )
}
