import { GranteeType, OperationType, Token } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, ButtonGroup, FormHelperText } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import { getGrantablePoolTokens } from '../../../../api/tokens'
import { getAllTradersByName, getTraderAddressesForToken } from '../../../../api/traders'
import { Select } from '../../../../components/ui/Select'
import onApiError from '../../../../util/on-api-error'
import { useTranslation } from 'react-i18next'
import { extractTokenHolderAddress } from '../../../../util/extract-token-address'

interface GrantKYCTokenProps {
  token: Token
  granteeType: GranteeType
  granteeId?: string
  traderAddressId?: string
  onSuccess: () => void
}

export interface GrantKYCTokenFormDataWithTrader {
  granteeType: GranteeType
  granteeId: string
  address?: string
}

const validationSchema = Yup.object()
  .shape({
    granteeId: Yup.string().required('Grantee is required'),
    address: Yup.string().required('Grantee address is required'),
  })
  .required()

function GrantKYCTokenForm({ token, granteeType, granteeId, traderAddressId, onSuccess }: GrantKYCTokenProps) {
  const { t } = useTranslation()
  const initialValues = {
    granteeType: granteeType,
    granteeId: granteeId || '',
    address: traderAddressId || '',
  }
  const { mutate } = useMutation(
    (formData: GrantKYCTokenFormDataWithTrader) =>
      formData.granteeType === GranteeType.Trader
        ? createOperation(OperationType.GrantKYC, { traderAddressId: formData.address }, token.id, formData.granteeId)
        : createOperation(OperationType.GrantKYC, { poolTokenId: formData.granteeId }, token.id),
    {
      onSuccess: (data) => {
        toast.success('Grant KYC request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )
  const { isLoading: isLoadingTraders, data: tradersData } = useQuery(['get-traders'], () => getAllTradersByName(), {
    retry: false,
  })

  const { isLoading: isLoadingPoolTokens, data: poolTokensData } = useQuery(
    ['get-grantable-pool-tokens'],
    () => getGrantablePoolTokens(token.id),
    {
      retry: false,
    },
  )

  const onSubmit = (data: GrantKYCTokenFormDataWithTrader) => {
    mutate({ granteeType: data.granteeType, granteeId: data.granteeId, address: data.address })
  }

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<GrantKYCTokenFormDataWithTrader>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const traderAddressesQuery = useQuery(
    [`get-trader-addressess-for-token`, watch('granteeId')],
    () => getTraderAddressesForToken(getValues('granteeId'), token.id),
    {
      retry: false,
      enabled: getValues('granteeType') === GranteeType.Trader && !!getValues('granteeId'),
    },
  )

  const [addressOptions, setAddressOptions] = useState<Array<any>>([])

  useEffect(() => {
    watch((value, { name }) => {
      if (name === 'granteeId') {
        if (getValues('granteeType') === GranteeType.Trader) {
          setValue('address', '')
        } else {
          const selectedPoolToken = poolTokensData?.data.data.find(
            (poolToken: any) => poolToken.id === getValues('granteeId'),
          )
          if (selectedPoolToken) {
            const address = extractTokenHolderAddress(selectedPoolToken)
            setAddressOptions([{ label: address, value: address }])
            setValue('address', address)
            trigger('address')
          }
        }
      }
      if (name === 'granteeType') {
        setValue('granteeId', '')
        setValue('address', '')
      }
    })
  }, [watch, setValue, getValues, poolTokensData?.data.data])

  useEffect(() => {
    let traderAddressOptions = []
    if (traderAddressesQuery.data) {
      traderAddressOptions = (traderAddressesQuery.data as any).data.addresses.map((traderAddress: any) => ({
        label: `${traderAddress.name}: ${traderAddress.address}`,
        value: traderAddress.id,
      }))
    }
    setAddressOptions(traderAddressOptions)
  }, [traderAddressesQuery.data])

  const granteeOptions = useMemo(() => {
    if (getValues('granteeType') === GranteeType.Trader && !isLoadingTraders) {
      const traders = (tradersData as any).data.data as any[]
      const traderOptions = traders
        .filter((trader: any) => {
          return !trader.deletedAt
        })
        .map((trader: any) => ({
          label: trader.name,
          value: trader.id,
        }))
      return traderOptions
    } else if (getValues('granteeType') === GranteeType.PoolToken && !isLoadingPoolTokens) {
      const poolTokenOptions = (poolTokensData as any).data.data.map((poolToken: any) => ({
        label: poolToken.name,
        value: poolToken.id,
      }))
      return poolTokenOptions
    }
    return []
  }, [tradersData, getValues, isLoadingPoolTokens, isLoadingTraders, poolTokensData])

  useEffect(() => {
    if (getValues('granteeType') === GranteeType.Trader && addressOptions.length === 1) {
      setValue('address', addressOptions[0].value)
      trigger('address')
    }
  }, [addressOptions, setValue, trigger, getValues])

  const isSubmitButtonDisabled = !isValid || isSubmitting || isSubmitSuccessful

  const isSelectAddressDisabled =
    getValues('granteeType') === GranteeType.PoolToken ||
    !getValues('granteeId') ||
    traderAddressesQuery.isLoading ||
    addressOptions.length <= 1

  return (
    <form data-testid="grant-kyc-form" onSubmit={handleSubmit(onSubmit)}>
      <Select
        name="granteeId"
        control={control}
        label={getValues('granteeType') === GranteeType.Trader ? t('holder.forms.selectHolder') : 'Select a pool token'}
        options={granteeOptions}
      />
      {getValues('granteeType') === GranteeType.PoolToken && !isLoadingPoolTokens && granteeOptions.length === 0 && (
        <FormHelperText sx={{ color: 'warning.dark' }}>
          There are no pool tokens that can be whitelisted.
        </FormHelperText>
      )}
      <Select
        sx={{ mb: 1 }}
        name="address"
        control={control}
        label={getValues('granteeType') === GranteeType.Trader ? 'Select the address' : 'Address'}
        disabled={isSelectAddressDisabled}
        options={addressOptions}
      />
      {getValues('granteeType') === GranteeType.Trader &&
        !traderAddressesQuery.isLoading &&
        getValues('granteeId') &&
        addressOptions.length === 0 && (
          <FormHelperText sx={{ color: 'warning.dark' }}>
            {t('holder.errors.missingAddressHolderToWhitelist')}
          </FormHelperText>
        )}
      <Box m={4} />
      <ButtonGroup fullWidth>
        <Button
          data-testid="grant-kyc-form__submit-button"
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Grant KYC
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default GrantKYCTokenForm
