import { ReactElement } from 'react'
import { Button, CircularProgress, Typography, styled } from '@mui/material'
import { Dialog, DialogContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import PeraWalletIconURL from '../../../../assets/perawallet.icon.svg'
import WalletConnectIconURL from '../../../../assets/walletconnect.icon.svg'

export const StyledButton = styled(Button)(() => ({
  display: 'flex',
  gap: '5px',

  maxWidth: '200px',
  maxHeight: '50px',
  background: 'none',
  border: 'solid 1px white',
  color: 'white',
}))

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  minHeight: '300px',
  h5: {
    color: theme.palette.grey[700],
  },
  svg: {
    color: theme.palette.grey[700],
  },
}))
export function LoadingSpinner(): ReactElement {
  return (
    <Wrapper>
      <CircularProgress />
      <Typography variant="h5">Loading token data...</Typography>
    </Wrapper>
  )
}

export const OptInButton = styled(Button)(() => ({
  display: 'flex',
  gap: '5px',

  maxWidth: '150px',
  height: '40px',
  color: 'white',
  paddingLeft: '20px',
  svg: {
    color: 'white',
  },
}))
export const OptedInButton = styled(Button)(() => ({
  display: 'flex',
  gap: '5px',

  width: '120px',
  height: '40px',
  color: 'white',
}))

const StyledImg = styled('img')(() => ({
  width: '48px',
  height: '48px',
}))
function PeraWalletIcon(): ReactElement {
  return <StyledImg src={PeraWalletIconURL} />
}
function WalletConnectIcon(): ReactElement {
  return <StyledImg src={WalletConnectIconURL} />
}

const StyledCloseIcon = styled(CloseIcon)(() => ({
  position: 'absolute',
  top: '0.6rem',
  right: '0.6rem',
}))
const StyledDialogContent = styled(DialogContent)(() => ({
  marginTop: '0.6rem',
}))

type WalletConnectDialogProps = {
  open: boolean
  onClose: () => void
  peraWallet: () => void
  walletConnect: () => void
}
export function WalletConnectDialog({ open, onClose, peraWallet, walletConnect }: WalletConnectDialogProps) {
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <StyledCloseIcon onClick={onClose} />
      <StyledDialogContent dividers>
        <List>
          <ListItem onClick={peraWallet}>
            <ListItemButton>
              <ListItemIcon>
                <PeraWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Pera Wallet" />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={walletConnect}>
            <ListItemButton>
              <ListItemIcon>
                <WalletConnectIcon />
              </ListItemIcon>
              <ListItemText primary="Wallet Connect" />
            </ListItemButton>
          </ListItem>
        </List>
      </StyledDialogContent>
    </Dialog>
  )
}
