import React from 'react'
import { Controller } from 'react-hook-form'
import { styled, Grid, Typography } from '@mui/material'
import MuiSwitch, { SwitchProps } from '@mui/material/Switch'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '0.5rem',
}))

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #0070b8',
  },
}))

interface ISwitch extends SwitchProps {
  name: string
  control: any
  label: string
  tooltip?: string
}

function Switch({ name, control, label, tooltip, ...others }: ISwitch) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => (
        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <StyledTypography variant="body1">
            {label}
            {tooltip && (
              <HtmlTooltip title={<React.Fragment>{tooltip}</React.Fragment>} placement="right" arrow>
                <HelpOutlineIcon />
              </HtmlTooltip>
            )}
          </StyledTypography>
          <MuiSwitch color="primary" onChange={onChange} value={value} checked={value} {...others} />
        </Grid>
      )}
    ></Controller>
  )
}

export default Switch
