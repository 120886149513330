import { Trader } from '@archax/shared-types'
import { Box, Card, Typography } from '@mui/material'
import { TraderStatus } from './components/TraderStatus'
import { TraderStatusChangeButton } from './components/TraderStatusChangeButton'

interface TraderDetailsProps {
  trader: Trader
  refetch: () => void
}

function TraderDetails({ trader, refetch }: TraderDetailsProps) {
  if (!trader) {
    return <></>
  }

  return (
    <>
      <Card sx={{ p: 7 }}>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography align="left" variant="h3">
            {trader.name}
          </Typography>
          <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
            Status: <TraderStatus trader={trader} />
            <div style={{ width: '24px' }} />
            <TraderStatusChangeButton trader={trader} size="medium" refetch={refetch} />
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default TraderDetails
