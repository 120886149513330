import {
  OperationType,
  RevokeTraderKYCTokenFormData,
  TABLE_HEADER_NAMES,
  TokenWithTraderAddress,
} from '@archax/shared-types'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { createOperation } from '../../../api/operations'
import { GetTraderDetailsParams, getTraderWhitelist, getTraderWhitelistCSVUrl } from '../../../api/traders'
import Dialog from '../../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { useGlobalContext } from '../../../context'
import onApiError from '../../../util/on-api-error'
import { hasUserRole } from '../../../util/user-roles'

interface TraderWhitelistProps {
  id: string
}

interface RevokeKYCOperationData {
  formData: RevokeTraderKYCTokenFormData
  tokenId: string
}

function TraderWhitelist({ id }: TraderWhitelistProps) {
  const [showRevokeKYCTokenDialog, setShowRevokeKYCTokenDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TokenWithTraderAddress | null>(null)
  const {
    state: { user },
  } = useGlobalContext()

  const { mutate: revokeKYCMutation } = useMutation(
    (data: RevokeKYCOperationData) => createOperation(OperationType.RevokeKYC, data.formData, data.tokenId, id),
    {
      onSuccess: () => {
        toast.success('Revoke KYC request sent for approval')
      },
      onError: onApiError,
    },
  )

  const handleRevokeKYC = async (row: TokenWithTraderAddress) => {
    revokeKYCMutation({ formData: { traderAddressId: row.traderAddressId }, tokenId: row.tokenId })
    setShowRevokeKYCTokenDialog(false)
  }

  const columnDefs: ColDef<TokenWithTraderAddress>[] = useMemo(
    () => [
      {
        field: 'tokenName',
        headerName: TABLE_HEADER_NAMES.common.token_name,
        flex: 1,
        minWidth: 160,
        sortable: false,
      },
      {
        field: 'protocol',
        headerName: TABLE_HEADER_NAMES.common.protocol,
        flex: 1,
        minWidth: 140,
        sortable: false,
      },
      {
        field: 'traderAddressName',
        headerName: TABLE_HEADER_NAMES.common.address_name,
        flex: 1.5,
        minWidth: 160,
        sortable: false,
      },
      {
        field: 'traderAddress',
        headerName: TABLE_HEADER_NAMES.common.address,
        flex: 3,
        minWidth: 200,
        sortable: false,
      },
    ],
    [],
  )
  if (hasUserRole(user)) {
    columnDefs.push({
      field: 'actions',
      flex: 1,
      minWidth: 150,
      cellRenderer: (params: ICellRendererParams<TokenWithTraderAddress>) => {
        return (
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Box marginRight={1}>
                <Button
                  sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSelectedRow(params.data!)
                    setShowRevokeKYCTokenDialog(true)
                  }}
                >
                  Revoke KYC
                </Button>
              </Box>
            </Box>
          </Grid>
        )
      },
    })
  }

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      defaultColDef: { filter: false },
    }),
    [columnDefs],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          Tradable tokens
        </Typography>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => {
            return getTraderWhitelist({ ...(gridParams as GetTraderDetailsParams), id })
          }}
          csvExportUrlGetter={(gridParams) => {
            return getTraderWhitelistCSVUrl({
              ...(gridParams as GetTraderDetailsParams),
              id,
            })
          }}
        />
      </Box>
      <Dialog
        title="Are you sure that you want to revoke account access to the token?"
        onConfirm={() => handleRevokeKYC(selectedRow!)}
        confirmLabel="Revoke KYC"
        onClose={() => setShowRevokeKYCTokenDialog(false)}
        open={showRevokeKYCTokenDialog}
        showCancel
      >
        <Typography variant="body2">You can grant the KYC back later.</Typography>
      </Dialog>
    </div>
  )
}

export default TraderWhitelist
