import { OperationType, READABLE_BLACKLIST_STATUS, TABLE_HEADER_NAMES, Token } from '@archax/shared-types'
import { Box, Button, TextareaAutosize, Typography, Zoom, styled } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { hasUserRole } from '../../../util/user-roles'
import { useGlobalContext } from '../../../context'
import { AddButton } from '../../../components/ui/AddButton'
import Dialog from '../../../components/Dialog/Dialog'
import { useMemo, useState } from 'react'
import { ColDef, GridOptions, ICellRendererParams, IFilterOptionDef, ValueFormatterParams } from 'ag-grid-community'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { AddBlacklistedAddressesForm } from './AddBlacklistedAddressesForm'
import { GetTokenBlacklistParams, getTokenBlacklist, getTokenBlacklistCSVUrl } from '../../../api/tokens'
import { formatDate } from '../../../util/formatters'
import { Refresh, Delete } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { createOperation } from '../../../api/operations'
import { toast } from 'react-toastify'
import onApiError from '../../../util/on-api-error'
import { substringFilterParams } from '../../../util/common-grid-options'

interface TokenBlacklistedAddressesProps {
  token: Token
}

type TokenBlacklistData = {
  id: string
  tokenId: string
  address: string
  isBlacklisted: boolean
  createdAt: string
  updatedAt: string
}

const BlacklistActionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="right" TransitionComponent={Zoom} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#0070B8',
    // boxShadow: theme.shadows[1],
    fontSize: 10,
    border: '1px solid #0070B8',
    fontWeight: '400',
    wordWrap: 'break-word',
    lineHeight: '14px',
    maxWidth: '95px',
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#0070B8',
  },
}))

function TokenBlacklistedAddresses({ token }: TokenBlacklistedAddressesProps) {
  const [showAddBlacklistedWalletDialog, setShowAddBlacklistedWalletDialog] = useState(false)
  const [showBlacklistStatusChangeDialog, setShowBlacklistStatusChangeDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TokenBlacklistData>()
  const {
    state: { user },
  } = useGlobalContext()

  const { mutate: mutateRemoveFromBlacklist } = useMutation(
    (formData: { address: string }) => {
      return createOperation(OperationType.RemoveFromBlacklist, formData, token.id)
    },
    {
      onSuccess: () => {
        toast.success('Remove address from blacklist request sent for approval')
      },
      onError: onApiError,
    },
  )
  const { mutate: mutateAddToBlacklist } = useMutation(
    (formData: { addresses: string[] }) => createOperation(OperationType.AddToBlacklist, formData, token.id),
    {
      onSuccess: () => {
        toast.success('Add addresses to the blacklist request sent for approval')
      },
      onError: onApiError,
    },
  )

  const columnDefs: ColDef<TokenBlacklistData>[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: TABLE_HEADER_NAMES.token_blacklist.is_blacklisted,
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? READABLE_BLACKLIST_STATUS.ENABLED : READABLE_BLACKLIST_STATUS.DISABLED
          },
          maxNumConditions: 1,
        },
        valueGetter: (params) =>
          params.data!.isBlacklisted ? READABLE_BLACKLIST_STATUS.ENABLED : READABLE_BLACKLIST_STATUS.DISABLED,
      },
      {
        field: 'address',
        headerName: TABLE_HEADER_NAMES.common.address,
        flex: 3,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
        sortable: true,
      },
      {
        field: 'createdAt',
        headerName: TABLE_HEADER_NAMES.common.created_at,
        flex: 1,
        valueGetter: (params) => {
          return formatDate(params.data!.createdAt)
        },
        filter: false,
      },
      {
        field: 'isBlacklistedAction',
        headerName: '',
        filter: false,
        maxWidth: 112,
        cellRenderer: (params: ICellRendererParams<TokenBlacklistData>) => {
          const tooltipText = params.data!.isBlacklisted ? 'Remove from blacklist' : 'Add to blacklist'

          return (
            <BlacklistActionTooltip title={tooltipText}>
              <Button
                variant="text"
                sx={{
                  color: '#0070B8',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                size="small"
                onClick={() => {
                  setSelectedRow(params.data!)
                  setShowBlacklistStatusChangeDialog(true)
                }}
              >
                {params.data!.isBlacklisted ? <Delete /> : <Refresh />}
              </Button>
            </BlacklistActionTooltip>
          )
        },
      },
    ],
    [],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
    }),
    [columnDefs],
  )

  return (
    <div>
      <Box>
        <Box
          sx={{ paddingLeft: 0 }}
          maxWidth="xl"
          flexDirection={'row'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography align="left" variant="h5">
            Blacklisted addresses
          </Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {hasUserRole(user) && (
              <AddButton
                name="whitelist-addition"
                ariaLabel="Add Blacklisted Wallet"
                onClick={() => {
                  setShowAddBlacklistedWalletDialog(true)
                }}
              ></AddButton>
            )}
          </Box>
        </Box>
        <Box>
          <ServerSideGrid
            gridOptions={gridOptions}
            queryFn={(gridParams) => {
              return getTokenBlacklist({ ...(gridParams as GetTokenBlacklistParams), id: token.id })
            }}
            csvExportUrlGetter={(gridParams) => {
              return getTokenBlacklistCSVUrl({ ...(gridParams as GetTokenBlacklistParams), id: token.id })
            }}
          />
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        title={`Add addresses`}
        onClose={() => setShowAddBlacklistedWalletDialog(false)}
        open={showAddBlacklistedWalletDialog}
      >
        <AddBlacklistedAddressesForm
          onClose={() => setShowAddBlacklistedWalletDialog(false)}
          tokenId={token.id}
          tokenProtocol={token.standard.chain.protocol}
          onSuccess={() => {
            setShowAddBlacklistedWalletDialog(false)
          }}
        />
      </Dialog>
      {showBlacklistStatusChangeDialog && selectedRow && (
        <Dialog
          fullWidth
          maxWidth="sm"
          title={
            selectedRow!.isBlacklisted
              ? `Are you sure that you want to remove the address from the blacklist`
              : `Are you sure that you want to add the address to the blacklist`
          }
          onClose={() => setShowBlacklistStatusChangeDialog(false)}
          open={showBlacklistStatusChangeDialog}
          showCancel={true}
          confirmLabel="Confirm"
          onConfirm={() => {
            if (!selectedRow) return
            if (selectedRow!.isBlacklisted) {
              mutateRemoveFromBlacklist({ address: selectedRow!.address })
            }
            if (!selectedRow!.isBlacklisted) {
              mutateAddToBlacklist({ addresses: [selectedRow!.address] })
            }

            setShowBlacklistStatusChangeDialog(false)
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: 0, fontWeight: '400' }} align="center">
            You can {!selectedRow!.isBlacklisted ? 'remove the address from' : 'add the address to'} the blacklist
            later:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: '700' }} align="center">
            {selectedRow!.address}
          </Typography>
        </Dialog>
      )}
    </div>
  )
}

export default TokenBlacklistedAddresses
