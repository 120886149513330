"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXTRERNAL_TOKENS_PROTOCOLS = exports.EVM_CHAIN_PROTOCOLS = exports.ChainProtocol = void 0;
var ChainProtocol;
(function (ChainProtocol) {
    ChainProtocol["Algorand"] = "ALGORAND";
    ChainProtocol["Ethereum"] = "ETHEREUM";
    ChainProtocol["Hedera"] = "HEDERA";
    ChainProtocol["Polygon"] = "POLYGON";
    ChainProtocol["XRP_Ledger"] = "XRP_LEDGER";
})(ChainProtocol || (exports.ChainProtocol = ChainProtocol = {}));
exports.EVM_CHAIN_PROTOCOLS = [ChainProtocol.Ethereum, ChainProtocol.Polygon];
exports.EXTRERNAL_TOKENS_PROTOCOLS = [ChainProtocol.Ethereum, ChainProtocol.Polygon, ChainProtocol.Hedera];
