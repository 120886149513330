import { Button, Card, Container, Grid, Link, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

function AlgorandOptIn() {
  const { id } = useParams()

  return (
    <Container maxWidth="md">
      <Card sx={{ p: 7, marginTop: 20 }}>
        <Grid container>
          <Grid item sm={3} xs={12}>
            <img
              alt="Algorand logo"
              width="100%"
              src="https://www.algorand.com/assets/media-kit/logos/full/png/algorand_full_logo_black.png"
            />
          </Grid>
          <Grid item sm={9} xs={12}>
            <Typography align="center" variant="h1">
              Opt-in to an asset
            </Typography>
            <Typography sx={{ marginBottom: 5 }} align="center" variant="h2">
              Asset ID: {id}
            </Typography>
          </Grid>
        </Grid>

        <Typography sx={{ marginBottom: 5 }}>
          Before a new asset can be transferred to a specific account the receiver must opt-in to receive the asset.
          <br />
          Below you can find instructions for some Algorand wallets.
        </Typography>
        <Grid container>
          <Grid item sm={2} xs={4}>
            <img
              alt="Pera wallet logo"
              width="60"
              height="60"
              src="https://perawallet.s3.amazonaws.com/images/logo.svg"
            />
          </Grid>
          <Grid item sm={10} xs={8}>
            <Grid item xs={12}>
              Pera wallet
            </Grid>
            <Grid item xs={12}>
              <Link href="https://perawallet.app/">
                <Typography variant="caption">perawallet.app</Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>You can opt-in manually on the app or you can follow this link for your QR Code.</Typography>
            <Button
              variant="contained"
              target="_blank"
              component="a"
              href={`https://perawallet.app/qr-code-generator/result?reason=Opt-in+to+asset&assetID=${id}`}
            >
              Your QR Code
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  )
}
export default AlgorandOptIn
