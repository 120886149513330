import { ReactElement } from 'react'
import { Breadcrumbs as MuiBreadcrumbs, Typography, styled } from '@mui/material'

const StyledBreadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  margin: theme.spacing(2.5, 0),
  ol: {
    li: {
      color: theme.palette.grey[400],
      p: {
        color: theme.palette.grey[400],
      },
      '&:last-of-type': {
        p: {
          color: theme.palette.grey[50],
        },
      },
    },
  },
}))

export default function Breadcrumbs({ items }: { items: string[] }): ReactElement {
  return (
    <StyledBreadcrumbs aria-label="breadcrumb">
      {items.map((it, ix) => (
        <Typography key={`breadcrumb-${ix}`}>{it}</Typography>
      ))}
    </StyledBreadcrumbs>
  )
}
