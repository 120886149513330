import { BurnTokenFormData, OperationType, Token } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import TextField from '../../../../components/ui/TextField/TextField'
import onApiError from '../../../../util/on-api-error'
import { tokenAmountValidator } from '../../../../util/yup-validators'

interface BurnTokenProps {
  token: Token
  onSuccess: () => void
}

const initialValues = { amount: '0' }

function BurnTokenForm({ token, onSuccess }: BurnTokenProps) {
  const { id, decimals, onChainData } = token
  const validationSchema = Yup.object()
    .shape({
      amount: tokenAmountValidator({ decimals, positive: true, max: onChainData?.balance }),
    })
    .required()

  const { mutate } = useMutation((formData: BurnTokenFormData) => createOperation(OperationType.Burn, formData, id), {
    onSuccess: (data) => {
      toast.success('Burn token request sent for approval')
      onSuccess()
    },
    onError: onApiError,
  })

  const onSubmit = (data: BurnTokenFormData) => {
    mutate(data)
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<BurnTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form data-testid="burn-token-form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        data-testid="burn-token-form__amount-input"
        name="amount"
        type="number"
        control={control}
        label="Number to burn"
      />

      <ButtonGroup fullWidth>
        <Button
          data-testid="burn-token-form__submit-button"
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Burn
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default BurnTokenForm
