import { isValidAddress } from '@archax/address-validator'
import { ChainProtocol, OperationType } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, DialogActions, FormHelperText, TextareaAutosize, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import onApiError from '../../../../util/on-api-error'

interface AddBlacklistedAddressesDialogProps {
  onClose: () => void
  onSuccess: () => void
  tokenId: string
  tokenProtocol: ChainProtocol
}

const initialValues = { addresses: '' }

const extractAddresses = (addresses: string): string[] => addresses.split('\n').filter((address) => address !== '')

function AddBlacklistedAddressesDialog({
  onClose,
  tokenId,
  onSuccess,
  tokenProtocol,
}: AddBlacklistedAddressesDialogProps) {
  const validationSchema = Yup.object()
    .shape({
      addresses: Yup.string()
        .test('is-valid-address', 'Invalid address', (value: string | undefined, ctx: Yup.TestContext): boolean => {
          const protocol = tokenProtocol
          if (!value || !protocol) {
            return false
          }
          const addresses = extractAddresses(value)
          return addresses.every((address) => isValidAddress(address, protocol))
        })
        .required(),
    })
    .required()

  const { mutate } = useMutation(
    (formData: { addresses: string[] }) => createOperation(OperationType.AddToBlacklist, formData, tokenId),
    {
      onSuccess: () => {
        toast.success('Add addresses to blacklist request sent for approval')
        reset()
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful, errors },
    reset,
  } = useForm<{ addresses: string }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const addresses = extractAddresses(data.addresses)
        mutate({ addresses })
      })}
    >
      <Box>
        <Typography variant="inputLabel">Enter one or more wallet addresses per each line</Typography>
        <Controller
          name="addresses"
          control={control}
          render={({ fieldState: { error }, field: { onChange, value } }) => (
            <div style={{ margin: '32px 0px' }}>
              <TextareaAutosize
                minRows={7}
                style={{ lineHeight: '21px', width: '100%' }}
                onChange={onChange}
                value={value}
              />
              {tokenProtocol === ChainProtocol.Hedera && (
                <FormHelperText>
                  Before add to blacklist, be sure that the addresses have already associated to the current token.
                </FormHelperText>
              )}
            </div>
          )}
        />
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button size="large" fullWidth onClick={onClose} color="cancel" variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isSubmitButtonDisabled}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            Submit
          </Button>
        </DialogActions>
      </Box>
    </form>
  )
}

export default AddBlacklistedAddressesDialog
