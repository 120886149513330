import {
  ChainProtocol,
  OperationType,
  READABLE_PROTOCOL,
  RemoveTraderAddressOperationData,
  TABLE_HEADER_NAMES,
  Trader,
  TraderAddress,
} from '@archax/shared-types'
import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { createOperation } from '../../../api/operations'
import { getTraderAddresses, getTraderAddressesCSVUrl } from '../../../api/traders'
import Dialog from '../../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { AddButton } from '../../../components/ui/AddButton'
import { useGlobalContext } from '../../../context'
import onApiError from '../../../util/on-api-error'
import { hasUserRole } from '../../../util/user-roles'
import { AddAddressForm } from './AddAddressForm.tsx'

interface TraderProps {
  trader: Trader
}

function TraderAddresses({ trader }: TraderProps) {
  const { id } = trader
  const [selectedRow, setSelectedRow] = useState<TraderAddress | null>(null)
  const {
    state: { user },
  } = useGlobalContext()

  const { mutate: removeAddressMutation } = useMutation(
    (data: RemoveTraderAddressOperationData) => createOperation(OperationType.RemoveTraderAddress, data, undefined, id),
    {
      onSuccess: () => {
        toast.success('Remove address request sent for approval')
      },
      onError: onApiError,
    },
  )

  const handleRemoveAddress = async (row: TraderAddress) => {
    removeAddressMutation({ traderAddressId: row.id })
    setShowRemoveAddressDialog(false)
  }

  const columnDefs: ColDef<TraderAddress>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: TABLE_HEADER_NAMES.trader_addresses.account_name,
        flex: 1,
        minWidth: 200,
        sortable: false,
      },
      {
        field: 'address',
        headerName: TABLE_HEADER_NAMES.common.address,
        flex: 3,
        sortable: false,
      },
      {
        field: 'chain.protocol',
        headerName: TABLE_HEADER_NAMES.common.protocol,
        valueGetter: (params: ValueGetterParams<TraderAddress>) => {
          return READABLE_PROTOCOL[params.data!.chain.protocol as ChainProtocol]
        },
        flex: 1,
        sortable: false,
      },
    ],
    [],
  )

  if (hasUserRole(user) && !trader.deletedAt) {
    columnDefs.push({
      field: 'actions',
      flex: 1,
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: (params: ICellRendererParams<TraderAddress>) => {
        return (
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Box marginRight={1}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSelectedRow(params.data!)
                    setShowRemoveAddressDialog(true)
                  }}
                >
                  Remove
                </Button>
              </Box>
            </Box>
          </Grid>
        )
      },
    })
  }
  const [showAddAddressDialog, setShowAddAddressDialog] = useState(false)
  const [showRemoveAddressDialog, setShowRemoveAddressDialog] = useState(false)

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      defaultColDef: { filter: false },
    }),
    [columnDefs],
  )

  if (!id) {
    return <></>
  }

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          Addresses
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {hasUserRole(user) && !trader.deletedAt && (
            <AddButton
              name="address-additon"
              ariaLabel="Add address"
              onClick={() => setShowAddAddressDialog(true)}
            ></AddButton>
          )}
        </Box>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => getTraderAddresses({ ...gridParams, id })}
          csvExportUrlGetter={(gridParams) => getTraderAddressesCSVUrl({ ...gridParams, id })}
        />
      </Box>
      <Dialog title="Add address" onClose={() => setShowAddAddressDialog(false)} open={showAddAddressDialog}>
        <AddAddressForm onSuccess={() => setShowAddAddressDialog(false)} traderId={id} />
      </Dialog>
      <Dialog
        title="Are you sure you want to remove the address?"
        onConfirm={() => handleRemoveAddress(selectedRow!)}
        confirmLabel="Remove"
        onClose={() => setShowRemoveAddressDialog(false)}
        open={showRemoveAddressDialog}
        showCancel
      >
        {' '}
      </Dialog>
    </div>
  )
}

export default TraderAddresses
