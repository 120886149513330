import { ChainProtocol, ExplorerLink, TokenHistoryOperation, TraderHistoryOperation } from '@archax/shared-types'
import { Link } from '@mui/material'
import { EXPLORER_LOGOS } from '../../../constants'

export interface ExplorerLinksProps {
  operation: TokenHistoryOperation | TraderHistoryOperation
}

const ExplorerLinks: React.FunctionComponent<ExplorerLinksProps> = ({ operation: { explorerLinks, token } }) => {
  return (
    <>
      {explorerLinks?.map((explorerLink: ExplorerLink, index: number) => (
        <Link
          key={index}
          href={explorerLink.url}
          rel="noreferrer"
          target="_blank"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0',
          }}
        >
          <img
            alt={explorerLink.value}
            src={EXPLORER_LOGOS[token!.standard.chain.protocol as ChainProtocol]}
            width="18"
          />
        </Link>
      ))}
    </>
  )
}

export default ExplorerLinks
