import { Operation, OperationStatus as OperationStatusEnum } from '@archax/shared-types'
import BlockIcon from '@mui/icons-material/Block'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Tooltip } from '@mui/material'

export interface OperationStatusProps {
  operation: Operation
}

const OperationStatus: React.FunctionComponent<OperationStatusProps> = ({ operation: { status, error } }) => {
  if (status === OperationStatusEnum.NotApproved) {
    return (
      <Tooltip title="Not approved">
        <BlockIcon color="error" />
      </Tooltip>
    )
  }
  if (status === OperationStatusEnum.Approved) {
    if (error) {
      return (
        <Tooltip title="Failed">
          <CancelIcon color="error" />
        </Tooltip>
      )
    }

    return (
      <Tooltip title="Approved">
        <CheckBoxIcon color="success" />
      </Tooltip>
    )
  }

  return <></>
}

export default OperationStatus
