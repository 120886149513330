import { Typography, styled } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2rem',
}))

export default function StepSuccess() {
  return (
    <Wrapper>
      <CheckCircleIcon sx={{ fontSize: 100 }} color="success" />
      <Typography align="center" variant="body2">
        Thank you for opting in to the ASA and application.
        <br />
        You are eligible now to receive the token.
      </Typography>
    </Wrapper>
  )
}
