import { toast } from 'react-toastify'
import { ChainProtocol, OperationType, Token } from '@archax/shared-types'
import DefaultForm from './DefaultForm'
import HederaForm from './HederaForm'
import { FormMode } from '../../../../api/types'
import { useMutation } from '@tanstack/react-query'
import { createOperation } from '../../../../api/operations'
import onApiError from '../../../../util/on-api-error'

type ChangeOwnerFormProps = {
  token: Token
  onCancel: () => void
  onSuccess: () => void
}
export function ChangeOwnerForm({ token, onCancel, onSuccess }: ChangeOwnerFormProps) {
  const {
    standard: {
      chain: { protocol },
    },
  } = token

  const { mutateAsync, isLoading } = useMutation(
    (operationData: Record<string, unknown>) => {
      return createOperation(OperationType.ChangeOwner, operationData, token.id)
    },
    {
      onSuccess: () => {
        toast.success('Change Owner request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = async (operationData: Record<string, unknown>) => {
    if (!isLoading) {
      return mutateAsync(operationData)
    }
  }

  switch (protocol) {
    case ChainProtocol.Hedera:
      return <HederaForm mode={FormMode.Create} token={token} onCancel={onCancel} onSubmit={onSubmit} />
    default:
      return <DefaultForm mode={FormMode.Create} token={token} onCancel={onCancel} onSubmit={onSubmit} />
  }
}
