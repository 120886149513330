import { ChainProtocol, Token } from '@archax/shared-types'
import { FormHelperText } from '@mui/material'

interface HederaAssociateHelperProps {
  token: Token
}

function HederaAssociateHelper({ token }: HederaAssociateHelperProps) {
  const isHedera = token.standard.chain.protocol === ChainProtocol.Hedera
  const isKycEnabled = token.kycEnabled

  if (!isHedera || isKycEnabled) {
    return null
  }

  return (
    <FormHelperText>
      Before send/mint, be sure that the holder have already associated to the current token.
    </FormHelperText>
  )
}

export default HederaAssociateHelper
