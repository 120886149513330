"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DistributionAsset = void 0;
var DistributionAsset;
(function (DistributionAsset) {
    /**
     * Any token can distribute USDC
     */
    DistributionAsset["USDC"] = "USDC";
    /**
     * NFTs can not distribute the token itself
     */
    DistributionAsset["Token"] = "TOKEN";
})(DistributionAsset || (exports.DistributionAsset = DistributionAsset = {}));
