import { TokenFormData } from '@archax/shared-types'
import { AttachFile, DeleteForever } from '@mui/icons-material'
import { Box, Button, Grid, Input, Typography } from '@mui/material'
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { TextField } from '../../../../components/ui/TextField'
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { get } from 'http'
import { toast } from 'react-toastify'
import { FormMode } from '../../../../api/types'

function calcDocumentSize(size: number) {
  if (size < 1000) {
    return `${size} B`
  }
  if (size < 1000000) {
    return `${(size / 1000).toFixed(2)} KB`
  }
  return `${(size / 1000000).toFixed(2)} MB`
}

function borderRadiusRowRule(row: number, totalRows: number) {
  if (totalRows === 1) {
    return '4px'
  }
  if (row === 0) {
    return '4px 4px 0 0'
  }
  if (row === totalRows - 1) {
    return '0 0 4px 4px'
  }
}

interface IDragDropFiles {
  getValues: UseFormGetValues<TokenFormData>
  setvalue: UseFormSetValue<TokenFormData>
  mode: FormMode
  oldDocuments?: string[]
}

function DragDropFiles({ getValues, setvalue, mode, oldDocuments }: IDragDropFiles) {
  const [dragActive, setDragActive] = useState(false)
  const [dynamicDocuments, setDynamicDocuments] = useState(Array.from(oldDocuments || []))
  const inputRef = useRef(null)

  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
    } else if (e.type === 'dragleave') {
    }
  }

  function handleFiles(files: any) {
    let filesArray = Array.from(files)
    if (getValues('document')) {
      filesArray = [...getValues('document'), ...filesArray]
    }

    const notPdf = filesArray.find((file: any) => file.type === 'application/pdf')
    if (notPdf === undefined) {
      toast.error('Only PDF files are supported')
      return
    }
    setvalue('document', filesArray, {
      shouldValidate: true,
    })
  }

  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files)
    }
  }

  const onButtonClick = () => {
    ;(inputRef.current! as any).click()
  }

  return (
    <>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Upload documents
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Grid
          container
          sx={{
            border: getValues('document') ? '0px' : '1px solid var(--other-divider, #E0E2E4)',
            borderRadius: '4px',
            minHeight: getValues('document') ? '0px' : '200px',
            alignContent: 'center',
          }}
        >
          {!getValues('document') && (
            <Grid
              container
              direction={'column'}
              sx={{ justifyContent: 'center', alignContent: 'center', height: '100%' }}
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                Drop files here
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))', textAlign: 'center' }}
              >
                Supported files: PDF. Max size 100MB.
              </Typography>
            </Grid>
          )}
          {getValues('document') &&
            getValues('document').map((file: any, index: number) => (
              <Grid
                key={index}
                container
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: ' 1px solid var(--other-divider, #E0E2E4)',
                  height: '52px',
                  borderRadius: borderRadiusRowRule(index, getValues('document').length),
                  padding: '0 12px',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '14px',
                    color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    width: '130px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {file.name ?? file.split('/').pop()}
                </Typography>
                {file.name && (
                  <Input
                    placeholder="Enter document name"
                    sx={{
                      width: '177px',
                      ':after': { borderBottom: 'none' },
                      ':before': { borderBottom: 'none' },
                      color: 'rgba(22;25;27;0.30)',
                      fontSize: 15,
                      fontFamily: 'Montserrat',
                      fontWeight: '400',
                      lineHeight: 24,
                      wordWrap: 'break-word',
                    }}
                    onChange={(e) => {
                      const filesArray = getValues('document')
                      filesArray[index].customName = e.target.value + file.name.slice(file.name.lastIndexOf('.'))
                      setvalue('document', filesArray, {
                        shouldValidate: true,
                      })
                    }}
                  />
                )}
                <Grid container sx={{ width: 'fit-content', alignItems: 'center' }}>
                  {file.size && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '14px',
                        color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                      }}
                    >
                      {calcDocumentSize(file.size)}
                    </Typography>
                  )}
                  <Button
                    onClick={() => {
                      const filesArray = getValues('document')
                      if (filesArray.length === 1) {
                        setvalue('document', undefined, {
                          shouldValidate: true,
                        })
                        return
                      }
                      filesArray.splice(index, 1)
                      setvalue('document', filesArray, {
                        shouldValidate: true,
                      })
                    }}
                    size="small"
                    sx={{ ':hover': { backgroundColor: 'transparent' }, minWidth: 0, width: 'fit-content' }}
                  >
                    <DeleteForever sx={{ color: '#16191B80' }} />
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Box>
      {getValues('document') && (
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))',
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            Supported files: PDF. Max size 100MB.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              color: 'var(--text-secondary, rgba(22, 25, 27, 0.50))',
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            {getValues('document').length} files selected
          </Typography>
        </Grid>
      )}
      <Button
        sx={{ mt: 1, mb: 4 }}
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        component="label"
        startIcon={<AttachFile />}
      >
        Add Files
      </Button>
      <input
        ref={inputRef}
        hidden
        accept="application/pdf"
        type="file"
        multiple
        onChange={(e) => {
          handleFiles(e.target.files)
        }}
      />
    </>
  )
}

export default DragDropFiles
