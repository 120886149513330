import algosdk, { Transaction } from 'algosdk'
import { JsonRpcRequest, SignerTransaction, WalletTransaction } from './types'

export type ASAToken = {
  id: string
  name: string
  symbol: string
  deploymentResult: {
    assetId: number
    applicationId: number
  }
}
export type ASATokenAccount = {
  assetOptedIn: boolean
  applicationOptedIn: boolean
}

export function getAlgoClient(): algosdk.Algodv2 {
  const algodToken = process.env.REACT_APP_ALGORAND_TOKEN || ''
  const algodServer = process.env.REACT_APP_ALGORAND_SERVER || ''
  const algodPort = Number(process.env.REACT_APP_ALGORAND_PORT || 443)
  return new algosdk.Algodv2(algodToken, algodServer, algodPort)
}

export async function generateOptInAssetTx(algodClient: algosdk.Algodv2, walletAddress: string, assetId: number) {
  const suggestedParams = await algodClient.getTransactionParams().do()
  const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: walletAddress,
    to: walletAddress,
    suggestedParams,
    assetIndex: assetId,
    amount: 0,
  })
  return optInTxn
}

export async function generateOptInApplicationTx(
  algodClient: algosdk.Algodv2,
  walletAddress: string,
  applicationId: number,
) {
  const suggestedParams = await algodClient.getTransactionParams().do()
  const optInTxn = algosdk.makeApplicationOptInTxn(walletAddress, suggestedParams, applicationId)
  return optInTxn
}

export async function waitForConfirmation(algodClient: algosdk.Algodv2, txId: string, timeout: any) {
  if (algodClient == null || txId == null || timeout < 0) {
    throw new Error('Bad arguments')
  }

  const status = await algodClient.status().do()
  if (status === undefined) {
    throw new Error('Unable to get node status')
  }

  const startround = status['last-round'] + 1
  let currentround = startround

  while (currentround < startround + timeout) {
    const pendingInfo = await algodClient.pendingTransactionInformation(txId).do()
    if (pendingInfo !== undefined) {
      if (pendingInfo['confirmed-round'] !== null && pendingInfo['confirmed-round'] > 0) {
        // Got the completed Transaction
        return pendingInfo
      } else if (pendingInfo['pool-error'] != null && pendingInfo['pool-error'].length > 0) {
        // If there was a pool error, then the transaction has been rejected!
        throw new Error('Transaction ' + txId + ' rejected - pool error: ' + pendingInfo['pool-error'])
      }
    }
    await algodClient.statusAfterBlock(currentround).do()
    currentround++
  }

  throw new Error('Transaction ' + txId + ' not confirmed after ' + timeout + ' rounds!')
}

const getPayloadId = (): number => {
  const date = Date.now() * Math.pow(10, 3)
  const extra = Math.floor(Math.random() * Math.pow(10, 3))
  return date + extra
}

export const formatJsonRpcRequest = <T = any>(method: string, params: T): JsonRpcRequest => {
  return {
    id: getPayloadId(),
    jsonrpc: '2.0',
    method,
    params,
  }
}

export const encodeUnsignedTransactionInBase64 = (txn: Transaction): string => {
  return Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64')
}

export const base64ToUint8Array = (data: string) => {
  return Uint8Array.from(window.atob(data), (value) => value.charCodeAt(0))
}

export function composeTransaction(transaction: SignerTransaction, signerAddress?: string) {
  let signers: WalletTransaction['signers']

  if (signerAddress && !(transaction.signers || []).includes(signerAddress)) {
    signers = []
  }

  const txnRequestParams: WalletTransaction = {
    txn: encodeUnsignedTransactionInBase64(transaction.txn),
  }

  if (Array.isArray(signers)) {
    txnRequestParams.signers = signers
  }

  if (transaction.authAddr) {
    txnRequestParams.authAddr = transaction.authAddr
  }

  if (transaction.message) {
    txnRequestParams.message = transaction.message
  }

  if (transaction.msig) {
    txnRequestParams.msig = transaction.msig
  }

  return txnRequestParams
}

export const getSignTxnRequestParams = (txns: SignerTransaction[] | SignerTransaction[][], signerAddress?: string) => {
  // If `txns` is a single array, convert it to an array of arrays
  if (!Array.isArray(txns[0])) {
    txns = [txns as SignerTransaction[]]
  }

  return (txns as SignerTransaction[][]).flatMap((txGroup) =>
    txGroup.map<WalletTransaction>((txGroupDetail) => composeTransaction(txGroupDetail, signerAddress)),
  )
}
