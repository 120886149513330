import { Trader } from '@archax/shared-types'
import { Box, Card, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getTrader } from '../../api/traders'
import { TabPanel } from '../../components/ui/TabPanel'
import TraderAddresses from './components/TraderAddresses'
import TraderHistory from './components/TraderHistory'
import TraderWhitelist from './components/TraderWhitelist'
import TraderHoldings from './TraderHoldings'
import TraderDetails from './TraderDetails'

function TraderManagement() {
  const { id } = useParams() as { id: string }
  const { isLoading, data, refetch } = useQuery([`get-trader-${id}`], () => getTrader(id as string), {
    retry: false,
  })
  const [tabId, setTabId] = useState(0)

  if (isLoading) {
    return <CircularProgress />
  }

  if (!data) {
    return <Typography variant="h2">Not found</Typography>
  }

  return (
    <>
      <Container maxWidth="xl">
        <Box mb={3}>
          <TraderDetails trader={data?.data as Trader} refetch={refetch} />
        </Box>

        <Card sx={{ p: 7, mb: 5 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabId} onChange={(e, newValue) => setTabId(newValue)}>
              <Tab label="History" />
              <Tab label="Addresses" />
              <Tab label="Tradable tokens" />
              <Tab label="Holdings" />
            </Tabs>
          </Box>
          <TabPanel value={tabId} index={0}>
            <TraderHistory id={id} />
          </TabPanel>
          <TabPanel value={tabId} index={1}>
            <TraderAddresses trader={data?.data as Trader} />
          </TabPanel>
          <TabPanel value={tabId} index={2}>
            <TraderWhitelist id={id} />
          </TabPanel>
          <TabPanel value={tabId} index={3}>
            <TraderHoldings id={id} />
          </TabPanel>
        </Card>
      </Container>
    </>
  )
}

export default TraderManagement
