import { Currency } from '@archax/shared-types'
import currencyFormatter from '../../util/currency-formatter'

export function holderValueFormatter(data: {
  currencySymbol?: string | null
  holderValue: string | object | null
}): string | undefined {
  if (data.holderValue) {
    if (typeof data.holderValue! === 'object') {
      return Object.entries(data.holderValue! as Record<Currency, string>)
        .map(([currency, value]) => {
          return currencyFormatter(value as string, { decimals: 2, prefix: currency as Currency })
        })
        .join(' + ')
    }
    return currencyFormatter(data.holderValue! as string, { decimals: 2, prefix: data.currencySymbol as Currency })
  }
  return
}

export function totalValueFormatter(data: string | Record<Currency, string>): string {
  if (typeof data === 'object') {
    return Object.entries(data as Record<Currency, string>)
      .map(([currency, value]) => {
        return currencyFormatter(value as string, { decimals: 2, prefix: currency as Currency })
      })
      .join(' + ')
  }
  return currencyFormatter(data as string, { decimals: 2 })
}
