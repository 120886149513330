"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsufficientDistributionAssetBalanceError = exports.InsufficientGasError = exports.InvalidTokenStatusError = exports.TokenNotArchivedError = exports.TokenArchivedError = exports.OperationError = exports.OperationErrorMessages = exports.OperationErrorCode = void 0;
var OperationErrorCode;
(function (OperationErrorCode) {
    OperationErrorCode["TOKEN_ARCHIVED"] = "TOKEN_ARCHIVED";
    OperationErrorCode["TOKEN_NOT_ARCHIVED"] = "TOKEN_NOT_ARCHIVED";
    OperationErrorCode["INVALID_TOKEN_STATUS"] = "INVALID_TOKEN_STATUS";
    OperationErrorCode["INSUFFICIENT_GAS"] = "INSUFFICIENT_GAS";
    OperationErrorCode["INSUFFICIENT_DISTRIBUTION_ASSET_BALANCE"] = "INSUFFICIENT_DISTRIBUTION_ASSET_BALANCE";
})(OperationErrorCode || (exports.OperationErrorCode = OperationErrorCode = {}));
var OperationErrorMessages;
(function (OperationErrorMessages) {
    OperationErrorMessages["TokenArchived"] = "Token is archived";
    OperationErrorMessages["TokenNotArchived"] = "Token is not archived";
    OperationErrorMessages["InvalidTokenStatus"] = "Token status is invalid";
    OperationErrorMessages["InsufficientGas"] = "Insufficient gas";
    OperationErrorMessages["InsuficientDistributionAssetBalance"] = "Insufficient distribution asset balance";
})(OperationErrorMessages || (exports.OperationErrorMessages = OperationErrorMessages = {}));
class OperationError extends Error {
    code;
    message;
    details;
    constructor(message, { code, details }) {
        super(message);
        Error.captureStackTrace(this, this.constructor);
        this.code = code;
        this.details = details;
    }
    get name() {
        return this.constructor.name;
    }
    toJSON() {
        return {
            code: this.code,
            message: this.message,
            details: this.details,
        };
    }
}
exports.OperationError = OperationError;
class TokenArchivedError extends OperationError {
    constructor(message = OperationErrorMessages.TokenArchived, errorOptions = {}) {
        const { code = OperationErrorCode.TOKEN_ARCHIVED, details } = errorOptions;
        super(message, { code, details });
    }
}
exports.TokenArchivedError = TokenArchivedError;
class TokenNotArchivedError extends OperationError {
    constructor(message = OperationErrorMessages.TokenNotArchived, errorOptions = {}) {
        const { code = OperationErrorCode.TOKEN_NOT_ARCHIVED, details } = errorOptions;
        super(message, { code, details });
    }
}
exports.TokenNotArchivedError = TokenNotArchivedError;
class InvalidTokenStatusError extends OperationError {
    constructor(errorOptions = {}, message = OperationErrorMessages.InvalidTokenStatus) {
        const { code = OperationErrorCode.INVALID_TOKEN_STATUS, details } = errorOptions;
        super(message, { code, details });
    }
}
exports.InvalidTokenStatusError = InvalidTokenStatusError;
class InsufficientGasError extends OperationError {
    constructor(errorOptions = { code: OperationErrorCode.INSUFFICIENT_GAS }, message = OperationErrorMessages.InsufficientGas) {
        const { code = OperationErrorCode.INSUFFICIENT_GAS, details } = errorOptions;
        super(message, { code, details });
    }
}
exports.InsufficientGasError = InsufficientGasError;
class InsufficientDistributionAssetBalanceError extends OperationError {
    constructor(errorOptions = {
        code: OperationErrorCode.INSUFFICIENT_DISTRIBUTION_ASSET_BALANCE,
    }, message = OperationErrorMessages.InsuficientDistributionAssetBalance) {
        const { code = OperationErrorCode.INSUFFICIENT_DISTRIBUTION_ASSET_BALANCE, details } = errorOptions;
        super(message, { code, details });
    }
}
exports.InsufficientDistributionAssetBalanceError = InsufficientDistributionAssetBalanceError;
