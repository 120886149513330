import { TABLE_HEADER_NAMES, User } from '@archax/shared-types'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Card, Container, IconButton, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteUser, getUsers, getUsersCSVUrl } from '../../api/users'
import Dialog from '../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import { AddButton } from '../../components/ui/AddButton'
import { substringFilterParams } from '../../util/common-grid-options'
import onApiError from '../../util/on-api-error'
import UserDetailsForm, { UserDetailFormMode } from './components/UserDetailsForm'

function ListUsers() {
  const navigate = useNavigate()
  const [gridApi, setGridApi] = useState<null | GridApi>(null)
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false)
  const [showUserDetailsDialog, setShowUserDetailsDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState<User | null>(null)

  const { mutate } = useMutation((userId: string) => deleteUser(userId), {
    onSuccess: async (data) => {
      toast.success('User deleted successfully')
      gridApi?.refreshServerSide()
    },
    onError: onApiError,
  })

  const openDialog = useCallback(
    (selectedItem: User) => () => {
      setSelectedItem(selectedItem)
      setShowDeleteUserDialog(true)
    },
    [],
  )

  const handleDeleteUser = async () => {
    mutate(selectedItem?.id as string)
    setShowDeleteUserDialog(false)
  }

  const columnDefs: ColDef<User>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: TABLE_HEADER_NAMES.common.name,
        flex: 4,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'email',
        headerName: TABLE_HEADER_NAMES.common.email,
        flex: 5,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1,
        minWidth: 115,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<User>) => {
          return (
            <IconButton aria-label="Delete" onClick={openDialog(params.data!)}>
              <DeleteIcon />
            </IconButton>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Details',
        flex: 1,
        minWidth: 166,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<User>) => {
          return (
            <Button
              variant="contained"
              onClick={() => {
                setSelectedItem(params.data!)
                setShowUserDetailsDialog(true)
              }}
            >
              <Typography variant="button" fontWeight={700}>
                View Details
              </Typography>
            </Button>
          )
        },
      },
    ],
    [openDialog],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
    }),
    [columnDefs],
  )

  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography align="left" variant="h3">
            Users management
          </Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <AddButton
              name="create-user"
              ariaLabel="Create a new user"
              onClick={() => navigate(`/users/create`)}
            ></AddButton>
          </Box>
        </Box>
        <Box>
          <ServerSideGrid
            gridOptions={gridOptions}
            queryFn={getUsers}
            csvExportUrlGetter={getUsersCSVUrl}
            setParentGridApi={setGridApi}
          />
        </Box>
      </Card>
      <Dialog
        title="Are you sure that you want to delete this user?"
        onConfirm={handleDeleteUser}
        confirmLabel="Delete"
        onClose={() => setShowDeleteUserDialog(false)}
        open={showDeleteUserDialog}
        showCancel
      >
        <Typography variant="body2">You can not revert this action.</Typography>
      </Dialog>
      {selectedItem?.id && (
        <Dialog
          open={showUserDetailsDialog}
          title=""
          onClose={() => setShowUserDetailsDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <UserDetailsForm
            name={selectedItem?.name as string}
            email={selectedItem?.email as string}
            userId={selectedItem?.id as string}
            onClose={() => setShowUserDetailsDialog(false)}
            mode={UserDetailFormMode.AdminReset}
          />
        </Dialog>
      )}
    </Container>
  )
}

export default ListUsers
