import algosdk from 'algosdk'
import { useEffect, useState } from 'react'
import { useConnection as usePeraWalletConnection } from './peraWalletConnect'
import { useConnection as useWCConnection } from './walletConnect'
import { WalletConnection } from './types'

const defautConnection = {
  disconnect: async () => {},
  signTransaction: async (tx: algosdk.Transaction) => {
    return undefined
  },
}
export function useConnection() {
  const [connection, setConnection] = useState<{
    walletConnection?: WalletConnection
    disconnect: () => Promise<void>
    signTransaction: (transaction: algosdk.Transaction) => Promise<Uint8Array[] | undefined>
  }>(defautConnection)

  const {
    walletConnection: peraWalletConnection,
    connect: peraWalletConnect,
    disconnect: peraWalletDisconnect,
    signTransaction: peraWalletSignTransaction,
  } = usePeraWalletConnection()

  const {
    walletConnection: wcConnection,
    connect: wcConnect,
    disconnect: wcDisconnect,
    signTransaction: wcSignTransaction,
  } = useWCConnection()

  useEffect(() => {
    if (peraWalletConnection) {
      setConnection({
        walletConnection: peraWalletConnection,
        disconnect: peraWalletDisconnect,
        signTransaction: peraWalletSignTransaction,
      })
    } else if (wcConnection) {
      setConnection({
        walletConnection: wcConnection,
        disconnect: wcDisconnect,
        signTransaction: wcSignTransaction,
      })
    } else {
      setConnection(defautConnection)
    }
  }, [peraWalletConnection, wcConnection])

  return {
    walletConnection: connection.walletConnection,
    peraWalletConnect,
    wcConnect,
    disconnect: connection.disconnect,
    signTransaction: connection.signTransaction,
  }
}
