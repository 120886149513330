import { NFT_STANDARDS, Token } from '@archax/shared-types'
import TokenBlacklistedAddresses from './TokenBlacklistedAddresses'

interface TokenBlacklistProps {
  token: Token
}

function TokenBlacklist({ token }: TokenBlacklistProps) {
  const isNft = NFT_STANDARDS.includes(token.standard.name)
  const canMint = !isNft || (isNft && token.onChainData?.totalSupply === '0')
  const canSend = !isNft || (isNft && BigInt(token.onChainData?.balance!) > BigInt(0))

  return (
    <div>
      <TokenBlacklistedAddresses token={token} {...{ isNft, canMint, canSend }} />
    </div>
  )
}

export default TokenBlacklist
