import { ReactElement } from 'react'
import { ChainProtocol, Operation, Token } from '@archax/shared-types'
import { Box, Card, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editOperation } from '../../../api/operations'
import { FormMode } from '../../../api/types'
import onApiError from '../../../util/on-api-error'
import HederaForm, { DefaultValuesFormData as HederaDefaultValuesFormData } from './ChangeOwnerForm/HederaForm'
import DefaultForm, { DefaultValuesFormData } from './ChangeOwnerForm/DefaultForm'

export default function EditChangeOwnerOperation({ operation }: { operation: Operation }): ReactElement {
  const navigate = useNavigate()
  const { mutateAsync, isLoading } = useMutation(
    (operationData: Record<string, unknown>) =>
      editOperation(operation.id, {
        data: operationData,
      }),
    {
      onSuccess: () => {
        toast.success('Change token owner request edited with success.')
        navigate(`/tokens/requests/edit`)
      },
      onError: onApiError,
    },
  )

  const onSubmit = async (operationData: Record<string, unknown>) => {
    if (!isLoading) {
      return mutateAsync(operationData)
    }
  }

  const {
    standard: {
      chain: { protocol },
    },
  } = operation.token as Token
  const defaultValues = operation.data || {}

  const renderForm = () => {
    switch (protocol) {
      case ChainProtocol.Hedera:
        return (
          <HederaForm
            mode={FormMode.Edit}
            defaultValues={defaultValues as HederaDefaultValuesFormData}
            token={operation.token as Token}
            onSubmit={onSubmit}
          />
        )
      default:
        return (
          <DefaultForm
            mode={FormMode.Edit}
            defaultValues={defaultValues as DefaultValuesFormData}
            token={operation.token as Token}
            onSubmit={onSubmit}
          />
        )
    }
  }

  return (
    <Container maxWidth="md">
      <Card sx={{ p: 7 }}>
        <Typography align="center" variant="h2">
          Edit change token owner request
        </Typography>
        <Box m={4} />
        {renderForm()}
      </Card>
    </Container>
  )
}
