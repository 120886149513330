import { OperationType, Token } from '@archax/shared-types'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { createOperation } from '../../../api/operations'
import Dialog from '../../../components/Dialog/Dialog'
import { useGlobalContext } from '../../../context'
import onApiError from '../../../util/on-api-error'
import { hasUserRole } from '../../../util/user-roles'

interface TokenConfigurationProps {
  token: Token
}

function TokenConfiguration({ token }: TokenConfigurationProps) {
  const [showSetBlacklistableStatusDialog, setShowSetBlacklistableStatusDialog] = useState(false)
  const [showSetKYCableStatusDialog, setShowSetKYCableStatusDialog] = useState(false)

  const { mutate: setKYCableStatusMutation } = useMutation(
    () => createOperation(OperationType.SetKYCableStatus, { isKYCable: !token.onChainData?.isKYCable }, token.id),
    {
      onSuccess: () => {
        toast.success(`${token.onChainData?.isKYCable ? 'Disable' : 'Enable'}  whitelist request sent for approval`)
      },
      onError: onApiError,
    },
  )

  const { mutate: setBlacklistableStatus } = useMutation(
    () =>
      createOperation(
        OperationType.SetBlacklistableStatus,
        { isBlacklistable: !token.onChainData?.isBlacklistable },
        token.id,
      ),
    {
      onSuccess: () => {
        toast.success(
          `${token.onChainData?.isBlacklistable ? 'Disable' : 'Enable'}  blacklist request sent for approval`,
        )
      },
      onError: onApiError,
    },
  )

  const {
    state: { user },
  } = useGlobalContext()

  const handleToggleKYCableStatus = () => {
    setKYCableStatusMutation()
    setShowSetKYCableStatusDialog(false)
  }

  const handleToggleBlacklistableStatus = () => {
    setBlacklistableStatus()
    setShowSetBlacklistableStatusDialog(false)
  }

  return (
    <Box>
      <Box
        sx={{ paddingLeft: 0, marginBottom: 4 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          Configuration
        </Typography>
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Switch
              disabled={!hasUserRole(user)}
              checked={token.onChainData?.isKYCable}
              onChange={() => setShowSetKYCableStatusDialog(true)}
              name="whitelistable-switch"
              color="primary"
            />
          }
          label={
            <Box>
              <Typography variant="h4">Whitelisting</Typography>
              <Typography variant="body2">
                When this switch is enabled whitelisting is enabled on the token contract.
              </Typography>
            </Box>
          }
          labelPlacement="start"
          sx={{ marginLeft: 0, marginBottom: 2 }}
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Switch
              disabled={!hasUserRole(user)}
              checked={token.onChainData?.isBlacklistable}
              onChange={() => setShowSetBlacklistableStatusDialog(true)}
              name="blacklistable-switch"
              color="primary"
            />
          }
          label={
            <Box>
              <Typography variant="h4">Blacklisting</Typography>
              <Typography variant="body2">
                When this switch is enabled blacklisting is enabled on the token contract.
              </Typography>
            </Box>
          }
          labelPlacement="start"
          sx={{ marginLeft: 0 }}
        />
      </Box>

      <Dialog
        title="Are you sure that you want to toggle whitelisting status?"
        onConfirm={handleToggleKYCableStatus}
        confirmLabel={token.onChainData?.isKYCable ? 'Disable whitelist' : 'Enable whitelist'}
        onClose={() => setShowSetKYCableStatusDialog(false)}
        open={showSetKYCableStatusDialog}
        showCancel
      >
        <Typography variant="body2">
          This action will {token.onChainData?.isKYCable ? 'disable' : 'enable'} whitelisting on the token contract.
        </Typography>
      </Dialog>
      <Dialog
        title="Are you sure that you want to toggle blacklisting status?"
        onConfirm={handleToggleBlacklistableStatus}
        confirmLabel={token.onChainData?.isBlacklistable ? 'Disable blacklist' : 'Enable blacklist'}
        onClose={() => setShowSetBlacklistableStatusDialog(false)}
        open={showSetBlacklistableStatusDialog}
        showCancel
      >
        <Typography variant="body2">
          This action will {token.onChainData?.isBlacklistable ? 'disable' : 'enable'} blacklisting on the token
          contract.
        </Typography>
      </Dialog>
    </Box>
  )
}

export default TokenConfiguration
