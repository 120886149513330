import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Token } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { Download } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { generateHederaChangeOwnerTransaction } from '../../../../api/operations'
import onApiError from '../../../../util/on-api-error'
import WalletAddressInput, { walletAddressValidator } from '../../../../components/ui/WalletAddressInput'
import FormTextField from '../../../../components/ui/TextField/TextField'
import { StyledBoxAction, StyledButtonAction, StyledCircularProgress, StyledForm } from './styled-components'
import FileUpload from '../../../../components/ui/FileUpload'
import * as FileUtils from '../../../../util/file-utils'
import { FormMode } from '../../../../api/types'

export type DefaultValuesFormData = {
  newOwnerPublicKey: string
  newOwnerAccountId: string
  executeAt: Date
}

type FormData = {
  newOwnerPublicKey?: string
  newOwnerAccountId?: string
}

type Props = {
  mode: FormMode
  defaultValues?: DefaultValuesFormData
  token: Token
  onCancel?: () => void
  onSubmit: (operationData: Record<string, unknown>) => void
}
export default function HederaForm({ mode, defaultValues, token, onCancel, onSubmit }: Props) {
  const hederaTokenId = token.deploymentResult?.tokenId || ''
  const currentOwnerAdddress = token.deploymentResult?.owner?.address || ''
  const {
    privacyEnabled,
    standard: {
      chain: { protocol },
    },
  } = token

  const initialValues = {
    newOwnerPublicKey: defaultValues?.newOwnerPublicKey || '',
    newOwnerAccountId: defaultValues?.newOwnerAccountId || '',
  }

  const [scheduledTransaction, setScheduleTransaction] = useState<{
    executeAt: Date
    serializedTransaction: string
  } | null>(
    !!defaultValues
      ? {
          executeAt: new Date(defaultValues.executeAt),
          serializedTransaction: '',
        }
      : null,
  )

  const [transactionSignature, setTransactionSignature] = useState<string | null>(null)
  const validationSchema = Yup.object()
    .shape({
      newOwnerPublicKey: Yup.string().required('New Owner Public Key is required'),
      newOwnerAccountId: walletAddressValidator(token.standard.chain.protocol)
        .test({
          message: 'The account id must different from the current owner account id',
          test: (value) => !!currentOwnerAdddress && !!value && currentOwnerAdddress !== value,
        })
        .required('New Owner Account Id is required'),
    })
    .required()

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const { isLoading: isLoadingTransaction, mutateAsync: generateTransaction } = useMutation(
    () => {
      const formData = getValues()
      return generateHederaChangeOwnerTransaction(token.id, formData.newOwnerPublicKey!, formData.newOwnerAccountId!)
    },
    {
      onSuccess: ({ data }) => {
        const blob = new Blob([data.serializedTransaction], { type: 'text/plain' })
        FileUtils.download(blob, 'hedera-transaction-to-sign.txt')
        setScheduleTransaction({
          executeAt: new Date(data.executeAt),
          serializedTransaction: data.serializedTransaction,
        })
      },
      onError: onApiError,
    },
  )

  const submitForm = async (formData: FormData) => {
    if (!isSubmitting) {
      return onSubmit({
        newOwnerPublicKey: formData.newOwnerPublicKey,
        newOwnerAccountId: formData.newOwnerAccountId,
        transactionSignature,
        executeAt: scheduledTransaction?.executeAt.toISOString(),
      })
    }
  }

  const disableSubmitForm = !scheduledTransaction || !isValid || (mode === FormMode.Edit && !transactionSignature)

  return (
    <StyledForm onSubmit={handleSubmit(submitForm)}>
      <FormTextField control={control} label="New Owner Public Key" name="newOwnerPublicKey" />
      <WalletAddressInput
        control={control}
        label="New Owner Account Id"
        name="newOwnerAccountId"
        protocol={protocol}
        privacyEnabled={privacyEnabled}
      />
      <Box m={1} />
      <Typography align="center" variant="body2">
        Please associate the new owner with the Asset: <strong>{hederaTokenId}</strong>
        <span>
          <br />
          download and sign the follow transaction
        </span>
      </Typography>
      <StyledBoxAction>
        <StyledButtonAction
          sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
          variant="contained"
          onClick={() => {
            generateTransaction()
          }}
          disabled={!isValid}
        >
          {isLoadingTransaction ? (
            <>
              <StyledCircularProgress size={'1.2rem'} />
              Downloading
            </>
          ) : (
            <>
              <Download />
              Download
            </>
          )}
        </StyledButtonAction>
      </StyledBoxAction>
      <Box m={4} />
      {!!scheduledTransaction && (
        <FileUpload
          title="Upload file"
          description="File .txt with the serialized signature for the transaction"
          accept=".txt"
          onChange={(file) => {
            const reader = new FileReader()
            reader.addEventListener(
              'load',
              () => {
                const fileContent = reader.result?.toString()
                if (fileContent) {
                  setTransactionSignature(fileContent)
                }
              },
              false,
            )
            reader.readAsText(file)
          }}
        />
      )}
      <Box m={4} />
      <ButtonGroup fullWidth>
        {onCancel && (
          <Button size="large" fullWidth onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type="submit" variant="contained" size="large" color="primary" fullWidth disabled={disableSubmitForm}>
          {isSubmitting && <StyledCircularProgress size={'1.2rem'} />}
          {isSubmitting ? 'Submitting' : 'Submit'}
        </Button>
      </ButtonGroup>
    </StyledForm>
  )
}
