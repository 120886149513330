import { ChainProtocol, EVM_CHAIN_PROTOCOLS, Token, TokenType } from '@archax/shared-types'

const canRegisterTokens = (token: Token) => {
  return token.tokenType === TokenType.Pool
}

const isEVMProtocol = (protocol: ChainProtocol): boolean => {
  return EVM_CHAIN_PROTOCOLS.includes(protocol)
}

export { canRegisterTokens, isEVMProtocol }
