import { CircularProgress, Stack } from '@mui/material'
import { Box, Typography, styled } from '@mui/material'
import { ASAToken, ASATokenAccount } from './utils'
import { OptInButton, OptedInButton } from './styled-components'
import { useState } from 'react'

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}))

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 'solid 1px ' + theme.palette.grey[800],
  borderRadius: '10px',
  padding: '1.4rem',
}))

type Step2Props = {
  token: ASAToken
  tokenAccount: ASATokenAccount
  optInAsset: (assetId: number) => void
  optInApplication: (applicationId: number) => void
}
export default function Step2({ token, tokenAccount, optInAsset, optInApplication }: Step2Props) {
  const [processingOptInAsset, setProcessingOptInAsset] = useState(false)
  const [processingOptInApplication, setProcessingOptInApplication] = useState(false)

  return (
    <Wrapper>
      <Typography align="center" variant="body2">
        Welcome to the Archax token opt in page
        <br />
        Please follow the steps below to be able to receive the token
      </Typography>
      <Container>
        <StyledBox>
          <Stack>
            <Typography align="left" variant="h2">
              {token.name} ({token.symbol})
            </Typography>
            <Typography align="left" variant="body2">
              Asset Id: {token.deploymentResult.assetId}
            </Typography>
          </Stack>
          {tokenAccount.assetOptedIn ? (
            <OptedInButton variant="contained" size="large" color="success">
              Opted in
            </OptedInButton>
          ) : (
            <OptInButton
              variant="contained"
              size="large"
              color="primary"
              onClick={async () => {
                setProcessingOptInAsset(true)
                await optInAsset(token.deploymentResult.assetId)
                setProcessingOptInAsset(false)
              }}
              startIcon={processingOptInAsset ? <CircularProgress size={20} /> : null}
            >
              {processingOptInAsset ? 'Opting in' : 'Opt in'}
            </OptInButton>
          )}
        </StyledBox>
        <StyledBox>
          <Stack justifyContent={'start'}>
            <Typography align="left" variant="h2">
              Permission Manager
              <br />
              {token.name} ({token.symbol})
            </Typography>
            <Typography align="left" variant="body2">
              Application Id: {token.deploymentResult.applicationId}
            </Typography>
          </Stack>
          {tokenAccount.applicationOptedIn ? (
            <OptedInButton variant="contained" size="large" color="success">
              Opted in
            </OptedInButton>
          ) : (
            <OptInButton
              variant="contained"
              size="large"
              color="primary"
              onClick={async () => {
                setProcessingOptInApplication(true)
                await optInApplication(token.deploymentResult.applicationId)
                setProcessingOptInApplication(false)
              }}
              startIcon={processingOptInApplication ? <CircularProgress size={20} /> : null}
            >
              {processingOptInApplication ? 'Opting in' : 'Opt in'}
            </OptInButton>
          )}
        </StyledBox>
      </Container>
    </Wrapper>
  )
}
