import { OperationType, SendTokenFormData, Token, TokenWhitelistTradersData, TraderAddress } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import TextField from '../../../../components/ui/TextField/TextField'
import onApiError from '../../../../util/on-api-error'
import { tokenAmountValidator } from '../../../../util/yup-validators'
import { HederaAssociateHelper } from '../HederaAssociateHelper'

interface SendTokenProps {
  token: Token
  onSuccess: () => void
  account?: TraderAddress
  poolToken?: Token
}

function SendTokenForm({ token, onSuccess, account, poolToken }: SendTokenProps) {
  const { id, decimals, onChainData } = token
  const validationSchema = Yup.object()
    .shape({
      amount: tokenAmountValidator({ decimals, positive: true, max: onChainData?.balance }),
    })
    .required()

  const initialValues = { amount: '0' }

  const { mutate } = useMutation(
    (formData: SendTokenFormData) => createOperation(OperationType.Send, formData, id, account && account.trader.id),
    {
      onSuccess: (data) => {
        toast.success('Send token request sent for approval')
        onSuccess()
      },
      onError: onApiError,
    },
  )

  const onSubmit = (data: SendTokenFormData) => {
    const operationData = account ? { traderAddressId: account.id, ...data } : { poolTokenId: poolToken!.id, ...data }
    mutate(operationData)
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<SendTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting || isSubmitSuccessful

  return (
    <form data-testid="send-token-form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        data-testid="send-token-form__amount-input"
        name="amount"
        type="number"
        control={control}
        label="Number to send"
      />

      <ButtonGroup fullWidth>
        <Button
          data-testid="send-token-form__submit-button"
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Send
        </Button>
      </ButtonGroup>
      <HederaAssociateHelper token={token} />
    </form>
  )
}
export default SendTokenForm
