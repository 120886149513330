import { Currency } from '@archax/shared-types'
import { numericFormatter } from 'react-number-format'

const currencyFormatter = (
  value: string,
  options: { prefix?: Currency | string; decimals: number; keepDecimalsOnScale?: boolean },
): string => {
  const { prefix = '', decimals, keepDecimalsOnScale = false } = options
  const currencyPrefix = prefix ? `${prefix} ` : ''
  if (!value) {
    return `${currencyPrefix}-`
  }

  const paddedValue = value.padStart(decimals + 1, '0')

  let initialInteger, initialDecimal

  if (paddedValue.indexOf('.') > -1) {
    const [integer, decimal] = paddedValue.split('.')
    initialInteger = integer
    initialDecimal = decimal
  } else {
    initialInteger = paddedValue
    initialDecimal = ''
  }

  const decimalString =
    initialInteger.slice(0, -Number(decimals)) + '.' + initialInteger.slice(-Number(decimals)) + initialDecimal

  return numericFormatter(decimalString, {
    prefix: `${currencyPrefix}`,
    thousandSeparator: true,
    decimalScale: keepDecimalsOnScale ? decimals : 2,
    fixedDecimalScale: true,
  })
}
export default currencyFormatter
