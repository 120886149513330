import { Button } from '@mui/material'
import { ReactElement, ReactNode, useState } from 'react'
import Dialog from '../../../components/Dialog/Dialog'

type Props = {
  label: string
  buttonProps: Record<string, unknown>
  children: ReactNode
}
export default function CellDetailsModal({ label, buttonProps, children }: Props): ReactElement {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <>
      <Button
        onClick={() => {
          setShowDialog(true)
        }}
        {...buttonProps}
      >
        {label}
      </Button>
      <Dialog title={''} onClose={() => setShowDialog(false)} open={showDialog}>
        {children}
      </Dialog>
    </>
  )
}
