import { OperationStatus, OperationType } from '@archax/shared-types'
import qs from 'qs'
import { axios } from './axios'
import { PaginationRequestParams } from './types'

export const getOperation = (id: string) => {
  return axios.get(`/operations/${id}`)
}

export const generateHederaChangeOwnerTransaction = (
  tokenId: string,
  newOwnerPublicKey: string,
  newOwnerAccountId: string,
) => {
  return axios.post('/operations/generate-hedera-change-owner-transaction', {
    tokenId,
    newOwnerPublicKey,
    newOwnerAccountId,
  })
}

export const createOperation = (type: OperationType, data: Object, tokenId?: string, traderId?: string) => {
  return axios.post('/operations', { type, data, tokenId, traderId })
}

export const createOperationWithFiles = (type: OperationType, data: Object, tokenId?: string, traderId?: string) => {
  const { document, ...restData } = data as any
  const formData = new FormData()
  const customDocName: Record<string, string> = {}
  if (document && document[0]) {
    for (const file of document) {
      customDocName[String(file.name)] = file.customName
      formData.append('documents', file)
    }
  }
  formData.append('data', JSON.stringify({ ...restData, customDocName }))
  formData.append('type', type)
  if (tokenId) {
    formData.append('tokenId', tokenId)
  }
  if (traderId) {
    formData.append('traderId', traderId)
  }
  return axios.post('/operations', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const editOperation = (id: string, data: Object) => {
  return axios.put(`/operations/${id}`, data)
}

export const editOperationWithFiles = (id: string, data: Object) => {
  const { document, ...restData } = data as any
  const formData = new FormData()
  const customDocName: Record<string, string> = {}
  if (document && document[0]) {
    for (const file of document) {
      customDocName[String(file.name)] = file.customName
      formData.append('documents', file)
    }
  }
  formData.append('data', JSON.stringify({ ...restData, customDocName }))
  return axios.put(`/operations/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const approveOperation = (id: string, version: number) => {
  return axios.post(`/operations/${id}/approve`, { version })
}

export const cancelOperation = (id: string) => {
  return axios.post(`/operations/${id}/cancel`)
}

export const rejectOperation = (id: string, version: number) => {
  return axios.post(`/operations/${id}/reject`, { version })
}

interface GetOperationsParams extends PaginationRequestParams {
  status?: OperationStatus[]
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}
export const getOperationsToApprove = (params: GetOperationsParams) => {
  const { sort, ...rest } = params
  const queryObj = {
    status: [OperationStatus.PendingApproval, OperationStatus.InProgress, OperationStatus.Queued],
    ...rest,
    ...(sort && { sort: `${sort.field},${sort.direction}` }),
  }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/operations?${querystring}`)
}

interface GetMyOperationsParams extends PaginationRequestParams {
  status?: OperationStatus[]
  type?: OperationType[]
}

export const getMyOperations = (params: GetMyOperationsParams) => {
  params.type = [OperationType.CreateToken, OperationType.ChangeOwner]
  params.status = [OperationStatus.PendingApproval]
  const querystring = qs.stringify(params, { indices: false })

  return axios.get(`/me/operations?${querystring}`)
}

export const getOperationDocuments = (id: string) => {
  return axios.get(`/operations/${id}/documents`)
}
