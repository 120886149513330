"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.READABLE_TRANSACTION_TYPE = exports.TransactionType = exports.TransactionStatus = void 0;
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["Prepared"] = "PREPARED";
    TransactionStatus["Pending"] = "PENDING";
    TransactionStatus["Success"] = "SUCCESS";
    TransactionStatus["Failed"] = "FAILED";
})(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
var TransactionType;
(function (TransactionType) {
    TransactionType["MintERC20"] = "MINT_ERC20";
    TransactionType["MintERC721"] = "MINT_ERC721";
    TransactionType["TransferERC20"] = "TRANSFER_ERC20";
    TransactionType["TransferERC721"] = "TRANSFER_ERC721";
    TransactionType["MintFungible"] = "MINT_FUNGIBLE";
    TransactionType["MintNonFungible"] = "MINT_NON_FUNGIBLE";
    TransactionType["TransferFungible"] = "TRANSFER_FUNGIBLE";
    TransactionType["TransferNonFungible"] = "TRANSFER_NON_FUNGIBLE";
    TransactionType["GrantKYC"] = "GRANT_KYC";
    TransactionType["RevokeKYC"] = "REVOKE_KYC";
    TransactionType["Wipe"] = "WIPE";
    TransactionType["DeployImplementation"] = "DEPLOY_IMPLEMENTATION";
    TransactionType["DeployProxy"] = "DEPLOY_PROXY";
    TransactionType["Upgrade"] = "UPGRADE";
    TransactionType["AddToBlacklist"] = "ADD_TO_BLACKLIST";
    TransactionType["RemoveFromBlacklist"] = "REMOVE_FROM_BLACKLIST";
    TransactionType["SetKYCableStatus"] = "SET_KYCABLE_STATUS";
    TransactionType["SetBlacklistableStatus"] = "SET_BLACKLISTABLE_STATUS";
    // Pool tokens
    TransactionType["RegisterToken"] = "REGISTER_TOKEN";
    TransactionType["UnregisterToken"] = "UNREGISTER_TOKEN";
    // Hedera specific
    TransactionType["AssociateToken"] = "ASSOCIATE_TOKEN";
    TransactionType["CreateToken"] = "CREATE_TOKEN";
    TransactionType["CreateProxyFile"] = "CREATE_PROXY_FILE";
    TransactionType["AppendProxyFile"] = "APPEND_PROXY_FILE";
    TransactionType["CreateImplementationFile"] = "CREATE_IMPLEMENTATION_FILE";
    TransactionType["AppendImplementationFile"] = "APPEND_IMPLEMENTATION_FILE";
    TransactionType["CreateProxyContract"] = "CREATE_PROXY_CONTRACT";
    TransactionType["CreateImplementationContract"] = "CREATE_IMPLEMENTATION_CONTRACT";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
exports.READABLE_TRANSACTION_TYPE = {
    [TransactionType.MintERC20]: 'Mint ERC20',
    [TransactionType.MintERC721]: 'Mint ERC721',
    [TransactionType.TransferERC20]: 'Transfer ERC20',
    [TransactionType.TransferERC721]: 'Transfer ERC721',
    [TransactionType.MintFungible]: 'Mint Fungible',
    [TransactionType.MintNonFungible]: 'Mint Non-Fungible',
    [TransactionType.TransferFungible]: 'Transfer Fungible',
    [TransactionType.TransferNonFungible]: 'Transfer Non-Fungible',
    [TransactionType.GrantKYC]: 'Grant KYC',
    [TransactionType.RevokeKYC]: 'Revoke KYC',
    [TransactionType.Wipe]: 'Wipe',
    [TransactionType.DeployImplementation]: 'Deploy implementation contract',
    [TransactionType.DeployProxy]: 'Deploy proxy contract',
    [TransactionType.Upgrade]: 'Upgrade contract',
    [TransactionType.AddToBlacklist]: 'Add to blacklist',
    [TransactionType.RemoveFromBlacklist]: 'Remove from blacklist',
    [TransactionType.SetKYCableStatus]: 'Set KYCable status',
    [TransactionType.SetBlacklistableStatus]: 'Set blacklistable status',
    // Pool tokens
    [TransactionType.RegisterToken]: 'Register token',
    [TransactionType.UnregisterToken]: 'Unregister token',
    // Hedera specific
    [TransactionType.AssociateToken]: 'Associate token',
    [TransactionType.CreateToken]: 'Create token',
    [TransactionType.CreateProxyFile]: 'Create proxy file',
    [TransactionType.AppendProxyFile]: 'Append proxy file',
    [TransactionType.CreateImplementationFile]: 'Create implementation file',
    [TransactionType.AppendImplementationFile]: 'Append implementation file',
    [TransactionType.CreateProxyContract]: 'Create proxy contract',
    [TransactionType.CreateImplementationContract]: 'Create implementation contract',
};
