import * as React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { ChainProtocol } from '@archax/shared-types'
import { isValidAddress } from '@archax/address-validator'
import { EXPLORER_LOGOS } from '../../../constants'
import * as Yup from 'yup'
import { readableTokenProtocol } from '../../../util/token-utils'

export function walletAddressValidator(protocol: ChainProtocol) {
  return Yup.string().test({
    message: 'It is not a valid address',
    test: (value) => !!value && isValidAddress(value, protocol),
  })
}

type Props = {
  control: Control<FieldValues>
  label: string
  name: string
  protocol: ChainProtocol
  privacyEnabled: boolean
}
export default function WalletAddressInput({
  control,
  label,
  name,
  protocol,
  privacyEnabled,
}: Props): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => (
        <TextField
          fullWidth
          id={`wallet-address-input-${name}`}
          label={label}
          sx={{ mb: 4 }}
          variant="standard"
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  alt={`${readableTokenProtocol(protocol, privacyEnabled)} explorer logo`}
                  src={EXPLORER_LOGOS[protocol]}
                  width="20"
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
