"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationErrorMessage = void 0;
var OperationErrorMessage;
(function (OperationErrorMessage) {
    OperationErrorMessage["NotFound"] = "Operation not found";
    OperationErrorMessage["OperationCanNotBeUpdated"] = "This operation type can not be updated.";
    OperationErrorMessage["UpdateAllowedOnlyForRequestor"] = "Only the requestor can perform this operation.";
    OperationErrorMessage["OperationTypeCannotBeAccessed"] = "This operation type cannot be accessed by the logged in user.";
    OperationErrorMessage["UserAndRequestorCannotBeTheSame"] = "The logged user and the operation requestor cannot be the same.";
    OperationErrorMessage["DistributionAssetNotSupported"] = "This token only supports USDC as distribution asset.";
    OperationErrorMessage["DistributionUSDCNotSupported"] = "This token does not support USDC as distribution asset.";
    OperationErrorMessage["DistributionNotSupported"] = "Only ERC20PoolToken tokens support distribution";
    OperationErrorMessage["SingleMintNFTRequest"] = "There is already a pending mint operation for this token. Please wait for it to be approved or rejected before submitting a new one.";
    OperationErrorMessage["MaxSupplyExceeded"] = "The maximum supply of this token has been exceeded.";
    OperationErrorMessage["OperationDuplicated"] = "An identical operation is already pending. Please await its approval or rejection.";
})(OperationErrorMessage || (exports.OperationErrorMessage = OperationErrorMessage = {}));
