import { Token } from '@archax/shared-types'
import { ModeEditOutlineOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useMemo, useState } from 'react'
import { getTokenDocuments } from '../../../../api/tokens'
import { useGlobalContext } from '../../../../context'
import { formatDate } from '../../../../util/formatters'
import { hasUserRole } from '../../../../util/user-roles'
import UpdateTokenDocs from './UpdateTokenDocs'

interface ShowTokenDocumentsProps {
  token: Token
}

export interface Docs {
  name: string
  link: string
  lastModified: string
  key: string
  customName?: string
}

function ShowTokenDocuments({ token }: ShowTokenDocumentsProps) {
  const [openEditDocuments, setOpenEditDocuments] = useState(false)
  const {
    state: { user },
  } = useGlobalContext()

  const { isLoading, data } = useQuery(
    [`get-token-${token.id}-documents`],
    () => getTokenDocuments(token.id as string),
    {
      refetchOnWindowFocus: false,
    },
  )

  const documents = useMemo(() => data?.data || [], [data])

  const columnDefs: ColDef<Docs>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'lastModified',
        headerName: 'Last modified',
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Docs>) => {
          return formatDate(params.data!.lastModified)
        },
      },
      {
        field: 'hash',
        headerName: 'Hash',
        flex: 2,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'link',
        headerName: '',
        flex: 1,
        maxWidth: 230,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<Docs>) => {
          const downloadUrl = `/api/tokens/${token.id}/documents/${params.data!.name.split('.pdf')[0]}`
          return (
            <>
              <Button
                variant="contained"
                size="small"
                href={params.data!.link}
                target="_blank"
                sx={{ marginRight: '12px' }}
              >
                Preview
              </Button>
              <Button variant="contained" size="small" href={downloadUrl} target="_blank">
                Download
              </Button>
            </>
          )
        },
      },
    ],
    [token.id],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: documents,
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
    }),
    [columnDefs, documents],
  )

  return (
    <>
      <Box>
        <Grid container mt={1} mb={4} sx={{ alignContent: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h2">Documents</Typography>
          {hasUserRole(user) && (
            <Button
              sx={{ cursor: 'pointer', display: 'flex', alignContent: 'center' }}
              onClick={() => setOpenEditDocuments(true)}
              disabled={token.hasExternalOwner}
            >
              <ModeEditOutlineOutlined color={token.hasExternalOwner ? 'disabled' : 'primary'} />
            </Button>
          )}
        </Grid>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <Box width={'100%'} className="ag-theme-material">
            <AgGridReact {...gridOptions}></AgGridReact>
          </Box>
        )}
      </Box>
      <UpdateTokenDocs
        initialDocuments={documents}
        onClose={() => setOpenEditDocuments(false)}
        tokenId={token.id}
        open={openEditDocuments}
      />
    </>
  )
}

export default ShowTokenDocuments
