import { Currency } from '@archax/shared-types'
import { InputAdornment } from '@mui/material'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React from 'react'
import { Controller, UseFormSetValue } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

export type CurrencyFieldProps = TextFieldProps & {
  name: string
  control: any
  currency: Currency
  setValue: UseFormSetValue<any>
}

export const CurrencyField: React.FunctionComponent<CurrencyFieldProps> = ({ name, control, currency, setValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error } }) => (
        <NumericFormat
          customInput={TextField}
          thousandSeparator={true}
          decimalScale={2} // TODO: Update to the currency decimals after ARX-399
          fixedDecimalScale
          onValueChange={(values: any) => {
            setValue(name, values.value.replace(/\D/g, ''), { shouldValidate: true })
          }}
          error={!!error}
          InputProps={{
            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
          }}
          helperText={error ? error.message : null}
          variant="standard"
          sx={{ mb: 4 }}
          fullWidth
        />
      )}
    />
  )
}

export default CurrencyField
