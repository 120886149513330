import { Card, Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7, marginTop: 20 }}>
        <Typography variant="h1">Nothing to see here!</Typography>
        <Typography>
          <Link to="/">Go to the home page</Link>
        </Typography>
      </Card>
    </Container>
  )
}
export default NotFound
