const substringFilterParams = {
  filterOptions: ['contains'],
  maxNumConditions: 1,
}

const dateFilterOptions = {
  filterOptions: ['lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
  maxNumConditions: 1,
}

const numberFilterOptions = {
  filterOptions: ['lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
  maxNumConditions: 1,
}

export { substringFilterParams, dateFilterOptions, numberFilterOptions }
