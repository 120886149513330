import { Currency, READABLE_TOKEN_TYPE, TABLE_HEADER_NAMES, Token, TokenType } from '@archax/shared-types'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material'
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { ClientSideGrid } from '../../../../components/ClientSideGrid'
import currencyFormatter from '../../../../util/currency-formatter'
import tokenBalanceFormatter from '../../../../util/token-balance-formatter'

interface IStyledBox {
  children: React.ReactNode
  sx?: SxProps<Theme> | undefined
}

function StyledBox({ children, sx }: IStyledBox) {
  return (
    <Box
      sx={{
        marginTop: '16px',
        display: 'flex',
        padding: '24px',
        gap: '24px',
        alignItems: 'center',
        borderRadius: '8px',
        border: '1px solid var(--other-divider, #E0E2E4)',
        background: ' #F8FBFD',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

type TokenDistribution = {
  id: string
  name: string
  currency: Currency
  balance: string
  price: {
    id: string
    effectiveDate: Date
    price: string
  }
  value: string
}

interface IPoolTokenValue {
  tokenId: string
  tokenDistribution?: TokenDistribution[]
  totals?: {
    balance: 1
    value: Record<Currency, string>
  }
  handleRefresh: () => void
}

function PoolTokenValue({ tokenId, tokenDistribution, totals, handleRefresh }: IPoolTokenValue) {
  const [showDistribution, setShowDistribution] = useState(false)
  const [toggleDistribution, setToggleDistribution] = useState(false)

  useEffect(() => {
    if (showDistribution) {
      setToggleDistribution(true)
    } else {
      setTimeout(() => {
        setToggleDistribution(false)
      }, 300)
    }
  }, [showDistribution])

  const columnDefs: ColDef<any>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: TABLE_HEADER_NAMES.common.token_name,
        flex: 1,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'tokenType',
        headerName: TABLE_HEADER_NAMES.common.type,
        valueGetter: (params) => READABLE_TOKEN_TYPE[params.data!.tokenType as TokenType],
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(TokenType),
          valueFormatter: (params: ValueFormatterParams<Token>) => READABLE_TOKEN_TYPE[params.value as TokenType],
        },
      },
      {
        field: 'balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 50,
        sortable: false,
        valueGetter: ({ data }) => tokenBalanceFormatter(data.balance, data.decimals),
      },
      {
        field: 'value',
        headerName: TABLE_HEADER_NAMES.common.value,
        valueGetter: ({ data }) => {
          if (!data!.value) return currencyFormatter('0', { decimals: 2 })

          if (typeof data!.value! === 'object') {
            return Object.entries(data!.value! as Record<Currency, string>)
              .map(([currency, value]) => {
                return currencyFormatter(value as string, { decimals: 2, prefix: currency as Currency })
              })
              .join(' + ')
          }
          return currencyFormatter(data!.value! as string, { decimals: 2, prefix: data.currency as Currency })
        },
        flex: 1,
        minWidth: 50,
        sortable: false,
      },
    ],
    [],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: tokenDistribution,
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
    }),
    [columnDefs, tokenDistribution],
  )

  return (
    <>
      <Typography align="left" variant="h5">
        Pool Token Value
      </Typography>
      <StyledBox>
        <Grid
          container
          direction={'column'}
          sx={{
            maxHeight: '64px',
            alignContent: 'flex-start',
            gap: '6px 24px',
            overflowX: 'auto',
            overflowY: 'clip',
          }}
        >
          {Object.entries(totals?.value ?? {}).map(([currency, amount]) => (
            <Box
              key={currency}
              sx={{
                display: 'flex',
                gap: '8px',
                width: 'fit-content',
              }}
            >
              <Typography variant="caption">{currencyFormatter(amount, { decimals: 2 })}</Typography>
              <Typography variant="caption">{currency}</Typography>
            </Box>
          ))}
        </Grid>
      </StyledBox>
      <Box
        paddingLeft={0}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginBottom={4}
        marginTop={4}
      >
        <Typography variant="h5">
          Pool token portfolio{' '}
          <Button
            type="submit"
            variant="contained"
            size="small"
            color="primary"
            startIcon={showDistribution ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            onClick={() => setShowDistribution(!showDistribution)}
          >
            {showDistribution ? 'Hide' : 'Show'} portfolio
          </Button>
        </Typography>
      </Box>
      <StyledBox sx={{ marginTop: '32px', display: 'flex', flexDirection: 'column' }}>
        {toggleDistribution && (
          <Box
            sx={{
              opacity: showDistribution ? 1 : 0,
              transition: 'all 0.3s ease-in-out',
              width: 'fill-available',
            }}
          >
            <Box width={'100%'} className="ag-theme-material">
              <ClientSideGrid gridOptions={gridOptions} handleRefresh={handleRefresh} />
            </Box>
          </Box>
        )}
      </StyledBox>
    </>
  )
}

export default PoolTokenValue
