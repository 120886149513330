"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraderAddressErrorMessage = exports.TraderErrorMessage = void 0;
var TraderErrorMessage;
(function (TraderErrorMessage) {
    TraderErrorMessage["NotFound"] = "Trader not found";
    TraderErrorMessage["UnspecifiedTrader"] = "Trader id has to be specified";
    TraderErrorMessage["DuplicateTraderName"] = "Cannot create a trader with the same name as an existing trader";
    TraderErrorMessage["CannotBeArchived"] = "Trader cannot be archived";
    TraderErrorMessage["AlreadyActive"] = "Trader is already active";
    TraderErrorMessage["TraderIdMismatch"] = "Mismatching trader id";
})(TraderErrorMessage || (exports.TraderErrorMessage = TraderErrorMessage = {}));
var TraderAddressErrorMessage;
(function (TraderAddressErrorMessage) {
    TraderAddressErrorMessage["InvalidAddress"] = "Invalid address";
    TraderAddressErrorMessage["NotFound"] = "Trader address not found";
    TraderAddressErrorMessage["TokensCoupled"] = "Trader address is coupled to some tokens";
    TraderAddressErrorMessage["AlreadyAdded"] = "The address is already used in the system and could not be added";
})(TraderAddressErrorMessage || (exports.TraderAddressErrorMessage = TraderAddressErrorMessage = {}));
