import { UserRole } from '@archax/shared-types'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { Layout, PublicLayout } from './components/Layout'
import { LoadingSpinner } from './components/LoadingSpinner'
import { useGlobalContext } from './context'
import { LoginPage } from './feature/auth/login'
import { ListDistribution } from './feature/distribution'
import { NotFound } from './feature/not-found'
import { AlgorandOptIn } from './feature/optin/algorand'
import AlgorandOptinFlow from './feature/optin/algorand/flow'
import { HederaAssociate } from './feature/optin/hedera'
import AdminEditOrganization from './feature/organizations/AdminEditOrganization'
import CreateOrganizations from './feature/organizations/CreateOrganizations'
import EditOrganization from './feature/organizations/EditOrganization'
import ListOrganizations from './feature/organizations/ListOrganizations'
import AdminApproval from './feature/token/AdminApproval'
import ConsolidatedCapTable from './feature/token/ConsolidatedCapTable'
import CreateToken from './feature/token/CreateToken'
import EditOperation from './feature/token/EditOperation'
import EditOperations from './feature/token/EditOperations'
import ListStandards from './feature/token/ListStandards'
import ListTokens from './feature/token/ListTokens'
import TokenManagement from './feature/token/TokenManagement'
import ListTraders from './feature/trader/ListTraders'
import TraderManagement from './feature/trader/TraderManagement'
import CreateUsers from './feature/users/CreateUsers'
import ListUsers from './feature/users/ListUsers'

const PrivateRoute = () => {
  const {
    state: { user, isLoading },
  } = useGlobalContext()

  if (isLoading) {
    return <LoadingSpinner />
  } else if (user) {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }
}

const HolderDetailsRedirect = () => {
  const { id } = useParams()
  return <Navigate to={`/holders/${id}`} replace />
}

const Home = () => {
  const {
    state: { user },
  } = useGlobalContext()

  const redirectPath = user?.roles.some((role: UserRole) => role === UserRole.Approver) ? '/tokens/requests' : '/tokens'
  return <Navigate to={redirectPath} />
}

function Router() {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/tokens" element={<ListTokens />} />
          <Route path="/tokens/:id" element={<TokenManagement />}>
            <Route path="/tokens/:id/history" element={<TokenManagement />} />
            <Route path="/tokens/:id/whitelist" element={<TokenManagement />} />
            <Route path="/tokens/:id/blacklist" element={<TokenManagement />} />
            <Route path="/tokens/:id/cap-table" element={<TokenManagement />} />
            <Route path="/tokens/:id/tokens" element={<TokenManagement />} />
            <Route path="/tokens/:id/documents" element={<TokenManagement />} />
            <Route path="/tokens/:id/configuration" element={<TokenManagement />} />
          </Route>
          <Route path="/tokens/create" element={<CreateToken />} />
          <Route path="/tokens/requests" element={<AdminApproval />} />
          <Route path="/tokens/requests/:id" element={<EditOperation />} />
          <Route path="/tokens/requests/edit" element={<EditOperations />} />
          <Route path="/tokens/requests/edit/:id" element={<EditOperation />} />
          <Route path="/tokens/consolidated-cap-table" element={<ConsolidatedCapTable />} />
          <Route path="/tokens/distribution" element={<ListDistribution />} />
          {/* TODO: Remove '/traders/ and /traders/:id after checking if route is safe to remove. */}
          <Route path="/traders/" element={<Navigate to="/holders/" replace />} />
          <Route path="/traders/:id" element={<HolderDetailsRedirect />} />
          <Route path="/holders/" element={<ListTraders />} />
          <Route path="/holders/:id" element={<TraderManagement />} />
          <Route path="/users" element={<ListUsers />} />
          <Route path="/users/create" element={<CreateUsers />} />
          <Route path="/organizations" element={<ListOrganizations />} />
          <Route path="/organization" element={<EditOrganization />} />
          <Route path="/organizations/:id" element={<AdminEditOrganization />} />
          <Route path="/organizations/create" element={<CreateOrganizations />} />
          <Route path="/standards" element={<ListStandards />} />
        </Route>
      </Route>

      <Route element={<PublicLayout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/hedera/associate/:id" element={<HederaAssociate />} />
        <Route path="/algorand/optin/:id" element={<AlgorandOptIn />} />
        <Route path="/algorand/:id/optin" element={<AlgorandOptinFlow />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default Router
