"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DistributionStatusMap = exports.READABLE_DISTRIBUTION_STATUS = exports.DistributionStatus = void 0;
const operation_1 = require("../operation");
__exportStar(require("./errors"), exports);
var DistributionStatus;
(function (DistributionStatus) {
    DistributionStatus["New"] = "NEW";
    DistributionStatus["Processing"] = "PROCESSING";
    DistributionStatus["Scheduled"] = "SCHEDULED";
    DistributionStatus["Cancelled"] = "CANCELLED";
    DistributionStatus["Failed"] = "FAILED";
    DistributionStatus["Completed"] = "COMPLETED";
})(DistributionStatus || (exports.DistributionStatus = DistributionStatus = {}));
exports.READABLE_DISTRIBUTION_STATUS = {
    [DistributionStatus.New]: 'New',
    [DistributionStatus.Processing]: 'Processing',
    [DistributionStatus.Scheduled]: 'Scheduled',
    [DistributionStatus.Cancelled]: 'Cancelled',
    [DistributionStatus.Failed]: 'Failed',
    [DistributionStatus.Completed]: 'Completed',
};
exports.DistributionStatusMap = {
    [operation_1.OperationStatus.PendingApproval]: DistributionStatus.New,
    [operation_1.OperationStatus.Approved]: DistributionStatus.New,
    [operation_1.OperationStatus.InProgress]: DistributionStatus.Processing,
    [operation_1.OperationStatus.Queued]: DistributionStatus.Scheduled,
    [operation_1.OperationStatus.Cancelled]: DistributionStatus.Cancelled,
    [operation_1.OperationStatus.NotApproved]: DistributionStatus.Cancelled,
};
