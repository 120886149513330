// @deprecated Use `PaginationRequestParams`
export interface PaginatedRequestParams {
  page: number
  limit: number
}

export interface PaginationRequestParams {
  offset: number
  limit: number
}

export enum FormMode {
  Create = 0,
  Edit = 1,
  Readonly = 2,
}