import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Autocomplete, FormLabel, TextField, FormControl, styled } from '@mui/material'
//import { debounce } from 'lodash'
import { debounce } from '@mui/material/utils'

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.grey[700],
}))

export type SelectOption = {
  label?: String
  value: string | number
}

type MultiSelectProps = {
  label: string
  value?: string
  name: string
  placeholder?: string
  handleChange: () => void
  options: SelectOption[]
}
export function MultiSelect(props: MultiSelectProps): ReactElement {
  return (
    <FormControl fullWidth>
      <StyledFormLabel data-testid={`multi-select-${props.name}__label`} id={`multi-select-${props.name}__label`}>
        {props.label}
      </StyledFormLabel>
      <Autocomplete
        data-testid={`multi-select-${props.name}`}
        multiple
        autoComplete
        limitTags={2}
        id={`multi-select-${props.name}`}
        getOptionLabel={(option) => `${option.label}`}
        filterOptions={(x) => x}
        options={props.options}
        renderInput={(params) => <TextField {...params} label="" placeholder={props.placeholder} />}
      />
    </FormControl>
  )
}

const LIMIT_TAGS = 2
const MIN_INPUT_VALUE_LENGTH = 2
type AsyncMultiSelectProps = {
  label: string
  value?: SelectOption[]
  name: string
  placeholder?: string
  handleChange: (selectedOptions: SelectOption[]) => void
  loadOptions: (inputValue?: string) => Promise<SelectOption[]>
}
export function AsyncMultiSelect(props: AsyncMultiSelectProps): ReactElement {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly SelectOption[]>([])

  const loadOptionsDelayed = useCallback(
    debounce((value: string) => {
      ;(async () => {
        if (value?.length >= MIN_INPUT_VALUE_LENGTH) {
          setOptions(await props.loadOptions(value))
        }
      })()
    }, 1000),
    [],
  )

  useEffect(() => {
    loadOptionsDelayed(inputValue)
  }, [inputValue])

  useEffect(() => {
    ;(async () => {
      setOptions(await props.loadOptions())
    })()
  }, [])

  return (
    <FormControl fullWidth>
      <StyledFormLabel data-testid={`select-${props.name}__label`} id={`select-${props.name}__label`}>
        {props.label}
      </StyledFormLabel>
      <Autocomplete
        data-testid={`select-${props.name}`}
        multiple
        autoComplete
        limitTags={LIMIT_TAGS}
        id={`select-${props.name}`}
        getOptionLabel={(option) => `${option.label}`}
        isOptionEqualToValue={(a, b) => a.value === b.value}
        filterOptions={(x) => x}
        options={options}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        onChange={(_, selectedOptions) => {
          props.handleChange(selectedOptions)
        }}
        value={props.value || []}
        renderInput={(params) => <TextField {...params} label="" placeholder={props.placeholder} />}
      />
    </FormControl>
  )
}
