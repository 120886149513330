import { CSV_MIME_TYPE } from '@archax/shared-types'
import Axios, { RawAxiosRequestHeaders } from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const axios = Axios.create({
  withCredentials: true,
  baseURL: API_URL || '',
  headers: { 'Content-Type': 'application/json' },
})

export const axiosNoAuth = Axios.create({
  withCredentials: false,
  baseURL: API_URL || '',
  headers: { 'Content-Type': 'application/json' },
})

export const download = async (url: string, headers?: RawAxiosRequestHeaders) => {
  const response = await axios.get(url, { responseType: 'blob', headers })
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = downloadUrl
  const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '')
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const downloadCSV = async (url: string) => {
  return download(url, { Accept: CSV_MIME_TYPE })
}

export const downloadCSVByPost = async (url: string, data: Record<string, unknown>) => {
  const response = await axios.post(url, data, { responseType: 'blob', headers: { Accept: CSV_MIME_TYPE } })
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = downloadUrl
  const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '')
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
