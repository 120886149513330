import { numericFormatter } from 'react-number-format'
import { unparseUnits } from './units'
/**
 * Formats the token balance with thoousand separator and the token decimals
 */
const tokenBalanceFormatter = (value: string, decimals: number) => {
  if (!value) {
    return ''
  }
  const numStr = unparseUnits(String(value), decimals)
  return numericFormatter(numStr, {
    thousandSeparator: true,
    fixedDecimalScale: false,
    decimalScale: decimals,
  })
}
export default tokenBalanceFormatter
