import { Card, Container, Divider, Grid, Link, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

function HederaAssociate() {
  const { id } = useParams()

  return (
    <Container maxWidth="md">
      <Card sx={{ p: 7, marginTop: 20 }}>
        <Grid container>
          <Grid item sm={3} xs={12}>
            <img alt="Hedera logo" width="100%" src="//hips.hedera.com/assets/hedera_logo.png" />
          </Grid>
          <Grid item sm={9} xs={12}>
            <Typography align="center" variant="h1">
              Associate token
            </Typography>
            <Typography sx={{ marginBottom: 5 }} align="center" variant="h2">
              Token ID: {id}
            </Typography>
          </Grid>
        </Grid>

        <Typography sx={{ marginBottom: 5 }}>
          Before a token or NFT can be sent to an account, the token must be associated to the account.
          <br />
          Below you can find instructions for some Hedera wallets.
        </Typography>
        <Grid container>
          <Grid item sm={2} xs={4}>
            <img
              alt="HashPack logo"
              width="60"
              height="60"
              src="https://lh3.googleusercontent.com/11gZzwVrl8X2eoCbag1y5_hhyUqMKxG-zfDThmczUD7TwlX6HS0207EqyKGcz-FY1ZtDrWBwtNIG5VMlp-f6jkniYQ=w128-h128-e365-rj-sc0x00ffffff"
            />
          </Grid>
          <Grid item sm={10} xs={8}>
            <Grid item xs={12}>
              HashPack
            </Grid>
            <Grid item xs={12}>
              <Link href="https://www.hashpack.app/">
                <Typography variant="caption">www.hashpack.app</Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              It can be done via the "Add Token" button on the Assets tab, or the "Associate NFT" button the NFTs tab.
              The Hedera Network charges a fee of $0.05USD for associating a token to an account.
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ m: 3 }} />

        <Grid container>
          <Grid item sm={2} xs={4}>
            <img
              alt="Blade wallet logo"
              width="60"
              height="60"
              src="https://lh3.googleusercontent.com/QPb_HuFpJSITN_CZSXrF_w6WsTFGvq2420FQcTgj73Veb1IRt6a2VIPdxmbJR9IfMj-A8NC5FyZT6yLBW_AyMTngAzk=w128-h128-e365-rj-sc0x00ffffff"
            />
          </Grid>
          <Grid item sm={10} xs={8}>
            <Grid item xs={12}>
              Blade
            </Grid>
            <Grid item xs={12}>
              <Link href="https://www.bladewallet.io/">
                <Typography variant="caption">www.bladewallet.io</Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Article on Blade wallet helpcenter:
              <Link href="https://helpcenter.bladewallet.io/en/articles/6603623-how-to-add-associate-a-token-id">
                How to add/associate a Token ID?
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Container>
  )
}
export default HederaAssociate
