import WarningIcon from '@mui/icons-material/Warning'
import { Tooltip, Typography } from "@mui/material"

function BalanceErrorTooltip() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Tooltip title="Could not retrieve the balance">
        <Typography variant="caption">
          <WarningIcon />
        </Typography>
      </Tooltip>
    </div>
  )
}

export default BalanceErrorTooltip
