"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationProcessStatus = exports.OperationStatus = exports.OperationTransactionDescription = exports.OperationTypesAllowedForAdmin = exports.OperationTypesAllowedForApprover = exports.OperationTypesAllowedForUser = exports.OperationType = void 0;
__exportStar(require("./errors"), exports);
var OperationType;
(function (OperationType) {
    OperationType["CreateToken"] = "CREATE_TOKEN";
    OperationType["CreateExternalToken"] = "CREATE_EXTERNAL_TOKEN";
    OperationType["Mint"] = "MINT";
    OperationType["Send"] = "SEND";
    OperationType["Burn"] = "BURN";
    OperationType["Wipe"] = "WIPE";
    OperationType["GrantKYC"] = "GRANT_KYC";
    OperationType["RevokeKYC"] = "REVOKE_KYC";
    OperationType["Pause"] = "PAUSE";
    OperationType["Unpause"] = "UNPAUSE";
    OperationType["AddTraderAddress"] = "ADD_TRADER_ADDRESS";
    OperationType["RemoveTraderAddress"] = "REMOVE_TRADER_ADDRESS";
    OperationType["RegisterToken"] = "REGISTER_TOKEN";
    OperationType["UnregisterToken"] = "UNREGISTER_TOKEN";
    OperationType["TransferToken"] = "TRANSFER_TOKEN";
    OperationType["UpdateTokenDocs"] = "UPDATE_TOKEN_DOCS";
    OperationType["AddToBlacklist"] = "ADD_TO_BLACKLIST";
    OperationType["RemoveFromBlacklist"] = "REMOVE_FROM_BLACKLIST";
    OperationType["ArchiveToken"] = "ARCHIVE_TOKEN";
    OperationType["UnarchiveToken"] = "UNARCHIVE_TOKEN";
    OperationType["RemoveTraderAddressFromWhiteList"] = "REMOVE_TRADER_ADDRESS_FROM_WHITE_LIST";
    OperationType["Distribution"] = "DISTRIBUTION";
    OperationType["ChangeOwner"] = "CHANGE_OWNER";
    OperationType["CancelDistribution"] = "CANCEL_DISTRIBUTION";
    OperationType["DeployImplementation"] = "DEPLOY_IMPLEMENTATION";
    OperationType["Upgrade"] = "UPGRADE";
    OperationType["AssociateToken"] = "ASSOCIATE_TOKEN";
    OperationType["SetKYCableStatus"] = "SET_KYCABLE_STATUS";
    OperationType["SetBlacklistableStatus"] = "SET_BLACKLISTABLE_STATUS";
})(OperationType || (exports.OperationType = OperationType = {}));
exports.OperationTypesAllowedForUser = [
    OperationType.CreateToken,
    OperationType.CreateExternalToken,
    OperationType.Mint,
    OperationType.Send,
    OperationType.Burn,
    OperationType.Wipe,
    OperationType.GrantKYC,
    OperationType.RevokeKYC,
    OperationType.Pause,
    OperationType.Unpause,
    OperationType.AddTraderAddress,
    OperationType.RemoveTraderAddress,
    OperationType.RegisterToken,
    OperationType.UnregisterToken,
    OperationType.TransferToken,
    OperationType.UpdateTokenDocs,
    OperationType.AddToBlacklist,
    OperationType.RemoveFromBlacklist,
    OperationType.ArchiveToken,
    OperationType.UnarchiveToken,
    OperationType.RemoveTraderAddressFromWhiteList,
    OperationType.Distribution,
    OperationType.CancelDistribution,
    OperationType.DeployImplementation,
    OperationType.Upgrade,
    OperationType.AssociateToken,
    OperationType.SetKYCableStatus,
    OperationType.SetBlacklistableStatus,
];
exports.OperationTypesAllowedForApprover = [...exports.OperationTypesAllowedForUser, OperationType.CancelDistribution];
exports.OperationTypesAllowedForAdmin = [OperationType.ChangeOwner, OperationType.CancelDistribution];
var OperationTransactionDescription;
(function (OperationTransactionDescription) {
    OperationTransactionDescription["Mint"] = "MINT";
    OperationTransactionDescription["Transfer"] = "TRANSFER";
})(OperationTransactionDescription || (exports.OperationTransactionDescription = OperationTransactionDescription = {}));
var OperationStatus;
(function (OperationStatus) {
    OperationStatus["PendingApproval"] = "PENDING_APPROVAL";
    OperationStatus["InProgress"] = "IN_PROGRESS";
    OperationStatus["Approved"] = "APPROVED";
    OperationStatus["Cancelled"] = "CANCELLED";
    OperationStatus["NotApproved"] = "NOT_APPROVED";
    OperationStatus["Queued"] = "QUEUED";
})(OperationStatus || (exports.OperationStatus = OperationStatus = {}));
var OperationProcessStatus;
(function (OperationProcessStatus) {
    OperationProcessStatus["Success"] = "SUCCESS";
    OperationProcessStatus["Error"] = "ERROR";
    OperationProcessStatus["Pending"] = "PENDING";
})(OperationProcessStatus || (exports.OperationProcessStatus = OperationProcessStatus = {}));
