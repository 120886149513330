import {
  Currency,
  TABLE_HEADER_NAMES,
  TOKEN_BALANCE_ERROR_PLACEHOLDER_VALUE,
  TraderHoldingsData,
} from '@archax/shared-types'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material'
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useEffect, useMemo, useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { proRataDecimals } from '../../constants'
import currencyFormatter from '../../util/currency-formatter'
import BalanceErrorTooltip from './components/BalanceErrorTooltip'

interface TokenDistribution {
  id: string
  name: string
  currency: Currency
  balance: string
  price: {
    id: string
    effectiveDate: Date
    price: string
  }
  value: string
}

interface Value {
  [key: string]: string
}

interface ITokenDistribution extends Omit<TraderHoldingsData, 'value'> {
  tokenDistribution: TokenDistribution[]
  value: Value | string | null
}

interface IHoldingsDetail {
  tokenDistribution: TokenDistribution[]
  value: Value | string | null
}

interface IStyledBox {
  children: React.ReactNode
  sx?: SxProps<Theme> | undefined
}
function StyledBox({ children, sx }: IStyledBox) {
  return (
    <Box
      sx={{
        marginTop: '16px',
        display: 'flex',
        padding: '24px',
        gap: '24px',
        alignItems: 'center',
        borderRadius: '8px',
        border: '1px solid var(--other-divider, #E0E2E4)',
        background: ' #F8FBFD',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

function HoldingDistribution(data: IHoldingsDetail) {
  const { tokenDistribution, value } = data
  const [showDistribution, setShowDistribution] = useState(false)
  const [toggleDistribution, setToggleDistribution] = useState(false)

  useEffect(() => {
    if (showDistribution) {
      setToggleDistribution(true)
    } else {
      setTimeout(() => {
        setToggleDistribution(false)
      }, 300)
    }
  }, [showDistribution])

  const columnDefs: ColDef<any>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: TABLE_HEADER_NAMES.common.token_name,
        flex: 1,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'balance',
        headerName: TABLE_HEADER_NAMES.common.pro_rata,
        flex: 1,
        minWidth: 50,
        sortable: false,

        cellRenderer: ({ data }: { data: TokenDistribution }) => {
          if (data!.balance !== TOKEN_BALANCE_ERROR_PLACEHOLDER_VALUE.toString()) {
            return numericFormatter(String(data.balance), { decimalScale: parseInt(proRataDecimals) })
          }
          return <BalanceErrorTooltip />
        },
      },
      {
        field: 'value',
        headerName: TABLE_HEADER_NAMES.common.value,
        valueGetter: ({ data }) => currencyFormatter(data.value || '0', { decimals: 2, prefix: data.currency }),
        flex: 1,
        minWidth: 50,
        sortable: false,
      },
    ],
    [],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: tokenDistribution,
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
    }),
    [columnDefs, tokenDistribution],
  )

  return (
    <>
      <Typography align="left" variant="h5">
        Pool Token Value
      </Typography>
      <StyledBox>
        <Grid
          container
          direction={'column'}
          sx={{
            maxHeight: '64px',
            alignContent: 'flex-start',
            gap: '6px 24px',
            overflowX: 'auto',
            overflowY: 'clip',
          }}
        >
          {Object.entries(value ?? {}).map(([currency, amount]) => (
            <Box
              key={currency}
              sx={{
                display: 'flex',
                gap: '8px',
                width: 'fit-content',
              }}
            >
              <Typography variant="caption">{currencyFormatter(amount, { decimals: 2 })}</Typography>
              <Typography variant="caption">{currency}</Typography>
            </Box>
          ))}
        </Grid>
      </StyledBox>
      <StyledBox sx={{ marginTop: '32px', display: 'flex', flexDirection: 'column' }}>
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5">Pool Token Portfolio (pro rata)</Typography>

          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            startIcon={showDistribution ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            onClick={() => setShowDistribution(!showDistribution)}
          >
            {showDistribution ? 'Hide' : 'Show'} Distribution
          </Button>
        </Grid>
        {toggleDistribution && (
          <Box
            sx={{
              opacity: showDistribution ? 1 : 0,
              transition: 'all 0.3s ease-in-out',
              width: 'fill-available',
            }}
          >
            <Box width={'100%'} className="ag-theme-material">
              <AgGridReact {...gridOptions}></AgGridReact>
            </Box>
          </Box>
        )}
      </StyledBox>
    </>
  )
}

function TraderHoldingsDetail({ data }: ICellRendererParams<ITokenDistribution>) {
  const tokenDistribution = data?.tokenDistribution as TokenDistribution[]
  const value = data?.value as Value
  return (
    <Box sx={{ overflowY: 'auto', height: 'inherit' }}>
      <Box sx={{ width: 'auto', margin: '32px' }}>
        <HoldingDistribution tokenDistribution={tokenDistribution} value={value} />
      </Box>
    </Box>
  )
}

export default TraderHoldingsDetail
