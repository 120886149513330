import { ReactElement, useContext, useEffect, useMemo, useRef } from 'react'
import { ConsolidatedCapTableEntry, READABLE_TOKEN_TYPE, TokenType } from '@archax/shared-types'
import { Box, Card, Stack, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import CustomBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { ClientSideGrid } from '../../../components/ClientSideGrid'
import DatePicker from '../../../components/ui/DatePicker'
import { AsyncMultiSelect, SelectOption } from '../../../components/ui/MultiSelect'
import { substringFilterParams } from '../../../util/common-grid-options'
import onApiError from '../../../util/on-api-error'
import tokenBalanceFormatter from '../../../util/token-balance-formatter'
import { holderValueFormatter } from '../utils'
import ConsolidatedCapTableProvider, { ConsolidatedCapTableContext } from './context'

function ConsolidatedCapTable(): ReactElement {
  const { t } = useTranslation()
  const { state, actions } = useContext(ConsolidatedCapTableContext)
  const gridRef = useRef(null)

  const {
    isFetching,
    data: consolidatedCapTableData,
    refetch,
  } = useQuery(['consolidated-cap-table', state.params], actions.loadConsolidatedData, {
    retry: false,
    onError: onApiError,
  })

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'tokenType',
        headerName: t('headers.common.type'),
        valueGetter: (params) => READABLE_TOKEN_TYPE[params.data!.tokenType as TokenType],
        maxWidth: 150,
        sortable: true,
      },
      {
        field: 'tokenName',
        headerName: t('headers.common.tokenName'),
        maxWidth: 180,
        sortable: true,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'tokenProtocol',
        headerName: t('headers.common.protocol'),
        maxWidth: 160,
        sortable: true,
      },
      {
        field: 'holderName',
        headerName: t('headers.common.holderAddress'),
        maxWidth: 240,
        sortable: true,
      },
      {
        field: 'holderAddressName',
        headerName: t('headers.common.holderName'),
        sortable: false,
        minWidth: 450,
        valueGetter: (params: ValueGetterParams<ConsolidatedCapTableEntry>) => {
          if (params.data!.holderAddressName) {
            return `${params.data!.holderAddressName} (${params.data!.holderAddress})`
          }
          return `${params.data!.holderAddress}`
        },
        tooltipValueGetter: (params) => {
          if (params.data!.holderAddressName) {
            return `${params.data!.holderAddressName} (${params.data!.holderAddress})`
          }
          return `${params.data!.holderAddress}`
        },
      },
      {
        field: 'holderBalance',
        headerName: t('headers.common.balance'),
        maxWidth: 125,
        sortable: false,
        valueGetter: (params: ValueGetterParams<ConsolidatedCapTableEntry>) => {
          return tokenBalanceFormatter(params.data!.holderBalance, params.data!.tokenDecimals)
        },
      },
      {
        field: 'holderValue',
        headerName: t('headers.common.value'),
        maxWidth: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<ConsolidatedCapTableEntry>) => {
          return holderValueFormatter({
            currencySymbol: params.data!.tokenCurrencySymbol,
            holderValue: params.data!.holderValue,
          })
        },
        tooltipValueGetter: (params) => {
          return holderValueFormatter({
            currencySymbol: params.data!.tokenCurrencySymbol,
            holderValue: params.data!.holderValue,
          })
        },
      },
    ],
    [],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: consolidatedCapTableData,
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
      pagination: true,
      paginationPageSize: 10,
    }),
    [columnDefs, consolidatedCapTableData],
  )

  useEffect(() => {
    if (gridRef?.current && (gridRef.current as any).api) {
      isFetching ? (gridRef.current as any).api.showLoadingOverlay() : (gridRef.current as any).api.hideOverlay()
    }
  }, [isFetching])

  useEffect(() => {
    refetch()
  }, [state.params])

  return (
    <Container maxWidth="xl">
      <CustomBreadcrumbs items={['Token Management', 'Consolidated Cap Table']} />

      <Card sx={{ p: 7, mb: 5 }}>
        <Stack spacing={{ sm: 2 }} maxWidth="sm">
          <AsyncMultiSelect
            label="Selected Tokens"
            name="selectedTokens"
            value={state.params.selectedTokens.map((it) => ({
              value: it.id,
              label: it.name,
            }))}
            placeholder="Tokens"
            handleChange={(selectedOptions: SelectOption[]) => {
              actions.changeParams({
                selectedTokens: selectedOptions.map((it) => ({
                  id: `${it.value}`,
                  name: `${it.label}`,
                })),
              })
            }}
            loadOptions={actions.loadTokensOptions as (inputValue?: string) => Promise<SelectOption[]>}
          />
          <DatePicker
            label="Selected date"
            name="selectedDate"
            value={state.params.selectedDate}
            handleChange={(selectedDate) => {
              actions.changeParams({
                selectedDate: dayjs(selectedDate).endOf('day').toDate(),
              })
            }}
          />
        </Stack>
      </Card>
      <Card sx={{ p: 7, mb: 5 }}>
        <Box width={'100%'} height={'100%'}>
          <Typography align="left" variant="h3">
            Cap Table
          </Typography>
        </Box>
        <ClientSideGrid gridOptions={gridOptions} handleRefresh={refetch} handleCSVDownload={actions.exportCSV} />
      </Card>
    </Container>
  )
}

export default () => {
  return (
    <ConsolidatedCapTableProvider>
      <ConsolidatedCapTable />
    </ConsolidatedCapTableProvider>
  )
}
