import { ChainProtocol, READABLE_PROTOCOL } from '@archax/shared-types'

export function readableTokenProtocol(protocol: ChainProtocol, privacyEnabled?: boolean): string {
  if (!protocol) {
    return ''
  }
  if (privacyEnabled) {
    return `Private (${READABLE_PROTOCOL[protocol]})`
  }
  return READABLE_PROTOCOL[protocol]
}
