import { ChainProtocol, TokenStandardType } from '@archax/shared-types'
import EtherscanLogo from './assets/etherscan-logo-circle.svg'
import HederaExplorerLogo from './assets/hedera-hashgraph-hbar.svg'
import PeraExplorerLogo from './assets/pera-explorer.svg'
import PolyscanLogo from './assets/polyscan-logo.svg'
import XrpLogo from './assets/xrp-logo.svg'

export const EXPLORER_LOGOS = {
  [ChainProtocol.Algorand]: PeraExplorerLogo,
  [ChainProtocol.Ethereum]: EtherscanLogo,
  [ChainProtocol.Hedera]: HederaExplorerLogo,
  [ChainProtocol.Polygon]: PolyscanLogo,
  [ChainProtocol.XRP_Ledger]: XrpLogo,
}

export const STANDARDS_WITH_PREMINT = [
  TokenStandardType.ASA,
  TokenStandardType.ERC20,
  TokenStandardType.FungibleCommon,
  TokenStandardType.ERC20PoolToken,
  TokenStandardType.FungibleCommonPool,
]

export const STANDARDS_WITH_DECIMALS = STANDARDS_WITH_PREMINT
export const PROTOCOLS_WITH_WHITELIST_REQUIRED = [ChainProtocol.Algorand, ChainProtocol.XRP_Ledger]
export const PROTOCOLS_WITH_SUPPORT_TO_BLACKLIST = [ChainProtocol.Ethereum, ChainProtocol.Hedera, ChainProtocol.Polygon]

export const DEFAULT_PAGE_SIZE = 10

export const proRataDecimals = process.env.REACT_APP_PRO_RATA_DECIMALS || '4'

export const TOKENS_DOCUMENTS_SIZE_LIMIT = 100 * 1024 * 1024 // 100MB

export const USDC_DECIMALS = 6

export const DISABLED_CHAINS = (process.env.REACT_APP_DISABLED_CHAINS || '')
  .split(',')
  .map((it) => ChainProtocol[it as keyof typeof ChainProtocol])
let c = process.env.REACT_APP_PROTOCOLS_WITH_PRIVACY_ENABLED

console.log(c)
let d = (() => process.env.REACT_APP_PROTOCOLS_WITH_PRIVACY_ENABLED)()
console.log(d)

export const PROTOCOLS_WITH_PRIVACY_ENABLED = (process.env.REACT_APP_PROTOCOLS_WITH_PRIVACY_ENABLED || '')
  .split(',')
  .map((it) => ChainProtocol[it as keyof typeof ChainProtocol])

console.log('env REACT_APP_PROTOCOLS_WITH_PRIVACY_ENABLED', process.env.REACT_APP_PROTOCOLS_WITH_PRIVACY_ENABLED)
console.log('PROTOCOLS_WITH_PRIVACY_ENABLED', PROTOCOLS_WITH_PRIVACY_ENABLED)
